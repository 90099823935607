import { isEqual } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import useCreateProjectReducer from "../../../hooks/useCreateProjectReducer";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import ProjectDetails from "./ProjectDetails";

import { cleanUpTitle } from "../../../helpers/Formatters";
import {
  PROJECT_STATUS_TYPES,
  PROJECT_UPDATE_RATE,
  PROJECT_HOURS_OF_OPERATION,
  SET_PROJECT_MODAL_STATUS,
  customModalStyles,
  PROJECT_EMPTY,
} from "../../../constants";
import Modal from "../Modal/Modal";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import { useAppState } from "../../../state/appState";
import { SpinnerInline } from "../Spinner/Spinner";
import { useProjectsOverview } from "../../../hooks/projects";
import useProjectFormData from "./useProjectFormData";

const { overlayStyle, contentStyle, titleStyle, headerStyle } =
  customModalStyles;

/**
 * Renders the ProjectForm component, which is used exclusively within the Project Creation Modal
 */
const ProjectForm = ({ currentUser, systemConfiguration, test }) => {
  const { addProject, isSaving } = useProjectFormData();

  const [project, dispatch] = useCreateProjectReducer();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalState, setModalState] = useState();
  const [sameAddress, setSameAddress] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const [{ projectModalStatus }, appStateDispatch] = useAppState([]);

  const { projectDict } = useProjectsOverview();

  const closeCreateModal = () => {
    dispatch({
      type: "discard",
      value: undefined,
    });

    appStateDispatch({
      type: SET_PROJECT_MODAL_STATUS,
      open: false,
    });
  };

  const inputRefs = useRef({});

  useEffect(() => {
    const isSame = isEqual(PROJECT_EMPTY, project);
    setShowConfirm(!isSame);
  }, [project]);

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      dispatch({
        type: PROJECT_UPDATE_RATE,
        value: financialsConfiguration.financials?.rateSheet,
      });
    }
  }, [dispatch, financialsConfiguration]);

  useEffect(() => {
    if (systemConfiguration?.system) {
      dispatch({
        type: PROJECT_HOURS_OF_OPERATION,
        value: systemConfiguration?.system?.hoursOfOperation,
      });
    }
  }, [dispatch, systemConfiguration?.system]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalState();
  };

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = (event) => {
    // checks if the Enter/Tab key was pressed
    if (event.keyCode === 13 || event.keyCode === 9) {
      const inputCategories = Object.keys(inputRefs?.current);
      const currentInputCategoryIdx = inputCategories.indexOf(
        event.target.id || event.target.name
      );

      const currentInputCategory = inputCategories[currentInputCategoryIdx];
      if (currentInputCategory?.includes("address1")) {
        document.getElementById("address-option-0")?.click();
      }
      if (currentInputCategory === "zipCode") {
        inputRefs?.current.focus();
      }
      if (currentInputCategory === "checked" && sameAddress) {
        const next = inputRefs?.current?.cancelButton;
        next.focus();
      } else if (currentInputCategory === "checked" && !sameAddress) {
        const next = inputRefs?.current?.address2;
        next.focus();
      } else if (currentInputCategory === "cancelButton") {
        const next = inputRefs?.current?.createButton;
        next.focus();
      } else {
        const nextInputCategory =
          inputRefs?.current[inputCategories[currentInputCategoryIdx + 1]];
        event.preventDefault();
        nextInputCategory.focus();
      }
    }
  };

  const handleForwardRef = (key, val) => {
    if (inputRefs?.current) inputRefs.current[key] = val;
  };

  const createButtonHandler = async () => {
    const projectManagerRole =
      managementConfiguration?.management?.project?.memberPositions?.find(
        (elem) => !elem?.custom && elem?.display === "Project Manager"
      )?.id;

    const projectData = Object.entries(project)
      .filter(([, value]) => !!value)
      .reduce((obj, entry) => {
        const [entryKey, entryValue] = entry;
        let newVal;
        if (entryKey === "hoursOfOperation") {
          newVal = Object.entries(entryValue)
            .map(([day, value]) => {
              return {
                [day]: value ?? { active: false, from: "", to: "" },
              };
            })
            .reduce((body, item) => {
              const [key, value] = Object.entries(item)[0];
              return { ...body, [key]: value };
            }, {});
        } else if (entryKey === "retainage") {
          newVal = [
            ...entryValue?.map((item) => {
              return {
                percentComplete: item.percentComplete,
                percentRetainage: item.percentRetainage,
              };
            }),
          ];
        } else if (entryKey === "insurance") {
          newVal = {
            ...entryValue,
            requirements: entryValue?.requirements?.map((req) => req.text),
          };
        } else if (entryKey === "members") {
          newVal = [
            ...entryValue,
            { user: currentUser.reference, position: projectManagerRole },
          ];
        } else if (entryKey === "duration") {
          return {
            ...obj,
            duration: { ...obj?.duration, value: entryValue },
          };
        } else if (entryKey === "durationType") {
          return {
            ...obj,
            duration: { ...obj?.duration, typeOfDuration: entryValue },
          };
        }
        return { ...obj, [entryKey]: newVal ?? entryValue };
      }, {});

    // save project as draft by default
    projectData.status = PROJECT_STATUS_TYPES.draft;

    addProject(projectData, closeCreateModal);
  };

  // Make Sure new Projects have a unique name
  const [nameExists, setNameExists] = useState(false);

  useEffect(() => {
    if (!project?.name?.length) {
      setNameExists(false);
      return;
    }

    const doesNameExists =
      projectDict != null &&
      Object.values(projectDict)?.some(
        (existingProject) =>
          cleanUpTitle(existingProject.name) === cleanUpTitle(project.name)
      );
    setNameExists(doesNameExists);
  }, [project.name, projectDict]);

  return (
    <>
      <Modal
        overlayStyle={overlayStyle}
        contentStyle={contentStyle}
        titleStyle={titleStyle}
        headerStyle={headerStyle}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        title="Create Project"
        isOpen={projectModalStatus?.open}
        onRequestModalClose={closeCreateModal}
        primaryButtonOnClick={() => createButtonHandler()}
        primaryButtonTitle="Save"
        tertiaryButtonTitle="Cancel"
        trashCan
        disabled={
          !project.name?.trim()?.length ||
          !project?.projectType ||
          nameExists ||
          !project?.timezone
        }
        hideFooter
        showConfirm={showConfirm}
        modalAction="Project Creation"
        childContainerClassName="modal-content-class-no-padding"
      >
        {isSaving && (
          <div className="fixed flex items-center justify-center w-full z-10 h-full confirm-dialog">
            <div className="absolute mt-12">Creating Project...</div>
            <div className="absolute w-full z-10 h-full bottom-14">
              <SpinnerInline />
            </div>
          </div>
        )}
        <div
          className="flex flex-col w-192 ml-7 mt-7"
          style={{ minHeight: "450px" }}
        >
          <ProjectDetails
            project={project}
            dispatch={dispatch}
            currentUser={currentUser}
            configs={managementConfiguration}
            handleEnter={handleEnter}
            handleForwardRef={handleForwardRef}
            inputRefs={inputRefs}
            setSameAddress={setSameAddress}
            test={test}
            property={projectModalStatus?.property}
            isExistingProjectName={nameExists}
          />
        </div>
      </Modal>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          title={modalState?.title}
          hideFooter
          onRequestModalClose={handleCloseModal}
          primaryButtonTitle="Yes"
          primaryButtonOnClick={modalState?.onClick}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          tertiaryButtonTitle="No"
        >
          <div className="flex flex-col h-full justify-between p-2">
            <p>{modalState?.description}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

ProjectForm.propTypes = {
  /**
   * Current User Object
   */
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
  }),
  /**
   * System Configuration
   */
  systemConfiguration: PropTypes.shape({
    system: PropTypes.shape({ hoursOfOperation: PropTypes.shape({}) }),
  }),
  // closeCreateModal: PropTypes.func,
  test: PropTypes.bool,
};

ProjectForm.defaultProps = {
  currentUser: undefined,
  systemConfiguration: undefined,
  // closeCreateModal: () => {},
  test: false,
};

export default ProjectForm;
