import moment from "moment";
import "moment-timezone";
import { rrulestr } from "rrule";
import { isValidRecurrence } from "../rRule";

/**
 * Calculates the day offset between a given timestamp and a specified timezone offset.
 *
 * @param {string} timestamp - The original timestamp in ISO 8601 format.
 * @param {string} offset - The timezone offset in ±HH:mm format.
 * @returns {object} - An object indicating if the day changed, the difference in days, and the type of change (add or subtract).
 */
const calculateOffset = (timestamp, offset) => {
  const originalMoment = moment(timestamp).utc();
  const offsetMoment = moment(timestamp).utcOffset(offset);

  const originalDate = originalMoment.date();
  const offsetDate = offsetMoment.date();

  const dayDifference = offsetDate - originalDate;
  const isSameDay = dayDifference === 0;

  // if the conversion takes it back a day we need it to say add, otherwise subtract
  // to simulate moment function call moment.().add -> becomes moment()[type]
  let type = "NA";
  if (dayDifference < 0) type = "add";
  if (dayDifference > 0) type = "subtract";

  return {
    sameDay: isSameDay,
    offBy: Math.abs(dayDifference), // needs to be an absolute value to use with moment .add and .subtract
    type,
  };
};

/**
 * Creates request items based on a given item and time limits.
 *
 * @param {object} item - The item to process, containing a recurrence string and other properties.
 * @param {object} limits - An object containing left and right time limits.
 * @returns {Array} - An array of new recurring items with adjusted start and end dates.
 */
const createReqItems = (item, limits) => {
  const recurrenceString = item?.recurrence;

  if (!recurrenceString) {
    return [
      {
        resource: item,
        uri: `/api/${item.resource}/${item.id}`,
        search: {
          mode: "match",
        },
      },
    ];
  }

  if (item.recurrence) {
    const isValid = isValidRecurrence({
      rRuleString: item.recurrence,
    });

    if (!isValid) {
      return console.error(
        "creating rec items: this is not a valid recurrence string"
      );
    }
  }

  const rrule = rrulestr(recurrenceString);
  const tzString = item.timezone;

  const leftMoment = moment.tz(limits.left, tzString).utc();
  const rightMoment = moment.tz(limits.right, tzString).utc();

  const leftDate = leftMoment.toDate();
  const rightDate = rightMoment.toDate();

  const occurrences = rrule.between(leftDate, rightDate);

  const formattedDates = occurrences.map((date) =>
    moment.tz(date, tzString).format("YYYY-MM-DD")
  );

  const ogStart = moment.utc(item.startDate);
  const ogEnd = moment.utc(item.endDate);
  const differenceInDays = ogEnd.diff(ogStart, "days");

  const newRecurringItems = formattedDates.map((date) => {
    const sd = item.startDate;
    const ed = item.endDate;

    let newStartDate = `${date}T${sd.split("T")[1]}`;
    const dateWithDif = moment
      .tz(date, tzString)
      .add(differenceInDays, "days")
      .format("YYYY-MM-DD");

    let newEndDate = `${dateWithDif}T${ed.split("T")[1]}`;

    const offsetData = calculateOffset(newStartDate, moment().format("Z"));
    if (!offsetData.sameDay) {
      newStartDate = moment
        .utc(newStartDate)
        [offsetData.type]("days", offsetData.offBy)
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

      newEndDate = moment
        .utc(newEndDate)
        [offsetData.type]("days", offsetData.offBy + 1)
        .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }

    const newFormattedEvent = {
      resource: {
        ...item,
        instanceStartDate: newStartDate,
        startDate: newStartDate,
        endDate: newEndDate,
      },
      uri: `/api/${item.resource}/${item.id}`,
      search: {
        mode: "match",
      },
    };

    return newFormattedEvent;
  });

  return newRecurringItems;
};

export default createReqItems;
