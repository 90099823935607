import React from "react";
import { TASK } from "../../../constants";
import { hasWritePermission } from "../../../helpers/Permissions";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import PopoverComments from "../../../stories/Components/PopoverComments";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import PureTaskTable from "../../../stories/Components/TaskTable/PureTaskTable";
import Widget from "../../../stories/Components/Widget/Widget";
import useTaskListData from "./useTaskListData";

const TaskList = () => {
  const {
    showHeaderItems,
    newTaskButtonDisabled,
    formattedTasks,
    currentTask,
    tasksTemplates,
    isLoading,
    commentsData,
    openNewTaskDialog,
    onTaskClick,
    setCurrentTask,
    clearCurrentTask,
    usedSopDict,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    handleOpenComments,
    handleCloseComments,
  } = useTaskListData();

  const { data: currentUser } = useCurrentUser();

  return (
    <>
      {/* The primary header items should only be shown on the task list page */}
      {showHeaderItems && (
        <PrimaryHeaderItems
          headerTitle="Tasks"
          buttonTitle="Task"
          customButton={
            hasWritePermission(TASK, currentUser) && (
              <PrimaryButton
                addButton
                resourceName="Task"
                onClick={openNewTaskDialog}
                disabled={newTaskButtonDisabled}
              />
            )
          }
        />
      )}

      <Widget
        draggable={false}
        title={null}
        loading={isLoading}
        hideBorder={!showHeaderItems}
      >
        <PureTaskTable
          data={formattedTasks}
          currentTask={currentTask}
          setCurrentTask={setCurrentTask}
          clearCurrentTask={clearCurrentTask}
          usedSopDict={usedSopDict}
          onTaskClick={onTaskClick}
          tasksTemplates={tasksTemplates}
          updateUserTemplateSettings={updateUserTemplateSettings}
          deleteUserTemplateSettings={deleteUserTemplateSettings}
          hideSiteHeaderTitle
          handleOpenComments={handleOpenComments}
        />
      </Widget>
      {!!commentsData?.association && (
        <PopoverComments
          isOpen={!!commentsData?.association}
          resourceReference={commentsData?.taskReference}
          association={commentsData?.association}
          onClose={handleCloseComments}
        />
      )}
    </>
  );
};

export default TaskList;
