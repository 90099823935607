/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import {
  GET_ASSET_VIEW_PATH,
  GET_PROPERTY_ASSET_PATH,
} from "../../../constants";
import { useAssets } from "../../../hooks/assets";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import AssetTable from "../../../stories/Components/AssetTable/AssetTable";

const Assets = () => {
  const history = useHistory();
  const { projectId, propertyId } = useParams();
  const [catOptions, setCatOptions] = useState([]);
  const [subCatOptionsMap, setSubCatOptionsMap] = useState({});
  const rawParam = useParams();
  const { data: managementConfiguration } = useManagementConfiguration();

  const reference =
    (projectId && `Project/${projectId}`) ||
    (propertyId && `Property/${propertyId}`);

  const { data: originalAssets, isLoading: loading } = useAssets({
    reference,
  });

  useEffect(() => {
    if (managementConfiguration) {
      const categories = managementConfiguration.management?.asset?.category;
      const subcatMap = {};
      setCatOptions(() => {
        const catList = categories
          ?.filter((cat) => cat.selected)
          .map((cat) => {
            subcatMap[cat.display] = cat.subcategories
              .filter((subcat) => subcat.selected)
              .map((subcat) => {
                return { label: subcat.display, value: subcat.id };
              });
            return { label: cat.display, value: cat.id };
          });
        return catList;
      });
      setSubCatOptionsMap(subcatMap);
    }
  }, [managementConfiguration]);

  const onAssetRowClick = (id) => {
    const key = Object.keys(rawParam);
    if (key.includes("propertyId")) {
      history.replace(GET_PROPERTY_ASSET_PATH(propertyId, id));
    } else {
      // TODO: Fix for projectAsset use case
      history.push(GET_ASSET_VIEW_PATH(id));
    }
  };

  return (
    <div className={`relative min-h-200 ${loading && "loading"}`}>
      <AssetTable
        originalAssets={originalAssets}
        reference={reference}
        onAssetClick={onAssetRowClick}
        onRowClick={onAssetRowClick}
        categoryOptionsList={catOptions}
        subCatOptionsMap={subCatOptionsMap}
      />
    </div>
  );
};

Assets.propTypes = {};

Assets.defaultProps = {};

export default Assets;
