import { useCallback, useMemo, useState } from "react";
import { CredentialManagerAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { cloneDeep, isEqual } from "lodash";
import * as yup from "yup";
import { phoneRegExp } from "../../helpers/Formatters";
import useCurrentUser from "../../hooks/useCurrentUser";
import useUserPatch from "../../hooks/useUserPatch";
import { useUserById } from "../../hooks/useUserById";
import { toastMessage, toastError } from "../../stories/Components/Toast/Toast";
import { getTimeZoneOptions } from "../../helpers/Time";

const useMyProfile = () => {
  const { data: currentUser } = useCurrentUser();
  const { data: userData } = useUserById(currentUser.id);

  const { mutateAsync: patchUser } = useUserPatch();

  const [editableUser, setEditableUser] = useState(cloneDeep(userData));
  const isCompany = currentUser?.kind === "company";
  const [activeIndex, setActiveIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);

  const isFormValid = useMemo(() => {
    return (
      editableUser?.name?.firstName?.length >= 3 &&
      !isEqual(currentUser, editableUser)
    );
  }, [currentUser, editableUser]);

  // Variables ->
  const userEmail = useMemo(() => {
    return editableUser?.contactPoint?.find(
      (item) => item?.system === "Email" && item?.use === "Home"
    )?.value;
  }, [editableUser?.contactPoint]);

  const userDate = useCallback(
    (occasion) => {
      return editableUser?.dates?.find((date) => date?.occasion === occasion)
        ?.value;
    },
    [editableUser?.dates]
  );

  const userSocial = useMemo(() => {
    return editableUser?.social?.find((item) => item.platform === "LinkedIn")
      ?.value;
  }, [editableUser?.social]);

  const userPhoneNumber = useMemo(() => {
    return editableUser?.contactPoint?.find(
      (item) => item?.system === "Phone" && item?.use === "Mobile"
    )?.value;
  }, [editableUser?.contactPoint]);

  /**
   * @param {String} value input value for the appropriate name field
   * @param {String} key "firstName" || "lastName";
   */
  const changeName = (value, key) => {
    setEditableUser((prev) => ({
      ...prev,
      name: {
        ...prev.name,
        [key]: value, // firstName || lastName
      },
    }));
  };

  /**
   * @param {String} value Replace users "Home" email
   * Email user is signed up with (user.email) is not changed.
   */
  const changeEmail = (value) => {
    const hasEmail = editableUser?.contactPoint?.some(
      (item) => item?.system === "Email" && item.use === "Home"
    );

    if (hasEmail) {
      const replaceHomeEmail = editableUser?.contactPoint?.map((item) => {
        if (item?.system === "Email") {
          const newEmailValue = { ...item };
          newEmailValue.value = value;
          return newEmailValue;
        }
        return item;
      });
      setEditableUser((prev) => ({
        ...prev,
        contactPoint: [...replaceHomeEmail],
      }));
    } else {
      const newEmail = { system: "Email", value, use: "Home" };
      setEditableUser((prev) => ({
        ...prev,
        contactPoint: [...prev.contactPoint, newEmail],
      }));
    }
  };

  /**
   * @param {String} value Replace user "Home" PhoneNumber
   */
  const changePhoneNumber = (value) => {
    const hasPhone = editableUser?.contactPoint?.some(
      (item) => item.system === "Phone" && item.use === "Mobile"
    );
    if (hasPhone) {
      const replaceHomePhone = editableUser?.contactPoint?.map((item) => {
        if (item?.system === "Phone" && item?.use === "Mobile") {
          const newPhoneValue = { ...item };
          newPhoneValue.value = value;
          return newPhoneValue;
        }
        return item;
      });
      setEditableUser((prev) => ({
        ...prev,
        contactPoint: [...replaceHomePhone],
      }));
    } else {
      const newPhone = { system: "Phone", use: "Mobile", value };
      setEditableUser((prev) => ({
        ...prev,
        contactPoint: [...prev.contactPoint, newPhone],
      }));
    }
  };

  /**
   * @param {String} value date value from <DatePicker />
   * @param {String} occasion "Start Date" || "Birthday"
   */
  const hasDate = useCallback(
    (occasion) => {
      return editableUser?.dates?.some((item) => item?.occasion === occasion);
    },
    [editableUser?.dates]
  );

  const changeDate = (value, occasion) => {
    if (hasDate(occasion)) {
      const newDates = editableUser?.dates?.map((date) => {
        if (date?.occasion === occasion) {
          const newDate = { ...date };
          newDate.value = moment(value).utc().format();
          return newDate;
        }
        return date;
      });
      setEditableUser((prev) => ({
        ...prev,
        dates: [...newDates],
      }));
    } else {
      const newDate = { occasion, value: moment(value).utc().format() };
      const datesArr = [...editableUser.dates];
      datesArr.push(newDate);
      setEditableUser((prev) => ({
        ...prev,
        dates: datesArr,
      }));
    }
  };

  const changeSocial = (value) => {
    const hasSocial = editableUser?.social?.some(
      (item) => item?.platform === "LinkedIn"
    );

    if (hasSocial) {
      const socials = editableUser?.social?.map((item) => {
        if (item?.platform === "LinkedIn") {
          const newItem = { ...item };
          newItem.value = value;
          return newItem;
        }
        return item;
      });
      setEditableUser((prev) => ({
        ...prev,
        social: [...socials],
      }));
    } else {
      const newSocial = { platform: "LinkedIn", value };
      setEditableUser((prev) => ({
        ...prev,
        social: [...prev.social, newSocial],
      }));
    }
  };

  // TODO: Need Ticket To update Form Avatar to use updated progress bar
  const changeProfileImage = async (fileResource) => {
    if (fileResource) {
      setEditableUser((prev) => ({
        ...prev,
        avatar: fileResource?.reference,
      }));
    }
  };

  const changePronunciation = (val) => {
    setEditableUser((prev) => ({
      ...prev,
      pronunciation: val,
    }));
  };

  const firstNameValidation = yup
    ?.string()
    ?.trim()
    .min(1, "Must be at least 1 characters")
    .required();

  const phoneNumberValidation = userPhoneNumber?.trim()?.length
    ? yup.string().matches(phoneRegExp, "Phone number is not valid")
    : null;

  // TODO: Could separate this code chunk into a helper for contacts pages
  const handleSaveUser = ({ newUserInfo, oldUserInfo }) => {
    // Check index, so we aren't trying to patch a resource that hasn't changed
    if (activeIndex === 0) {
      // If user is editing details page
      try {
        patchUser({
          oldUserInfo,
          newUserInfo,
        });
      } catch (err) {
        console.warn("Profile Error", err);
      }
    }
    // if preferences is added back saving those will go here under activeIndex === 1
  };

  const handleSave = () => {
    handleSaveUser({
      newUserInfo: editableUser,
      oldUserInfo: currentUser,
    });
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleResetState = () => {
    setEditableUser(cloneDeep(currentUser));
    setIsEditing(false);
  };

  /**
   * API Helper method to call the "$changepassword" api method, to update a User's password. Promise resolves to true
   * if the password was updated successfully, or false if there was an error.
   */
  const saveUpdatedPassword = async (newPassword) => {
    setChangePasswordModalOpen(false);
    try {
      const res = await CredentialManagerAPI.postWOP("$changepassword", {
        newPassword1: newPassword,
        newPassword2: newPassword,
      });
      if (res.data.issue.code === "successful") {
        // success toast
        toastMessage("Updated Password");
      }
    } catch (e) {
      toastError("Error Updating Password");
    }
  };

  const timezoneOptions = getTimeZoneOptions();

  const changeTimezone = (val) => {
    setEditableUser((prev) => ({
      ...prev,
      timezone: val,
    }));
  };

  return {
    userEmail,
    userPhoneNumber,
    userDate,
    userSocial,
    changeName,
    changeEmail,
    changePhoneNumber,
    changeDate,
    changeSocial,
    changeProfileImage,
    firstNameValidation,
    phoneNumberValidation,
    activeIndex,
    setActiveIndex,
    isEditing,
    setIsEditing,
    editableUser,
    handleEditClick,
    isCompany,
    handleSave,
    handleResetState,
    changePasswordModalOpen,
    setChangePasswordModalOpen,
    saveUpdatedPassword,
    isFormValid,
    changePronunciation,
    timezoneOptions,
    changeTimezone,
  };
};

export default useMyProfile;
