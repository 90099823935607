import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { taskKeys } from "../../../config/reactQuery/queryKeyFactory";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import getUpdatedAllTaskSpacesAssets from "../../../helpers/Utilities/getUpdatedAllTaskSpacesAssets";
import { useAssets, useAssetsOverview } from "../../../hooks/assets";
import useEditCalendar from "../../../hooks/calendar/useEditCalendar";
import useSpaces from "../../../hooks/useSpaces";
import "../Spinner/styles.css";
import { toastError, toastMessage } from "../Toast/Toast";

const useAddSpaceAssetWidget = ({
  form,
  setForm,
  resource,
  isEditing,
  showSpacesAssets,
  currentTask,
  setCurrentTask,
  disabled,
}) => {
  const [showSelect, setShowSelect] = useState(false);
  const { editInCalendar } = useEditCalendar();

  const { assetsDict } = useAssetsOverview();
  const queryClient = useQueryClient();
  const taskData = isEditing ? form : currentTask;
  const isPropertyParent = taskData?.association?.includes("Property");

  // Handle Checking off Spaces && Assets from Single View task and View Task Modal
  const handleMarkComplete = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$non-recurring",
          };

          const taskPatchResponse = await editInCalendar(props);

          setCurrentTask({
            ...taskPatchResponse.data,
          });

          setForm({ ...taskPatchResponse.data });

          toastMessage("Task updated successfully");
        } else {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$single",
          };

          const updatedTaskItem = await editInCalendar(props);

          setForm({ ...updatedTaskItem.data });

          toastMessage("Task updated successfully");
        }
        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        console.error(error);
        toastError("This task could not be updated");
      }
    },
    [currentTask, editInCalendar, queryClient, setCurrentTask, setForm]
  );

  const handleCompleteCheck = useCallback(
    (value, entity, resourceName) => {
      // create array with all spaces/assets
      const currentEntities = [
        ...(taskData?.spaces ?? []),
        ...(taskData?.assets ?? []),
      ];
      const newCompletionStatus = value;

      // Helper to loop and check off the correct space || asset and return each list
      const { spaces, assets } = getUpdatedAllTaskSpacesAssets({
        currentEntities,
        resourceName,
        newCompletionStatus,
        entity,
      });

      const updatedTask = {
        ...taskData,
        assets,
        spaces,
      };
      handleMarkComplete(updatedTask);
      return newCompletionStatus;
    },
    [taskData, handleMarkComplete]
  );

  const params = useMemo(
    () => ({
      propertyRef: isPropertyParent && taskData?.association,
      projectRef: !isPropertyParent && taskData?.association,
    }),
    [taskData?.association, isPropertyParent]
  );
  const { data: originalAssets } = useAssets({ params });

  // Show both spaces & assets in DDL or just show the given resource type
  const listForDDL = showSpacesAssets
    ? [...taskData?.spaces, ...taskData?.assets]
    : taskData?.[resource];

  const selectedMembersDDL = listForDDL?.map((item) => {
    return item?.ref ?? item?.id;
  });

  const hookDeps = useMemo(() => {
    if (taskData?.association?.includes("Property")) {
      return {
        resource: "propertiesDict",
        route: "property",
        cacheKey: "properties",
        id: taskData?.association?.split("Property/")[1],
        ref: taskData?.association,
        spaceId: taskData?.space,
        api: "PropertyAPI",
      };
    }
    return {
      resource: "projectDict",
      route: "project",
      cacheKey: "projects",
      id: taskData?.association?.split("Project/")[1],
      spaceId: taskData?.space,
      ref: taskData?.association,
      api: "ProjectAPI",
    };
  }, [taskData?.association, taskData?.space]);

  const {
    base: {
      data: { spaces = [], spacesDict = {} },
    },
  } = useSpaces(hookDeps);
  // Decide witch dictionary to use to grab the full resource
  const currentDict = useMemo(() => {
    switch (resource) {
      case "assets":
        return assetsDict;
      case "spaces":
        return spacesDict;
      default:
        return spacesDict;
    }
  }, [assetsDict, resource, spacesDict]);

  // Format List for DistroSelectMembers popup DDL
  const formattedList = useMemo(() => {
    // Determine the base resource list based on the resource type
    let resourceList = [];
    if (resource === "spaces") {
      resourceList = spaces ?? [];
    } else {
      resourceList = originalAssets ?? [];
    }

    // If showSpacesAssets is true, combine spaces and originalAssets
    if (showSpacesAssets) {
      resourceList = [...(spaces ?? []), ...(originalAssets ?? [])];
    }

    // Filter and map the list to include only the relevant items
    return resourceList.reduce((acc, item) => {
      const refOrId = item?.resource === "Asset" ? item?.reference : item?.id;
      const isAssociated =
        item.project === form.association ||
        item.property === form.association ||
        item.parent === form.association.split("/")[1];

      if (isAssociated) {
        acc.push({
          ...item,
          reference: refOrId,
          id: refOrId,
          name: { firstName: item?.name },
          avatar: item?.primaryImage,
        });
      }

      return acc;
    }, []);
  }, [form.association, originalAssets, resource, showSpacesAssets, spaces]);

  const currentEntities = useMemo(() => {
    // For reference ->
    // taskData.spaces = [ "uuid" ] spacesDict is "space id": {}
    // taskData.assets = [ "assetReference"] assetsDict is "AssetRef": {}
    const list = [];
    // if List should handle Both Spaces & Assets
    if (showSpacesAssets) {
      const joinedList = [
        ...(taskData?.spaces ?? []),
        ...(taskData?.assets ?? []),
      ];
      // map over joinedList to grab the obj of each entity
      joinedList?.forEach((item) => {
        if (item?.ref) {
          return list.push({
            ...assetsDict[item?.ref],
            isCompleted: item.isCompleted,
          });
        }
        return list.push({
          ...spacesDict[item?.id],
          isCompleted: item.isCompleted,
        });
      });
    }

    // if list should only handle the passed "resource" type (ie: "spaces" || "assets")
    taskData?.[resource]?.forEach((entity) => {
      const entityType = entity?.ref ? entity?.ref : entity?.id;
      return list.push({
        ...currentDict?.[entityType],
        isCompleted: entity.isCompleted,
      });
    });
    return list;
  }, [
    assetsDict,
    currentDict,
    taskData,
    resource,
    showSpacesAssets,
    spacesDict,
  ]);

  const resourceTitle = showSpacesAssets ? "Spaces/Assets" : resource;
  // remove 's' at the end of the resource name for 'spaces' & 'assets'
  const addButtonTitle = `Add ${
    showSpacesAssets
      ? "Space/Asset"
      : capitalizeFirstLetter(resource?.slice(0, -1))
  }`;
  const distroListTitle = `Associated ${capitalizeFirstLetter(resourceTitle)}`;
  const searchPlaceHolder = `Search ${capitalizeFirstLetter(resourceTitle)}`;

  // if editing and DDL isn't showing
  const showAddButton = isEditing && !showSelect && !disabled;
  return {
    currentEntities,
    addButtonTitle,
    distroListTitle,
    searchPlaceHolder,
    formattedList,
    showSelect,
    setShowSelect,
    showAddButton,
    selectedMembersDDL,
    handleCompleteCheck,
  };
};

export default useAddSpaceAssetWidget;
