import { DocumentAPI, ProjectAPI } from "@griffingroupglobal/eslib-api";
import { debounce } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";
import useDocuments from "../../hooks/useDocuments";
import useDocumentsConfiguration from "../../hooks/useDocumentsConfiguration";
import useDocumentsForProject from "../../hooks/useDocumentsForProject";
import useFavorites from "../../hooks/useFavorites";
import useProjectFormReducer from "../../hooks/useProjectFormReducer";
import { useProjectSwitchView } from "../../hooks/useSwitchView";
import { useAppState } from "../../state/appState";

import Modal from "../../stories/Components/Modal/Modal";
import Spinner from "../../stories/Components/Spinner/Spinner";

import {
  DOCUMENT_DISPLAY_STATUS,
  FILTER_TYPES,
  GET_PROJECT_CREATE_DOCUMENT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  SET_WORKFLOW_DOCUMENT,
  TABLE_FILTER,
  WORKFLOWS_ADD_NEW_PATH,
} from "../../constants";
import { resolvePrimaryImage } from "../../helpers/File";
import { formatServerErrorMessage } from "../../helpers/Formatters";
import useProject from "../../hooks/useProject";
import whiteCircleCheckIcon from "../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../stories/assets/images/whiteExclamationIcon.svg";

import {
  getDocsDictionaryByDocType,
  getDocumentUser,
} from "../../helpers/Document";
import { getProjectOptions } from "../../helpers/SiteHeaderOptions";
import { toastError, toastMessage } from "../../helpers/Toast";
import PureDocumentFoldersView from "../../stories/Components/DocumentFoldersView/PureDocumentFoldersView";
import {
  BooleanFilter,
  DateFilter,
  IsOrNotFilter,
} from "../../stories/Components/Table/tableFilters";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

/**
 * @deprecated
 */
const ProjectDocuments = ({ currentUser }) => {
  const history = useHistory();
  const [, dispatchAppState] = useAppState();
  const { projectId } = useParams();
  const [project, , setProject] = useProject(projectId);
  const [loading, setLoading] = useState(true);
  const { documents, reload } = useDocumentsForProject(projectId, setLoading);
  const {
    addDocument,
    removeDocument,
    reload: reloadAllDocuments,
  } = useDocuments();
  const { data: docConfig } = useDocumentsConfiguration();
  const [editedProject, dispatch] = useProjectFormReducer();
  const [favorites, { postFavorite, deleteFavorite }] = useFavorites();
  const [isShowingTable, setIsShowingTable] = useProjectSwitchView(false);

  const [projectImage, setProjectImage] = useState();
  const [clonedProject, setClonedProject] = useState(project);
  const [searchText, setSearchText] = useState("");
  const [resetDocs, setResetDocs] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState();
  const [columns, setColumns] = useState([]);

  /**
   * Document View States
   */
  const [folderViews, setFolderViews] = useState({ allDocs: [] });
  const [viewFolder, setViewFolder] = useState({
    title: undefined,
    content: [],
  });

  /**
   *  Search Document States
   */
  const [searchDocuments, setSearchDocuments] = useState();
  const [searchViewFolder, setSearchViewFolder] = useState();

  /**
   * Document Type Options
   */
  const docTypeOptionsMap = React.useMemo(() => {
    return {
      ...docConfig?.documents?.documentType
        .filter((doc) => doc.selected && (doc.value || doc.id))
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.value ?? item.id]: {
              label: item.display,
              value: item.value ?? item.id,
            },
          };
        }, {}),
    };
  }, [docConfig]);

  const documentColumns = useMemo(
    () => [
      {
        id: "isFavorited",
        Header: "",
        accessor: "isFavorited",
        filterOptions: {
          equals: true,
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
      },
      {
        Header: "Name",
        accessor: "resource",
        minWidth: 200,
      },
      {
        Header: "Type",
        accessor: "type",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Created By",
        accessor: "createdBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        id: "modifiedDate",
        Header: "Date Modified",
        accessor: (row) => moment(row.modifiedDate).format("MMM DD, YYYY"),
        filterOptions: {
          filterType: FILTER_TYPES.date,
        },
      },
    ],
    []
  );

  useEffect(() => {
    reloadAllDocuments();
  }, [reloadAllDocuments]);

  useEffect(() => {
    const col = documentColumns?.map((currentCol) => {
      switch (currentCol.filterOptions?.filterType) {
        case FILTER_TYPES.isOrNot: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
        case FILTER_TYPES.boolean: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.BOOL,
            Filter: BooleanFilter,
          };
        }
        case FILTER_TYPES.date: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.DATE,
            Filter: DateFilter,
          };
        }
        default: {
          return {
            ...currentCol,
            filter: TABLE_FILTER.IS_OR_NOT,
            Filter: IsOrNotFilter,
          };
        }
      }
    });
    setColumns(col);
  }, [documentColumns]);

  const getDocType = useCallback(
    (documentType) => {
      if (documentType) {
        return docTypeOptionsMap[documentType]?.label ?? documentType;
      }
      return "File";
    },
    [docTypeOptionsMap]
  );

  useEffect(() => {
    /**
     * Create Document Dict Function
     */
    const handleFolderViews = () => {
      const views = getDocsDictionaryByDocType({
        documents,
        favorites,
        getDocType,
      });
      setFolderViews(views);
      setResetDocs(false);
      setLoading(false);
    };
    if (!folderViews?.isFavorited && favorites && documents)
      handleFolderViews();
  }, [documents, favorites, folderViews, getDocType, loading]);

  /**
   * @return - All Documents
   */
  const getAllDocs = useCallback(() => {
    return folderViews.allDocs ?? [];
  }, [folderViews.allDocs]);

  const handleFolderClick = (title, docType) => {
    setViewFolder({ title, content: folderViews?.[docType]?.docs, docType });
  };

  const handleBackClick = () => {
    setViewFolder({ title: undefined, content: [] });
  };

  const resetDocuments = React.useCallback(async () => {
    reload();
    setResetDocs(false);
    setLoading(false);
  }, [reload]);

  useEffect(() => {
    if (project?.images?.length) {
      resolvePrimaryImage(project.images)
        .then((imageFile) => setProjectImage(imageFile?.contentsUrl))
        .catch((err) =>
          console.error(`Unable to resolve property primary image:${err}`)
        );
    }
  }, [project]);

  useEffect(() => {
    const filesUploading = getAllDocs().some((item) => item.isUploading);
    if (!filesUploading && resetDocs) {
      const finishedProject = {
        ...project,
        documents: [...getAllDocs().map((item) => item.reference)],
      };

      ProjectAPI.patch(projectId, finishedProject, project)
        .then(({ data }) => {
          setProject(data);
          resetDocuments();
        })
        .catch((err) => {
          const serverMsg = formatServerErrorMessage(err);
          console.error(serverMsg);
        });
    }
  }, [projectId, project, resetDocs, resetDocuments, setProject, getAllDocs]);

  const resetProjectState = useCallback(() => {
    dispatch({
      type: "reset",
      project,
    });
  }, [project, dispatch]);

  useEffect(() => {
    if (project) {
      resetProjectState();
    }
  }, [project, resetProjectState]);

  useEffect(() => {
    const resolvePrimary = async () => {
      if (clonedProject?.images?.length) {
        const primaryImage = await resolvePrimaryImage(clonedProject.images);
        setClonedProject((prev) => {
          return { ...prev, primaryImage };
        });
        setProjectImage(primaryImage?.contentsUrl);
      }
    };
    resolvePrimary();
  }, [clonedProject?.images]);

  const onFinishEditing = async (key, val) => {
    const finishedProject = {
      ...editedProject,
      [key]: val,
    };

    try {
      const newProject = await ProjectAPI.patch(
        project.id,
        finishedProject,
        project
      );
      setClonedProject((prev) => newProject?.data ?? prev);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeImage = (image) => {
    const newImage = { file: image, isPrimary: true };
    dispatch({
      type: "addImage",
      image: newImage,
    });
    onFinishEditing("images", [
      ...editedProject.images.map((item) => {
        return { ...item, isPrimary: false };
      }),
      newImage,
    ]);
  };

  const handleFileClick = (doc) => {
    // history.push(GET_DOCUMENT_PATH(doc.id));
    history.push(GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, doc.id));
  };
  const handleRowClick = (doc) => {
    // history.push(GET_DOCUMENT_PATH(doc.id));
    history.push(GET_PROJECT_DOCUMENTS_DOC_PATH(projectId, doc.id));
  };
  const handleFileEdit = async (doc) => {
    // history.push(GET_DOCUMENT_EDIT_PATH(id));
    const { data } = await DocumentAPI.getWOP(`${doc.id}/$canedit`);
    if (data.canedit) {
      history.push(
        GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH(
          projectId,
          typeof doc === "string" ? doc : doc.id
        )
      );
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be edited`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };
  const handleShowHistory = (doc) => {
    // history.push(GET_DOCUMENT_HISTORY_PATH(id));
    history.push(
      GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH(
        projectId,
        typeof doc === "string" ? doc : doc.id
      )
    );
  };
  const handleSendToWorkflow = useCallback(
    ({ id, project: projectRef }) => {
      const document = documents.find((doc) => doc.id === id);

      const parentDocument = documents.find(
        (doc) => doc.reference === document?.documentRelationships?.parent
      );
      const doesParentDocHasCurrentDocAsAdditionalDoc =
        parentDocument?.additionalDocuments?.indexOf(document.reference) !== -1;
      // if document has parent, then block the access and toast msg
      if (parentDocument && doesParentDocHasCurrentDocAsAdditionalDoc) {
        toastError(
          `This document was created from "${parentDocument?.name}" and cannot be directly sent to a Workflow. Go to the parent document to send this to a Workflow.`,
          toastErrorIcon,
          toastCloseIcon
        );
      } else {
        const allChildDocs = [];
        // if document has children then also attach the children docs to the WF
        if (documents?.length && document?.additionalDocuments?.length) {
          document?.additionalDocuments?.forEach((child) => {
            const childDoc = documents.find((doc) => doc.reference === child);
            if (childDoc) {
              allChildDocs.push(childDoc);
            }
          });
        }
        dispatchAppState({
          type: SET_WORKFLOW_DOCUMENT,
          workflowDocument: [id, ...allChildDocs.map((child) => child.id)],
        });

        let route;
        if (projectRef) {
          route = GET_PROJECT_WORKFLOW_CREATE_PATH(projectRef.split("/")[1]);
        } else {
          route = WORKFLOWS_ADD_NEW_PATH;
        }
        history.push(route);
      }
    },
    [dispatchAppState, documents, history]
  );
  const handleUploadVersion = () => {};
  const handleShowDelete = (doc) => {
    setDeleteDoc(doc);
    setShowDeleteModal(true);
  };
  const handleHideDelete = () => {
    setDeleteDoc();
    setShowDeleteModal(false);
  };
  const handleDeleteDocument = async () => {
    const { data } = await DocumentAPI.getWOP(`${deleteDoc.id}/$canedit`);
    if (data.canedit) {
      let count = 1;
      setDeleting(true);
      removeDocument(deleteDoc.id);

      if (deleteDoc?.additionalDocuments?.length) {
        count += deleteDoc?.additionalDocuments?.length;
        deleteDoc?.additionalDocuments.map((doc) =>
          removeDocument(doc.split("/")[1])
        );
      }
      DocumentAPI.delete(deleteDoc.id)
        .then(({ data: docData }) => {
          const newProject = {
            ...project,
            documents: project.documents?.filter(
              (item) => item !== docData.reference
            ),
          };
          let currentFavoriteViews = folderViews?.isFavorited;
          if (
            currentFavoriteViews?.docs?.some(
              (favorite) => favorite.id === deleteDoc.id
            )
          ) {
            currentFavoriteViews = {
              ...currentFavoriteViews,
              sum: currentFavoriteViews.sum - 1,
              docs: currentFavoriteViews?.docs?.filter(
                (item) => item?.id !== deleteDoc?.id
              ),
            };
          }

          setFolderViews((prev) => {
            return {
              ...prev,
              [deleteDoc.docType]: {
                ...folderViews?.[deleteDoc?.docType],
                sum: folderViews?.[deleteDoc?.docType]?.sum - 1,
                docs: folderViews?.[deleteDoc?.docType]?.docs?.filter(
                  (item) => item?.reference !== deleteDoc?.reference
                ),
              },
              isFavorited: currentFavoriteViews,
              allDocs: prev.allDocs?.filter(
                (deleted) => deleted?.id !== deleteDoc?.id
              ),
            };
          });
          if (viewFolder?.title)
            setViewFolder((prev) => ({
              ...prev,
              content: prev?.content?.filter(
                (doc) => doc?.reference !== deleteDoc?.reference
              ),
            }));
          ProjectAPI.patch(project.id, newProject, project)
            .then(({ data: propertyData }) => {
              setProject(propertyData);
            })
            .catch(() => {
              toastError(
                "Error Saving Project Data",
                toastErrorIcon,
                toastCloseIcon
              );
            });
        })
        .catch(() => {
          toastError("Delete Document Failed", toastErrorIcon, toastCloseIcon);
        });
      setDeleting(false);
      handleHideDelete();
      toastMessage(
        count > 1 ? `(${count}) Documents Deleted.` : "(1) Document Deleted.",
        toastIcon,
        toastCloseIcon
      );
    } else {
      toastError(
        `Document is currently "${
          DOCUMENT_DISPLAY_STATUS[data?.status]
        }" and cannot be deleted`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  };

  const handleFavoriteClick = (id) => {
    const foundItem = favorites.find((favorite) => {
      return favorite.item.reference.includes(id);
    });
    const foundDoc = folderViews?.allDocs.find((item) => item.id === id);

    if (foundItem) {
      deleteFavorite([foundItem]);
      setFolderViews((prev) => ({
        ...prev,
        isFavorited: {
          ...prev?.isFavorited,
          docs: prev?.isFavorited?.docs?.filter(
            (item) => item?.id !== foundDoc.id
          ),
          sum: prev?.isFavorited?.sum - 1,
        },
        allDocs: prev.allDocs?.map((doc) => {
          if (doc?.id === foundDoc?.id) {
            return { ...doc, isFavorited: false };
          }
          return doc;
        }),
      }));
      if (viewFolder?.title === "Favorites") {
        setViewFolder((prev) => ({
          ...prev,
          content: prev?.content?.filter((doc) => doc?.id !== id),
        }));
      }
    } else {
      postFavorite(id, "Document");
      setFolderViews((prev) => ({
        ...prev,
        isFavorited: {
          ...prev?.isFavorited,
          docs: [
            ...prev?.isFavorited?.docs,
            { ...foundDoc, isFavorited: true },
          ],
          sum: prev?.isFavorited?.sum + 1,
        },
        allDocs: prev.allDocs?.map((doc) => {
          if (doc?.id === foundDoc?.id) {
            return { ...doc, isFavorited: true };
          }
          return doc;
        }),
      }));
      if (viewFolder?.title) {
        setViewFolder((prev) => ({
          ...prev,
          content: prev?.content?.map((doc) => {
            if (doc?.id !== id) {
              return doc;
            }
            return { ...doc, isFavorited: true };
          }),
        }));
      }
    }

    if (foundDoc) {
      delete foundDoc.isFavorited;
      addDocument(foundDoc);
    }
  };

  const handleFilesAdded = async (fileRef, fileBody) => {
    if (fileBody) {
      const tempDocument = {
        customName: fileBody.name,
        docType: fileBody.docType,
        contentReference: fileRef,
        project: `Project/${projectId}`,
        status: "open",
        lineItems: [],
      };

      const resolution = await DocumentAPI.post(tempDocument).then(
        ({ data: newDocument }) => {
          addDocument(newDocument);

          if (fileBody.isFavorited) {
            handleFavoriteClick(newDocument.id);
          }
          return { ...newDocument, isFavorited: fileBody?.isFavorited };
        }
      );
      setResetDocs(true);
      return resolution;
    }

    return undefined;
  };

  /**
   * @summary - Takes multiple files, creates an array of Promises,
   * and uploads/patches the new Documents asyncronously following successful
   * uploads to the FileAPI. Added Toast Loading/Success/Error message.
   * @param {Array} files - Array of Files returned from documentUploadForm.
   */

  // a ref to hold the timeout for the SavingDelay function
  const finishUploadRef = useRef(null);

  useEffect(() => {
    return () => {
      // clear the timeout if the component is unmounted
      if (finishUploadRef.current) clearTimeout(finishUploadRef.current);
    };
  }, []);

  const handleFinishUpload = async (files) => {
    const SavingDelay = () => {
      return new Promise((resolve) => {
        finishUploadRef.current = setTimeout(() => {
          resolve();
        }, 500);
      });
    };

    const savingToast = toast("Uploading Document(s)...", {
      isLoading: true,
      position: "top-center",
    });
    const posts = [SavingDelay];
    files?.forEach((doc) => {
      posts.push(() => handleFilesAdded(doc?.reference, doc));
    });
    try {
      const arrayOfPosts = posts?.map((post) => post());
      const uploads = await Promise.all(arrayOfPosts).then((res) => {
        return res;
      });

      const tempFolderViews = folderViews;
      const today = moment();
      const innerFolderView = [];
      uploads?.forEach((doc) => {
        if (doc) {
          tempFolderViews[doc.docType] = tempFolderViews?.[doc?.docType]
            ? {
                ...tempFolderViews?.[doc?.docType],
                sum: tempFolderViews?.[doc?.docType]?.sum + 1,
                docs: [
                  ...tempFolderViews?.[doc?.docType]?.docs,
                  {
                    ...doc,
                    creator: currentUser,
                    metadata: { createdAt: today },
                    id: doc.id,
                  },
                ],
              }
            : {
                sum: 1,
                docs: [doc],
              };

          if (doc.isFavorited)
            tempFolderViews.isFavorited = {
              ...tempFolderViews.isFavorited,
              sum: tempFolderViews.isFavorited.sum + 1,
              docs: [
                ...tempFolderViews.isFavorited.docs,
                {
                  ...doc,
                  creator: currentUser,
                  metadata: { createdAt: today },
                  id: doc.id,
                },
              ],
            };
          innerFolderView.push({
            ...doc,
            creator: currentUser,
            metadata: { createdAt: today },
            id: doc.id,
          });
          tempFolderViews.allDocs.push({
            ...doc,
            resource: doc.name,
            type: getDocType(doc.docType),
            isFavorited:
              doc.isFavorited ??
              favorites.some((fav) => fav.item.reference.includes(doc.id)),
            createdBy: getDocumentUser(doc.creator),
            modifiedBy: getDocumentUser(doc.modifier),
            modifiedDate: doc.metadata?.lastUpdated
              ? moment(doc.metadata.lastUpdated).format("MM/DD/YYYY")
              : "--",
          });
        }
      });
      setFolderViews(tempFolderViews);
      if (viewFolder?.title)
        setViewFolder((prev) => ({
          ...prev,
          content: prev?.content
            ? [...prev?.content, ...innerFolderView]
            : [...innerFolderView],
        }));
      toast.update(savingToast, {
        isLoading: false,
        render: "Saved",
        closeButton: toastCloseIcon,
        className: "bg-brandGreen text-white",
        hideProgressBar: true,
        position: "top-center",
        icon: toastIcon,
        autoClose: 3000,
      });
    } catch (err) {
      toast.update(savingToast, {
        isLoading: false,
        render: `${err.message}`,
        style: {
          backgroundColor: "#BC2727",
          color: "white",
        },
        closeButton: toastCloseIcon,
        position: "top-center",
        hideProgressBar: true,
        icon: toastErrorIcon,
        autoClose: 3000,
      });
    }
  };

  const buttonActions = [
    {
      title: "Create Financial Document",
      onClick: () => history.push(GET_PROJECT_CREATE_DOCUMENT_PATH(projectId)),
    },
    {
      title: "Send to Workflow",
      onClick: () => history.push(GET_PROJECT_WORKFLOW_CREATE_PATH(projectId)),
    },
  ];

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((input) => {
        if (input !== "") {
          const reg = new RegExp(input.toLowerCase());
          setSearchViewFolder(() => {
            return {
              ...viewFolder,
              content: viewFolder?.content?.filter(
                (doc) =>
                  reg.test(doc?.name?.toLowerCase()) ||
                  reg.test(getDocType(doc?.docType)?.toLowerCase())
              ),
            };
          });
          setSearchDocuments(() =>
            folderViews?.allDocs?.filter(
              (doc) =>
                reg.test(doc?.name?.toLowerCase()) ||
                reg.test(getDocType(doc?.docType)?.toLowerCase())
            )
          );
        } else {
          setSearchDocuments();
          setSearchViewFolder();
        }
      }, 700),
    [folderViews?.allDocs, getDocType, viewFolder]
  );

  const handleChange = useCallback(
    (input) => {
      setSearchText(() => input);
      debouncedHandleSearch(input);
    },
    [debouncedHandleSearch]
  );

  /**
   * @summary - Manually Filter Documents Dynamically
   * @param {Array} content - Array of Documents
   * @param {Object} filter - Filter from useTable Hook
   * @returns {Array} - Array of filtered Documents from view
   */
  const filterView = (content, filter) => {
    const selectedType = filter?.value?.type;
    const selectedValues = filter?.value?.values || [];
    const selectedIsTrue = filter?.value?.isTrue;
    const selectedValue = filter?.value?.value;
    const selectedFrom = filter?.value?.from;
    const selectedTo = filter?.value?.to;
    switch (filter?.id) {
      case "isFavorited":
        if (selectedIsTrue) {
          return content?.filter((row) => {
            return row[filter?.id] === selectedValue;
          });
        }
        return content?.filter((row) => {
          return row[filter?.id] !== selectedValue;
        });
      case "type":
        if (selectedValues.length) {
          if (selectedType === "is") {
            return content?.filter((row) => {
              return selectedValues.indexOf(row[filter?.id]) !== -1;
            });
          }

          return content?.filter((row) => {
            return selectedValues.indexOf(row[filter?.id]) === -1;
          });
        }
        return content;
      case "status":
        if (selectedValues.length) {
          if (selectedType === "is") {
            return content?.filter((row) => {
              return selectedValues.indexOf(row[filter?.id]) !== -1;
            });
          }

          return content?.filter((row) => {
            return selectedValues.indexOf(row[filter?.id]) === -1;
          });
        }
        return content;
      case "createdBy":
        if (selectedValues.length) {
          if (selectedType === "is") {
            return content?.filter((row) => {
              return selectedValues.indexOf(row[filter?.id]) !== -1;
            });
          }

          return content?.filter((row) => {
            return selectedValues.indexOf(row[filter?.id]) === -1;
          });
        }
        return content;
      case "modifiedDate":
        if (selectedFrom && selectedTo) {
          return content?.filter((row) => {
            return moment(row[filter?.id]).isBetween(
              moment(selectedFrom),
              moment(selectedTo),
              "days",
              "[]"
            );
          });
        }
        if (selectedFrom) {
          return content?.filter((row) => {
            return moment(row[filter?.id]).isSameOrAfter(moment(selectedFrom));
          });
        }
        if (selectedTo) {
          return content?.filter((row) => {
            return moment(row[filter?.id]).isSameOrBefore(moment(selectedTo));
          });
        }
        return content;
      default:
        return content;
    }
  };

  /**
   * @summary - Loops through array of filters, applies
   * & returns filtered array of documents.
   * @param {Array} content - Array of Documents
   * @param {Array} filter - Array of Filters from useTable Hook
   * @returns {Array} - Array of filtered Documents || Original Array of Documents
   */
  const getFolderView = (content, filter) => {
    let filteredContent = content;

    filter?.forEach((funnel) => {
      filteredContent = filterView(filteredContent, funnel);
    });

    return filteredContent;
  };

  return (
    <>
      <PureDocumentFoldersView
        currentUser={currentUser}
        documents={searchDocuments ?? getAllDocs()}
        resourceId={projectId}
        buttonActions={buttonActions}
        loading={loading}
        columns={columns}
        resource={project}
        isShowingTable={isShowingTable}
        setIsShowingTable={setIsShowingTable}
        searchText={searchText}
        handleChange={handleChange}
        resourceImage={projectImage}
        handleChangeImage={handleChangeImage}
        viewFolder={searchViewFolder ?? viewFolder}
        folderViews={folderViews}
        docTypeOptionsMap={docTypeOptionsMap}
        headerOptions={getProjectOptions}
        favorites={favorites}
        handleFolderClick={handleFolderClick}
        getFolderView={getFolderView}
        handleBackClick={handleBackClick}
        handleFinishUpload={handleFinishUpload}
        handleFileClick={handleFileClick}
        handleFavoriteClick={handleFavoriteClick}
        handleShowDelete={handleShowDelete}
        handleSendToWorkflow={handleSendToWorkflow}
        handleRowClick={handleRowClick}
        handleFileEdit={handleFileEdit}
        handleShowHistory={handleShowHistory}
        handleUploadVersion={handleUploadVersion}
        disableUpload
        hideHeader
        hasWritePermission={currentUser?.hasPermission?.(
          "administrative",
          "can_write_project"
        )}
      />

      {loading && (
        <div className="flex justify-center items-center w-full h-60">
          <Spinner notFullScreen />
        </div>
      )}

      <Modal
        title={
          deleteDoc?.name ? `Delete ${deleteDoc.name}?` : "Delete Document?"
        }
        isOpen={showDeleteModal}
        primaryButtonTitle="Yes, delete"
        primaryButtonOnClick={handleDeleteDocument}
        tertiaryButtonTitle="Cancel"
        onRequestModalClose={handleHideDelete}
        deleting={deleting}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        hideFooter
      >
        <>
          <p className="text-base mb-2">
            Are you sure you want to delete {deleteDoc?.name ?? "this document"}
            ? Once deleted, it cannot be recovered.
          </p>
        </>
      </Modal>
    </>
  );
};

ProjectDocuments.propTypes = {
  currentUser: PropTypes.shape({
    hasPermission: PropTypes.func,
  }),
};
ProjectDocuments.defaultProps = {
  currentUser: undefined,
};

export default ProjectDocuments;
