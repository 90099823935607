import PropTypes from "prop-types";
import React from "react";
import { PRIMARY_DARK_GREEN } from "../../../constants";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import "../Spinner/styles.css";
import DistroListSelectMembers from "../DistroList/DistroListSelectMembers";
import EntityPillList from "../EntityPillList";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import useAddSpaceAssetWidget from "./useAddSpaceAssetWidget";

const labelWidth = { minWidth: "12.5rem" };

const AddSpacesAssetsWidget = ({
  form,
  setForm,
  handleSelect,
  handleRemove,
  resource,
  isEditing,
  noTitle,
  checkbox,
  showSpacesAssets,
  currentTask,
  setCurrentTask,
  disabled,
}) => {
  const {
    currentEntities,
    addButtonTitle,
    distroListTitle,
    searchPlaceHolder,
    formattedList,
    showSelect,
    setShowSelect,
    showAddButton,
    selectedMembersDDL,
    handleCompleteCheck,
  } = useAddSpaceAssetWidget({
    form,
    setForm,
    resource,
    isEditing,
    showSpacesAssets,
    currentTask,
    setCurrentTask,
    disabled,
  });

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center pt-4">
        {!noTitle && (
          <p style={labelWidth} className="text-gray-400 text-md font-semibold">
            {capitalizeFirstLetter(resource)}
          </p>
        )}
        {!isEditing && !currentEntities?.length && (
          <p className="text-sm text-gray-450">
            {`There are no ${resource} saved to this task`}
          </p>
        )}
        {showAddButton && (
          <PlusCircleButton
            title={addButtonTitle}
            className="mt-0"
            onClick={() => setShowSelect(true)}
            style={{ color: PRIMARY_DARK_GREEN }}
          />
        )}
      </div>
      <div id="locations-container" className="flex flex-col">
        {currentEntities?.length !== 0 && (
          <EntityPillList
            list={currentEntities}
            handleClick={handleRemove}
            hideTitle
            isEditing={isEditing}
            checkbox={checkbox}
            disabled={disabled}
            handleCompleteCheck={handleCompleteCheck}
            resource={resource}
          />
        )}
        {showSelect && (
          <DistroListSelectMembers
            members={formattedList}
            setDistroSelectOpen={setShowSelect}
            selectedMembers={selectedMembersDDL}
            onAdd={(val) => handleSelect(val?.reference)}
            onRemove={(val) => handleRemove(val)}
            title={distroListTitle}
            placeHolder={searchPlaceHolder}
          />
        )}
      </div>
    </div>
  );
};

AddSpacesAssetsWidget.propTypes = {
  form: PropTypes.shape({
    invitees: PropTypes.arrayOf(PropTypes.shape({})),
    association: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
  }),
  handleRemove: PropTypes.func,
  handleSelect: PropTypes.func,
  resource: PropTypes.string,
  isEditing: PropTypes.bool,
  noTitle: PropTypes.bool,
  /**
   * Show Both Assets and Spaces in the DDL for single task view specifically
   */
  checkbox: PropTypes.bool,
  /**
   * Show Both Assets and Spaces in the DDL for single task view specifically
   */
  showSpacesAssets: PropTypes.bool,
  setCurrentTask: PropTypes.func,
  currentTask: PropTypes.shape({
    id: PropTypes.string,
  }),
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
};

AddSpacesAssetsWidget.defaultProps = {
  form: {},
  handleRemove: () => {},
  handleSelect: () => {},
  resource: "",
  isEditing: undefined,
  noTitle: undefined,
  checkbox: undefined,
  showSpacesAssets: undefined,
  setCurrentTask: () => {},
  currentTask: {},
  setForm: PropTypes.func,
  disabled: false,
};

export default AddSpacesAssetsWidget;
