// Framework Tools
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";

// Libraries
import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import getBeginningTimeStamp from "../../helpers/Date/getBeginningTimeStamp";

// Hooks
import { useProjectById, useProjectsOverview } from "../../hooks/projects";
import { usePropertiesOverview, usePropertyById } from "../../hooks/properties";
import { useAppState } from "../../state/appState";

// Components
import { hasWritePermission } from "../../helpers/Permissions";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import CalendarControl from "../../stories/Components/Calendar/CalendarControl";
import DayCalendar from "../../stories/Components/Calendar/DayCalendar";
import Calendar from "../../stories/Components/Calendar/PureCalendar";
import WeekCalendar from "../../stories/Components/Calendar/WeekCalendar";
import YearCalendar from "../../stories/Components/Calendar/YearCalendar";
import CalendarSidebar from "../../stories/Components/CalendarSidebar/CalendarSideBar";
import SiteHeader from "../../stories/Components/SiteHeader/SiteHeader";
// Formatters & Helpers
import {
  adjustToCurrentTime,
  getWeek,
  getWeeks,
  roundToNearestHalfHour,
} from "../../helpers/Calendar";
import {
  getProjectOptions,
  getPropertyOptions,
} from "../../helpers/SiteHeaderOptions";

import {
  ADD_OPEN_MODAL,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  EVENT,
  REMOVE_POSITIONED_MODALS,
  SET_CALENDAR_DATA,
  SET_CALENDAR_VIEW,
  TASK,
  VIEW_EVENT_MODAL,
  VIEW_TASK_MODAL,
  calendarViewOptions,
} from "../../constants";
import getTaskListFromDay from "../../helpers/Calendar/getTaskListFromDay";
import { useAssetById } from "../../hooks/assets";
import useCalendarTimezone from "../../hooks/useCalendarTimezone";
import useRelativeAssociations from "../../hooks/useRelativeAssociations";
import { useModalState } from "../../state/modalState";
import ScheduleCalendar from "../../stories/Components/Calendar/ScheduleCalendar";
import CalendarSidebarToggle from "../../stories/Components/CalendarSidebar/CalendarSidebarToggle";
import WidgetContainer from "../../stories/Components/Widget/WidgetContainer";
import useCalendarViewData from "./useCalendarViewData";

const DAY_EVENT_MODEL = {
  allDay: [],
  allDayCount: 0,
  brief: [],
};

const CalendarView = ({ isTest, currentUser, isTabView, setButtonActions }) => {
  /**
   * Associated Resource Event Handling
   */
  const allParams = useParams();
  const [{ modals }, modalDispatch] = useModalState();
  const { data: property } = usePropertyById(allParams?.propertyId);
  const { data: project } = useProjectById(allParams?.projectId);

  const { data: asset, isLoading: isAssetLoading } = useAssetById(
    allParams?.assetId
  );

  const { queryInfo: propertiesOverviewInfo } = usePropertiesOverview();
  const { queryInfo: projectsOverviewInfo } = useProjectsOverview();

  const propertiesLoading = propertiesOverviewInfo.isLoading;
  const projectsLoading = projectsOverviewInfo.isLoading;

  const { refreshTimezone, calendarTimezone } = useCalendarTimezone(
    project,
    property,
    asset
  );
  const [{ calendarData, userDict, calendarView }, dispatch] = useAppState([]);

  const {
    selectedEventType,
    setSelectedEventType,
    setSelectedProjectIds,
    calendarLoading,
    selectedProjectIds,
    filteredEvents,
  } = useCalendarViewData({
    calendarTimezone,
  });

  useEffect(() => {
    refreshTimezone();
  }, [refreshTimezone]);
  // responsible for filtering events based on the selected resource
  // currently only set up for active projects

  const [tasksOpen, setTasksOpen] = useState(false);
  const [timezoneOpen, setTimezoneOpen] = useState(false);
  const [showSideBar, setShowSideBar] = useState(true);

  const { eventFilter, associationLock, spaceLock } = useRelativeAssociations();

  const taskList = useMemo(() => {
    switch (calendarView?.value) {
      case "day": {
        const beginningTimestamp = getBeginningTimeStamp(
          calendarData,
          calendarTimezone
        );
        const dayToParseTasksFrom = filteredEvents[beginningTimestamp];
        const eventWithAssociationCheck =
          eventFilter(dayToParseTasksFrom) ?? DAY_EVENT_MODEL;

        return getTaskListFromDay(eventWithAssociationCheck) ?? [];
      }
      case "week":
        return getWeek(calendarData, calendarTimezone).reduce((list, day) => {
          const dayToParseTasksFrom =
            eventFilter(filteredEvents?.[day.iso]) ?? DAY_EVENT_MODEL;

          return [...list, ...getTaskListFromDay(dayToParseTasksFrom)] ?? [];
        }, []);
      default:
        return getWeeks(calendarData, calendarTimezone).reduce((list, week) => {
          week.forEach((d) => {
            const dayToParseTasksFrom =
              eventFilter(filteredEvents?.[d.iso]) ?? DAY_EVENT_MODEL;
            const filteredTasksList = getTaskListFromDay(dayToParseTasksFrom);
            list.push(...filteredTasksList);
          });
          return list ?? [];
        }, []);
    }
  }, [
    calendarData,
    calendarTimezone,
    calendarView?.value,
    eventFilter,
    filteredEvents,
  ]);

  // Function to toggle visibility of the calandar sidebar
  const toggleShowSideBar = () => setShowSideBar(!showSideBar);

  /**
   * Associated Resource Event Handling
   */

  /**
   * Event Form Handling Start
   */

  const history = useHistory();

  const handleCreateModalOpen = useCallback(
    // The callback function accepts a `dateISO` parameter with a default value of `undefined`.
    (dateISO = undefined) => {
      const isEventModalOpen = modals?.find(
        (item) => item.modalType === CREATE_EVENT_MODAL
      );

      const urlObj = new URL(window.location.href);
      const resource = urlObj.pathname.split("/")[1];

      // this location is specific to the properties page
      // you have to select an asset form the properties page
      // example: https://localhost:8080/properties/8ea27855-725a-46f4-aafe-c6cc443e8327/asset/d67ea7c5-25a1-48be-a84d-d00a90efd4f0?tab=calendar
      const assetFromProperty = urlObj.pathname.split("/")[3];
      const { searchParams } = urlObj;
      const tabValue = searchParams.get("tab");

      // If the modal is already open or the user does not have permission to create an event, return.
      if (
        isEventModalOpen ||
        !hasWritePermission(EVENT, currentUser) ||
        resource === "assets" ||
        tabValue === "assets" ||
        assetFromProperty === "asset"
      ) {
        return;
      }
      // Dispatch an action to manage the modal's status.
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: uuidv4() },
        modalData: { associationLock: false, dateISO },
        modalType: CREATE_EVENT_MODAL,
      });
    },
    [currentUser, modalDispatch, modals]
  );

  const handleCreateTask = useCallback(() => {
    const isTaskModalOpen = modals?.find(
      (item) => item.modalType === CREATE_TASK_MODAL
    );
    // If the modal is already open or the user does not have permission to create an event, return.
    if (isTaskModalOpen || !hasWritePermission(TASK, currentUser)) {
      return;
    }
    // Dispatch an action to manage the modal's status.
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { associationLock, spaceLock },
      modalType: CREATE_TASK_MODAL,
    });
  }, [spaceLock, associationLock, currentUser, modalDispatch, modals]);

  useEffect(() => {
    // optionally add event to CTA when in Calendar tab view
    if (
      !allParams?.assetId &&
      isTabView &&
      hasWritePermission(EVENT, currentUser)
    ) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add Event")) {
          return [
            {
              title: "Add Event",
              onClick: () => handleCreateModalOpen(),
              tabAction: true,
            },
            ...prev,
          ];
        }
        return prev;
      });
    }
    // optionally add task CTA when in Calendar tab view
    if (isTabView && hasWritePermission(TASK, currentUser)) {
      setButtonActions((prev) => {
        if (!prev.find((opt) => opt.title === "Add Task")) {
          return [
            ...prev,
            {
              title: "Add Task",
              onClick: () => handleCreateTask(),
              tabAction: true,
            },
          ];
        }
        return prev;
      });
    }
  }, [
    allParams?.assetId,
    associationLock,
    currentUser,
    handleCreateModalOpen,
    handleCreateTask,
    isTabView,
    setButtonActions,
  ]);

  /**
   * Event Filters
   */

  /**
   * Event Filters
   */

  /**
   * Calendar Navigation: Start
   * Note: Clear Offset when naviging
   */

  const [offset, setOffset] = useState(0);

  const pathname = history?.location?.pathname;

  const newQueryString = useMemo(() => {
    return new URLSearchParams(history?.location?.search);
  }, [history?.location?.search]);

  // function to return the a moment object rounded to the nearest half hour
  // the function should take and argument "next", "prev", or "now"

  const showNext = useCallback(() => {
    setOffset(0);
    let next;
    switch (calendarView?.value) {
      case "year":
        next = moment
          .tz(calendarData, calendarTimezone)
          .startOf("year")
          .add(1, "years")
          .format();
        dispatch({
          type: SET_CALENDAR_DATA,
          data: next,
        });
        break;
      case "week":
        next = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .add(1, "weeks")
          .format();
        dispatch({
          type: SET_CALENDAR_DATA,
          data: next,
        });
        break;
      case "day":
        next = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .add(1, "days");
        newQueryString.set(
          "date",
          roundToNearestHalfHour(
            adjustToCurrentTime(
              moment.tz(calendarData, calendarTimezone).add(1, "days")
            )
          ).format()
        );

        history.push(`${pathname}?${newQueryString.toString()}`);

        dispatch({
          type: SET_CALENDAR_DATA,
          data: next,
        });
        break;
      case "schedule":
        next = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .add(1, "months")
          .format();
        dispatch({
          type: SET_CALENDAR_DATA,
          data: next,
        });
        break;
      default:
        next = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .add(1, "months")
          .format();
        dispatch({
          type: SET_CALENDAR_DATA,
          data: next,
        });
    }
  }, [
    calendarView?.value,
    dispatch,
    calendarData,
    newQueryString,
    history,
    pathname,
    calendarTimezone,
  ]);

  const showPrev = useCallback(() => {
    setOffset(0);
    let prev;
    switch (calendarView?.value) {
      case "week":
        prev = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .subtract(1, "weeks")
          .format();

        dispatch({
          type: SET_CALENDAR_DATA,
          data: prev,
        });
        break;
      case "year":
        dispatch({
          type: SET_CALENDAR_DATA,
          data: moment
            .tz(calendarData, calendarTimezone)
            .startOf("year")
            .subtract(1, "years")
            .format(),
        });
        break;
      case "day":
        prev = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .subtract(1, "days")
          .format();

        newQueryString.set(
          "date",
          roundToNearestHalfHour(
            adjustToCurrentTime(
              moment.tz(calendarData, calendarTimezone).subtract(1, "days")
            )
          ).format()
        );

        history.push(`${pathname}?${newQueryString.toString()}`);

        dispatch({
          type: SET_CALENDAR_DATA,
          data: prev,
        });
        break;
      case "schedule":
        prev = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .subtract(1, "months")
          .format();

        dispatch({
          type: SET_CALENDAR_DATA,
          data: prev,
        });
        break;
      default:
        prev = moment
          .tz(calendarData, calendarTimezone)
          .startOf("day")
          .subtract(1, "months")
          .format();

        dispatch({
          type: SET_CALENDAR_DATA,
          data: prev,
        });
    }
  }, [
    calendarView?.value,
    calendarData,
    dispatch,
    newQueryString,
    history,
    pathname,
    calendarTimezone,
  ]);

  const showToday = useCallback(() => {
    setOffset(0);
    dispatch({
      type: SET_CALENDAR_DATA,
      data: moment.tz(new Date(), calendarTimezone).format(),
    });
  }, [dispatch, calendarTimezone]);

  const handleChangeView = useCallback(
    (val) => {
      setOffset(0);
      modalDispatch({ type: REMOVE_POSITIONED_MODALS });
      dispatch({
        type: SET_CALENDAR_VIEW,
        value: val,
      });
    },
    [dispatch, modalDispatch]
  );

  /**
   * Calendar Navigation: End
   */

  /**
   * Event Handling: Start
   */

  const handleEventClick = useCallback(
    (event, containerRef) => {
      const { width, height, left, top } =
        containerRef.current.getBoundingClientRect();
      if (event.resource === "Task") {
        modalDispatch({
          type: ADD_OPEN_MODAL,
          ref: { id: event.id },
          position: {
            x: left,
            y: top,
          },
          modalData: { item: event, width, height },
          modalType: VIEW_TASK_MODAL,
        });
        return;
      }
      modalDispatch({
        type: ADD_OPEN_MODAL,
        ref: { id: event.id },
        position: {
          x: left,
          y: top,
        },
        modalData: { item: event, width, height },
        modalType: VIEW_EVENT_MODAL,
      });
    },
    [modalDispatch]
  );

  const prevCalendarViewRef = useRef();

  useEffect(() => {
    const QueryString = new URLSearchParams(history?.location?.search);

    // Check if current value is 'day' but previous value was not 'day'
    if (
      calendarView?.value === "day" &&
      prevCalendarViewRef.current !== "day"
    ) {
      QueryString.set(
        "date",
        roundToNearestHalfHour(
          adjustToCurrentTime(moment.tz(calendarData, calendarTimezone))
        ).format()
      );
    } else if (calendarView?.value !== "day") {
      QueryString.delete("date");
    }

    history.push(`${pathname}?${QueryString.toString()}`);

    // Store the current value in the ref for the next render cycle
    prevCalendarViewRef.current = calendarView?.value;
  }, [calendarView?.value, history, pathname, calendarData, calendarTimezone]);

  /**
   * Event Handling: End
   */

  const openCalendar = useCallback(() => {
    switch (calendarView?.value) {
      case "schedule":
        return (
          <ScheduleCalendar
            events={filteredEvents}
            calendarLoading={calendarLoading}
            now={calendarData}
            handleEventClick={handleEventClick}
            offset={offset}
            setOffset={setOffset}
          />
        );
      case "week":
        return (
          <WeekCalendar
            events={filteredEvents}
            now={calendarData}
            handleEventClick={handleEventClick}
            offset={offset}
            setOffset={setOffset}
          />
        );
      case "year":
        return (
          <YearCalendar
            events={filteredEvents}
            now={calendarData}
            handleEventClick={handleEventClick}
          />
        );
      case "day":
        return (
          <DayCalendar
            events={filteredEvents}
            now={calendarData}
            handleEventClick={handleEventClick}
            setOffset={setOffset}
            offset={offset}
          />
        );
      default:
        return (
          <Calendar
            handleCreateModalOpen={handleCreateModalOpen}
            isTabView={isTabView}
            now={calendarData}
            events={filteredEvents}
            isTest={isTest}
            onClick={
              !propertiesLoading &&
              !projectsLoading &&
              !isAssetLoading &&
              userDict
                ? handleEventClick
                : () => {}
            }
          />
        );
    }
  }, [
    calendarView?.value,
    filteredEvents,
    calendarData,
    handleEventClick,
    offset,
    propertiesLoading,
    projectsLoading,
    isAssetLoading,
    userDict,
    handleCreateModalOpen,
    calendarLoading,
    isTabView,
    isTest,
  ]);

  const handleHideDDC = () => {
    if (allParams?.projectId || allParams?.propertyId) {
      return false;
    }
    return true;
  };

  const handleGetTitle = () => {
    if (allParams?.projectId) {
      return project?.name;
    }
    if (allParams?.propertyId) {
      return property?.title;
    }
    // if the params have the asset id return asset name
    if (allParams?.assetId) {
      return asset?.name;
    }
    return "Calendar";
  };

  /**
   * Available Header Create Options
   */
  const addOptions = useMemo(() => {
    const list = [];
    // determine if a new event modal is already open
    const openEventModal = modals?.find(
      (item) => item.modalType === CREATE_EVENT_MODAL
    );
    // only add the event option if there is no open event modal
    if (!openEventModal && hasWritePermission(EVENT, currentUser)) {
      list.push({
        title: "Event",
        onClick: () => handleCreateModalOpen(),
      });
    }
    // determine if a new task modal is already open
    const openTaskModal = modals?.find(
      (item) => item.modalType === CREATE_TASK_MODAL
    );
    // only add the task option if there is no open task modal
    if (!openTaskModal && hasWritePermission(TASK, currentUser)) {
      list.push({
        title: "Task",
        onClick: () => handleCreateTask(),
      });
    }
    return list;
  }, [currentUser, handleCreateModalOpen, handleCreateTask, modals]);

  return (
    <>
      {/* @TODO REMOVE THIS */}
      {isTest && (
        <div>
          <p>currently undergoing test</p>
        </div>
      )}
      {!isTest && (
        <div
          id="calendarWrapper"
          className={`${isTabView ? "h-5/6 " : ""} w-full`}
        >
          <>
            {!isTabView && (
              <SiteHeader
                title={handleGetTitle() || "Loading"}
                dropdownRoutes={
                  (allParams?.projectId &&
                    getProjectOptions(allParams?.projectId, currentUser)) ||
                  (allParams?.propertyId &&
                    getPropertyOptions(allParams?.propertyId, currentUser))
                }
                buttons={
                  hasWritePermission(EVENT, currentUser) && (
                    <PrimaryButton
                      resourceName="Event"
                      actionLabel="Create"
                      dropdownItems={addOptions}
                      disabled={addOptions.length === 0}
                      addButton
                    />
                  )
                }
                hideDropdownContainer={handleHideDDC()}
              />
            )}
          </>
          <WidgetContainer
            className={`${!isTabView && "mb-10"} shadow-lg pb-0 pt-0 pl-4 pr-4`}
            childClassName="flex"
          >
            <div className="flex flex-col flex-1 gap-6 p-4 pt-8 h-full">
              <div className="flex justify-end items-center">
                <CalendarControl
                  showNext={showNext}
                  showPrev={showPrev}
                  showToday={showToday}
                  date={calendarData}
                  views={calendarViewOptions}
                  view={calendarView}
                  onChange={handleChangeView}
                  tasksOpen={tasksOpen}
                  setTasksOpen={setTasksOpen}
                  setTimezoneOpen={setTimezoneOpen}
                  timezoneOpen={timezoneOpen}
                  tasks={taskList}
                  calendarTimezone={calendarTimezone}
                />
                {!showSideBar && (
                  <CalendarSidebarToggle
                    showSideBar={showSideBar}
                    setShowSideBar={setShowSideBar}
                    toggleOpenSidebar={toggleShowSideBar}
                  />
                )}
              </div>
              {openCalendar()}
            </div>
            {showSideBar && (
              <>
                <div className="min-h-full border-l-2 mx-4" />
                <div className="flex flex-col h-full items-center mt-10">
                  <CalendarSidebar
                    now={calendarData}
                    selectedEventType={selectedEventType}
                    setSelectedEventType={setSelectedEventType}
                    setSelectedProjectIds={setSelectedProjectIds}
                    selectedProjectIds={selectedProjectIds}
                    currentUser={currentUser}
                    tasksOpen={tasksOpen}
                    toggleOpenSidebar={toggleShowSideBar}
                  />
                </div>
              </>
            )}
          </WidgetContainer>
        </div>
      )}
    </>
  );
};

CalendarView.propTypes = {
  userEvents: PropTypes.shape({}),
  addUserEvent: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  isTest: PropTypes.bool,
  currentUser: PropTypes.shape({
    reference: PropTypes.string,
    permissions: PropTypes.shape({
      event: PropTypes.shape({
        can_write: PropTypes.bool,
      }),
    }),
    hasPermission: PropTypes.func,
    isSuperAdmin: PropTypes.bool,
    isAdmin: PropTypes.bool,
  }),
  isTabView: PropTypes.bool,
  setButtonActions: PropTypes.func,
};

CalendarView.defaultProps = {
  userEvents: undefined,
  addUserEvent: undefined,
  updateUserEvent: undefined,
  isTest: false,
  currentUser: undefined,
  isTabView: false,
  setButtonActions: () => {},
};

export default CalendarView;
