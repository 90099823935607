/* eslint-disable consistent-return */
const getUpdatedAllTaskSpacesAssets = ({
  currentEntities,
  resourceName,
  newCompletionStatus,
  entity,
}) => {
  const updatedEntityList = currentEntities;
  const spaces = [];
  const assets = [];
  updatedEntityList.forEach((item) => {
    if (
      entity?.ref === item?.ref ||
      (resourceName === "assets" && item?.ref && entity?.length)
    ) {
      return assets.push({ ...item, isCompleted: newCompletionStatus });
    }
    if (
      entity?.id === item?.id ||
      (resourceName === "spaces" && item?.id && entity?.length)
    ) {
      return spaces.push({ ...item, isCompleted: newCompletionStatus });
    }
    // return non complete spaces
    if (item?.id) {
      return spaces.push({ ...item });
    }

    // return non complete assets
    if (item?.ref) {
      return assets.push({ ...item });
    }
  });
  return { spaces, assets };
};

export default getUpdatedAllTaskSpacesAssets;
