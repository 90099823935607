import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import { useAppState } from "../../../../state/appState";
import ScheduleRow from "./ScheduleRow";
import getUniqueKey from "../../../../helpers/Calendar/getUniqueKey";
import { usePropertiesOverview } from "../../../../hooks/properties";
import { useProjectsOverview } from "../../../../hooks/projects";
import { useAssetsOverview } from "../../../../hooks/assets";

const rowCN = (className) => cntl`
  pb-1
  w-full
  ${className}
`;

const ScheduleBody = ({
  collapsed,
  dayValue,
  dateStart,
  handleEventClick,
  pageSize,
  count,
}) => {
  const [allEvents, setAllEvents] = useState([]);
  const [{ calendarTimezone }] = useAppState();

  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const containerRef = useRef(null);

  const dateParts = useMemo(() => {
    let parts = {};
    const day = moment.tz(dayValue, calendarTimezone).format("llll");
    let dayArray = day.replaceAll(",", "");
    dayArray = dayArray.split(" ");
    parts = {
      ...parts,
      day: dayArray[0],
      date: dayArray[2],
      month: dayArray[1],
    };
    return parts;
  }, [dayValue, calendarTimezone]);

  useEffect(() => {
    setAllEvents(
      collapsed?.map((event) => {
        const resource = event?.association?.split("/")[0];
        switch (resource) {
          case "Project": {
            return {
              ...event,
              associationName: projectDict[event?.association]?.name,
            };
          }
          case "Property": {
            return {
              ...event,
              associationName: propertiesDict[event?.association]?.title,
            };
          }
          case "Asset": {
            return {
              ...event,
              associationName: assetsDict[event?.association]?.title,
            };
          }
          default: {
            return {
              ...event,
              associationName: " N/A",
            };
          }
        }
      })
    );
  }, [assetsDict, collapsed, projectDict, propertiesDict]);

  return (
    <div
      id={`${count === pageSize - 1 ? "load-more" : ""}`}
      className="flex flex-row w-full max-h-full overflow-y-scroll hide-scrollbar relative border-t"
    >
      <div
        style={{
          backgroundColor: `${dateStart ? "#E8F8F5" : "#f9f9f9"}`,
          minHeight: "100%",
        }}
        className="flex flex-col min-h-full justify-center text-center w-14 items-center"
      >
        <div className={`py-4 ${dateStart && "text-brandGreen"}`}>
          <p className="text-xs font-semibold uppercase">{dateParts.day}</p>
          <p className="text-xs font-bold">{dateParts.date}</p>
          <p className="text-xs font-semibold uppercase">{dateParts.month}</p>
        </div>
      </div>
      <div ref={containerRef} className="flex flex-col h-full py-6 w-full ml-5">
        {allEvents.map((item, index) => {
          const key = getUniqueKey(item, index);

          return (
            <div key={key} className={rowCN(item?.daySpan && "relative")}>
              <button
                type="button"
                onClick={() => {
                  handleEventClick(item, containerRef);
                }}
                className="w-full"
                aria-label="View"
              >
                <ScheduleRow item={item} calendarTimezone={calendarTimezone} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ScheduleBody.propTypes = {
  collapsed: PropTypes.arrayOf(PropTypes.shape({})),
  dayValue: PropTypes.string,
  dateStart: PropTypes.string,
  handleEventClick: PropTypes.func,
  pageSize: PropTypes.number,
  count: PropTypes.number,
};

ScheduleBody.defaultProps = {
  collapsed: [],
  dayValue: "",
  dateStart: "PropTypes.string",
  handleEventClick: () => {},
  pageSize: 40,
  count: 40,
};

export default ScheduleBody;
