import cntl from "cntl";
import React from "react";
import { TASK } from "../../../../../constants";
import { hasUpdatePermission } from "../../../../../helpers/Permissions";
import formatTasks, {
  statusPillCN,
} from "../../../../../helpers/ServiceRequest/formatTasks";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import TertiaryButton from "../../../Buttons/TertiaryButton";
import CommentButton from "../../../CommentButton";
import Pill from "../../../Pill/Pill";
import BackButton from "../../../WidgetEditorView/Header/BackButton";
import TaskDeleteButton from "../TaskDeleteButton";
import TaskEditButton from "../TaskEditButton";
import useHeaderControlItemsData from "./useHeaderControlItemsData";

const statusCN = (status) => cntl`
border 
flex 
items-center 
rounded-full
font-medium
text-sm
py-2 
px-3
w-36
cursor-default
${status}
`;

const HeaderControlItems = ({
  currentTask,
  editingMode,
  cancelEditing,
  handleSaveClick,
  handleDeleteClick,
  handleEditClick,
  handleCompleteClick,
  handleOpenComments,
  isSaving,
  saveClassName,
  commentClassName,
  onBackToWfPressed,
}) => {
  const { currentUser, canTaskBeCompleted } = useHeaderControlItemsData({
    currentTask,
  });

  return (
    <>
      <div className="flex justify-between items-center">
        {onBackToWfPressed && (
          <div className="flex flex-col">
            <BackButton
              buttonStyle={{ width: "100%" }}
              title={`Task: ${currentTask?.name}`}
              onBackPressed={() => onBackToWfPressed(currentTask)}
            />
            <div className="mt-3">
              <Pill
                value={formatTasks(currentTask)?.formattedStatus}
                background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
                className={statusCN(
                  statusPillCN(formatTasks(currentTask)?.formattedStatus)
                )}
              />
            </div>
          </div>
        )}
        {!onBackToWfPressed && (
          <Pill
            value={formatTasks(currentTask)?.formattedStatus}
            background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
            className={statusCN(
              statusPillCN(formatTasks(currentTask)?.formattedStatus)
            )}
          />
        )}
        <div className="flex justify-center items-center">
          {!editingMode && canTaskBeCompleted && (
            <PrimaryButton
              className="mr-4 bg-primaryGreen"
              title="Mark As Complete"
              onClick={handleCompleteClick}
            />
          )}
          {handleOpenComments && (
            <CommentButton
              onClick={handleOpenComments}
              commentClassName={commentClassName}
            />
          )}
          {editingMode && (
            <>
              <TertiaryButton
                className="px-4 py-2 mr-4"
                onClick={cancelEditing}
                title="Cancel"
                disabled={isSaving}
              />
              <PrimaryButton
                saveButton
                className={`bg-primaryGreen px-4 py-2 rounded-lg ${saveClassName}`}
                onClick={handleSaveClick}
                title="save"
                disabled={isSaving}
              />
            </>
          )}

          <TaskEditButton
            handleEditClick={handleEditClick}
            canShow={
              currentTask.status !== "done" &&
              currentTask.status !== "incomplete" &&
              hasUpdatePermission(TASK, currentUser) &&
              !editingMode
            }
          />

          <TaskDeleteButton
            handleDeleteClick={handleDeleteClick}
            canShow={
              currentTask.status !== "done" &&
              currentTask.status !== "incomplete"
            }
          />
        </div>
      </div>
    </>
  );
};

export default HeaderControlItems;
