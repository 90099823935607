import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Modal from "../../../stories/Components/Modal/Modal";
import {
  ASSET_TABLE_PATH,
  GET_PROJECT_ASSETS_PATH,
  GET_PROPERTY_VIEW_PATH,
} from "../../../constants";
import { useAssetsDelete } from "../../../hooks/assets";

export default function AssetDeleteModal({
  asset,
  showDeleteModal,
  setShowDeleteModal,
}) {
  const history = useHistory();
  const { mutate: removeAsset, isLoading } = useAssetsDelete();

  const onDeleteClicked = () => {
    const routeParts = history.location.pathname?.split("/");

    removeAsset(asset?.id);
    if (routeParts[1] === "properties")
      history.push(GET_PROPERTY_VIEW_PATH(routeParts[2]));
    else if (routeParts[1] === "projects")
      history.push(GET_PROJECT_ASSETS_PATH(routeParts[2]));
    else history.push(ASSET_TABLE_PATH);
  };

  return (
    <Modal
      title="Delete Asset?"
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
      disabled={isLoading}
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {asset?.name}? Once deleted, this
          asset and its data cannot be recovered.
        </p>
      </>
    </Modal>
  );
}

AssetDeleteModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
};

AssetDeleteModal.defaultProps = {
  asset: null,
  showDeleteModal: false,
  setShowDeleteModal: () => {},
};
