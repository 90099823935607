import taskKeys from "../../hooks/tasks/taskKeys";

const editTaskStore = ({ queryClient, returnedData }) => {
  queryClient.setQueryData(
    taskKeys.singleTask(returnedData.data.id),
    () => returnedData
  );
};

export default editTaskStore;
