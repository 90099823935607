import React from "react";
import AddSpacesAssetsWidget from "../../AddSpacesAssetsWidget";
import LinksRow from "../../Links/LinksRow";
import PopoverComments from "../../PopoverComments";
import TagsContainer from "../../Tags/TagsContainer";
import Widget from "../../Widget/Widget";
import WidgetEditorView from "../../WidgetEditorView";
import AttachmentsSection from "./AttachmentsSection";
import HeaderControlItems from "./HeaderControlItems";
import HeaderNavigationTitle from "./HeaderNavigationTitle";
import MediaSection from "./MediaSection";
import TaskActivity from "./TaskActivity";
import TaskClosingSection from "./TaskClosingSection";
import TaskGeneralInfo from "./TaskGeneralInfo";
import TaskHistory from "./TaskHistory";
import TaskStepsSection from "./TaskSteps";
import TaskTitle from "./TaskTitle";
import useSingleTaskViewData from "./useSingleTaskViewData";
import RsvpRow from "../../RsvpRow";

const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };

const SingleTaskView = ({
  currentTask,
  clearCurrentTask,
  setCurrentTask,
  usedSopDict,
  page,
  onBackToWfPressed,
  showBackButton = true,
}) => {
  const {
    allDayData,
    nameForm,
    allInviteesInfo,
    editingMode,
    dateForm,
    timeForm,
    endDateForm,
    endTimeForm,
    steps,
    currentInvitees,
    inviteOpenPopup,
    completedBy,
    createdBy,
    inviteeArray,
    tagsForm,
    filesState,
    taskForm,
    sopOptions,
    commentsData,
    fromPage,
    isSaving,
    isActivityOpen,
    isTaskComplete,
    isPastTask,
    recurrenceDropDownRef,
    recurrenceForm,
    recurrenceString,
    handleCompleteClick,
    handleOpenComments,
    handleCloseComments,
    handleStepAdd,
    handleStepEdit,
    handleStepRemove,
    handleLeaveSingleView,
    handleFilesToAdd,
    handleFilesToRemove,
    handleTagsChange,
    handleAddInvitee,
    handleRemoveInvitee,
    setInviteOpenPopup,
    removeAssignee,
    cancelEditing,
    handleEditClick,
    handleDeleteClick,
    handleSaveClick,
    setFormName,
    setFormDate,
    setFormTime,
    setFormEndDate,
    setFormEndTime,
    setFormRecurrence,
    handleAddLink,
    handleRemoveLink,
    onActivityClick,
    handleDescriptionChange,
    handlePriorityChange,
    handleRemoveSpaces,
    handleSelectSpaces,
    setTaskForm,
    showRSVP,
  } = useSingleTaskViewData({
    currentTask,
    setCurrentTask,
    usedSopDict,
    clearCurrentTask,
    page,
  });
  return (
    <>
      {/* If single task view is rendered as page from another resource
        it shows the resource name. */}
      {page && fromPage && (
        <div className="mb-10">
          <HeaderNavigationTitle fromPage={fromPage} />
        </div>
      )}

      {/* If single task view is rendered as page from another resource,
        it needs to show the back button with navigation and wrapped into a widget */}
      <WidgetEditorView
        showBackButton={showBackButton}
        hideBorder={!page}
        backButtonTitle={page && !fromPage ? "Tasks" : undefined}
        hideFooter
        onBackPressed={handleLeaveSingleView}
        overFlow
      >
        {!onBackToWfPressed && (
          <TaskTitle
            isEditing={editingMode}
            value={nameForm}
            onChange={setFormName}
            currentTask={currentTask}
            page={!fromPage}
            onActivityClick={onActivityClick}
            showActivityButton={!isActivityOpen}
            disabled={isPastTask}
          />
        )}
        {showRSVP && (
          <div className="mb-6">
            <RsvpRow
              isRecurring={currentTask?.recurrence}
              reference={currentTask?.reference}
              instanceStartDate={currentTask?.instanceStartDate}
              isEvent={currentTask?.resource === "Event"}
            />
          </div>
        )}

        <Widget draggable={false} title={null} overflow>
          {!isActivityOpen && (
            <>
              <HeaderControlItems
                onBackToWfPressed={onBackToWfPressed}
                saveClassName="justify-center w-16"
                currentTask={currentTask}
                editingMode={editingMode}
                cancelEditing={cancelEditing}
                handleSaveClick={handleSaveClick}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
                handleCompleteClick={handleCompleteClick}
                handleOpenComments={handleOpenComments}
                isSaving={isSaving}
                title="Task"
                nameForm={nameForm}
              />

              <div className="mt-4">
                <TaskGeneralInfo
                  allDayData={allDayData}
                  currentTask={currentTask}
                  editingMode={editingMode}
                  dateForm={dateForm}
                  timeForm={timeForm}
                  endDateForm={endDateForm}
                  endTimeForm={endTimeForm}
                  inviteOpenPopup={inviteOpenPopup}
                  currentInvitees={currentInvitees}
                  inviteeArray={inviteeArray}
                  tagsForm={tagsForm}
                  completedBy={completedBy}
                  createdBy={createdBy}
                  allInviteesInfo={allInviteesInfo}
                  setFormTime={setFormTime}
                  setFormDate={setFormDate}
                  setFormEndDate={setFormEndDate}
                  setFormEndTime={setFormEndTime}
                  setFormRecurrence={setFormRecurrence}
                  handleAddInvitee={handleAddInvitee}
                  handleRemoveInvitee={handleRemoveInvitee}
                  setInviteOpenPopup={setInviteOpenPopup}
                  removeAssignee={removeAssignee}
                  handleDescriptionChange={handleDescriptionChange}
                  handlePriorityChange={handlePriorityChange}
                  taskForm={taskForm}
                  isPastTask={isPastTask}
                  recurrenceDropDownRef={recurrenceDropDownRef}
                  recurrenceForm={recurrenceForm}
                  recurrenceString={recurrenceString}
                />
              </div>
              <div className="mt-6">
                <AddSpacesAssetsWidget
                  form={taskForm}
                  setForm={setTaskForm}
                  isEditing={editingMode}
                  handleRemove={handleRemoveSpaces}
                  handleSelect={handleSelectSpaces}
                  checkbox
                  currentTask={currentTask}
                  setCurrentTask={setCurrentTask}
                  resource="spaces"
                  disabled={isTaskComplete || (editingMode && isPastTask)}
                />
              </div>
              <div className="mt-8">
                <AddSpacesAssetsWidget
                  form={taskForm}
                  setForm={setTaskForm}
                  isEditing={editingMode}
                  handleRemove={handleRemoveSpaces}
                  handleSelect={handleSelectSpaces}
                  checkbox
                  currentTask={currentTask}
                  setCurrentTask={setCurrentTask}
                  resource="assets"
                  disabled={isTaskComplete || (editingMode && isPastTask)}
                />
              </div>

              <div className="mt-4">
                <TaskStepsSection
                  editingMode={editingMode}
                  sopsForTask={steps}
                  taskForm={taskForm}
                  currentTask={currentTask}
                  setCurrentTask={setCurrentTask}
                  sopOptions={sopOptions}
                  handleStepAdd={handleStepAdd}
                  handleStepEdit={handleStepEdit}
                  handleStepRemove={handleStepRemove}
                  singleTaskView
                  isPastTask={isPastTask}
                />
              </div>
              <div className="border-b my-6" />
              <div className="flex flex-row items-center justify-start w-full">
                <p
                  className="text-gray-450 flex font-semiBold text-md self-start"
                  style={labelWidth}
                >
                  Media
                </p>
                <div className="flex flex-wrap">
                  <MediaSection
                    editingMode={editingMode}
                    taskForm={taskForm}
                    currentTask={currentTask}
                    filesState={filesState}
                    handleFilesToAdd={handleFilesToAdd}
                    handleFilesToRemove={handleFilesToRemove}
                    noTitle
                  />
                </div>
              </div>
              <div className="border-b my-6" />
              <div className="flex flex-row justify-start w-full my-2">
                <p
                  className="text-gray-450 flex font-semiBold text-md self-start"
                  style={labelWidth}
                >
                  Attachments
                </p>
                <div className="flex flex-wrap">
                  <AttachmentsSection
                    singleTask
                    editingMode={editingMode}
                    taskForm={taskForm}
                    currentTask={currentTask}
                    filesState={filesState}
                    handleFilesToAdd={handleFilesToAdd}
                    handleFilesToRemove={handleFilesToRemove}
                    noTitle
                  />
                </div>
              </div>
              <div className="border-b my-6" />
              <div className="flex flex-row justify-start w-full items-center">
                <p
                  className="text-gray-450 flex font-semiBold text-md self-start"
                  style={labelWidth}
                >
                  Tags
                </p>
                <div className="flex flex-wrap">
                  <TagsContainer
                    isEditing={editingMode}
                    resource={{ currentTags: tagsForm }}
                    dispatch={handleTagsChange}
                    noTitle
                    menuPlacement="top"
                    className="my-0 py-0"
                  />
                </div>
              </div>

              <div className="border-b my-6" />
              <LinksRow
                isEditing={editingMode}
                form={taskForm}
                currentTask={currentTask}
                handleAddLink={handleAddLink}
                handleRemoveLink={handleRemoveLink}
              />

              {commentsData.isOpen && (
                <PopoverComments
                  isOpen={commentsData.isOpen}
                  resourceReference={commentsData.taskReference}
                  association={commentsData.association}
                  onClose={handleCloseComments}
                />
              )}
            </>
          )}
          {isActivityOpen && <TaskActivity currentTask={currentTask} />}
        </Widget>
        {page && (
          <Widget className="mt-6" draggable={false} title={null}>
            <TaskHistory
              taskForm={taskForm}
              setCurrentTask={setCurrentTask}
              currentTask={currentTask}
            />
          </Widget>
        )}
      </WidgetEditorView>
      {!isActivityOpen &&
        !taskForm?.recurrence &&
        taskForm?.status === "done" && (
          <div className="mt-2 px-2">
            <Widget draggable={false} title={null}>
              <TaskClosingSection taskForm={taskForm} />
            </Widget>
          </div>
        )}
      {!isActivityOpen && taskForm?.recurrence && (
        <div className="mt-2 px-2">
          {/* Check to see if its in single task view or under a resource */}
          {!page && (
            <Widget draggable={false} title={null}>
              <TaskHistory
                currentTask={currentTask}
                setCurrentTask={setCurrentTask}
              />
            </Widget>
          )}
        </div>
      )}
    </>
  );
};

export default SingleTaskView;
