/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import useAssetFormReducer from "../../../hooks/useAssetFormReducer";
import useCurrentUser from "../../../hooks/useCurrentUser";

import { tagKeys } from "../../../config/reactQuery/queryKeyFactory";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  EXPENSE_CREATE_MODAL,
} from "../../../constants";
import clearSearchField from "../../../helpers/clearSearchField";
import { onUpdateFile } from "../../../helpers/File";
import {
  hasDeletePermission,
  hasReadPermission,
  hasWritePermission,
} from "../../../helpers/Permissions";
import { toastError, toastMessage } from "../../../helpers/Toast";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import useAttributes from "../../../hooks/useAttributes";
import useEditModal from "../../../hooks/useEditModal";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import useServiceRequestTopButtons from "../../../hooks/useServiceRequestTopButtons";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import { useModalState } from "../../../state/modalState";
import whiteCircleCheckIcon from "../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import FormAvatar from "../../../stories/Components/Avatar/FormAvatar";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import Widget from "../../../stories/Components/Widget/Widget";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import CalendarView from "../../Calendar/CalendarView";
import ServiceRequestsOverview from "../ServiceRequests";
import TaskList from "../Task/TaskList";
import AssetDeleteModal from "./AssetDeleteModal";
import AssetDetailView from "./AssetDetailView";
import AssetExpences from "./AssetExpenses";
import AssetFinances from "./AssetFinances";
import AssetMaintenance from "./AssetMaintenance";
import useAssetDetailsData from "./useAssetDetailsData";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const AssetDetails = ({
  userEvents,
  updateUserEvent,
  isTest,
  reloadEvents,
}) => {
  const { assetId, asset, isLoading, assetsDict, patchAsset, patchAssetAsync } =
    useAssetDetailsData();

  const { data: currentUser } = useCurrentUser();
  const [measurements, reloadMeasurements] = useAttributes();

  const params = useMemo(
    () => ({ association: `Asset/${assetId}` }),
    [assetId]
  );
  const {
    associatedFiles,
    addFile,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);

  const [editedAsset, dispatch] = useAssetFormReducer();
  const [, setIsEditModalOpen] = useEditModal();
  const queryClient = useQueryClient();

  const [editing, setEditing] = useState(false);
  const [newAttributes, setNewAttributes] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [buttonActions, setButtonActions] = useState([]);
  const [isEditableTab, setIsEditableTab] = useState(false);

  const { associationLock, assetLock } = useRelativeAssociations();
  const [{ modals }, modalDispatch] = useModalState();

  const filterButtonActions = useCallback(
    (currentButtonActions) => {
      const newEventModalAlreadyOpen = modals?.some(
        (modal) => modal?.modalType === CREATE_EVENT_MODAL
      );
      const newTaskModalAlreadyOpen = modals?.find(
        (modal) => modal?.modalType === CREATE_TASK_MODAL
      );

      return currentButtonActions?.filter((action) => {
        if (newEventModalAlreadyOpen && action?.title === "Add Event") {
          return false;
        }
        if (newTaskModalAlreadyOpen && action?.title === "Add Task") {
          return false;
        }
        return true;
      });
    },
    [modals]
  );

  useEffect(() => {
    if (asset) {
      dispatch({
        type: "reset",
        asset,
      });
    }
  }, [asset, dispatch]);

  const handleEditClick = useCallback(() => {
    if (!editing) {
      setEditing(true);
      return;
    }
    setEditing(false);
  }, [editing]);

  const resetAssetState = useCallback(() => {
    dispatch({
      type: "reset",
      asset,
    });
  }, [asset, dispatch]);

  const handleAddMedia = useCallback(
    (imageResources) => {
      const updatedFiles = [
        ...asset.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        asset.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedAsset = {
        ...asset,
        files: updatedFiles,
        primaryImage: primary,
      };

      patchAsset({ updatedAsset, asset });

      addFiles(imageResources);
    },
    [addFiles, asset, patchAsset]
  );

  const updateAssetFiles = useCallback(
    (fileRefs) => {
      const updatedFiles = asset.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );

      let updatedAsset = {
        ...asset,
        files: updatedFiles,
      };

      // use next image as primary if deleted media contains primary image
      if (fileRefs.includes(asset?.primaryImage)) {
        updatedAsset = {
          ...updatedAsset,
          primaryImage: updatedFiles.find((file) => file.category === "Photos")
            ?.ref,
        };
      }

      patchAsset({ updatedAsset, asset });
    },
    [asset, patchAsset]
  );

  const handleRemoveMedia = useCallback(
    (imageRefs) => {
      // filter out media to delete
      const updatedFiles = asset.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      // use next image as primary if deleted media contains primary image
      const primary = imageRefs.includes(asset?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : asset?.primaryImage;
      // set the updated asset
      const updatedAsset = {
        ...asset,
        files: updatedFiles,
        primaryImage: primary,
      };

      patchAsset(
        { updatedAsset, asset },
        {
          onSuccess: () => {
            // update associated files state
            removeFilesAndUpdateApi(imageRefs);
          },
          onError: () => {
            toastError("Error removing media", toastErrorIcon, toastCloseIcon);
          },
        }
      );
    },
    [asset, patchAsset, removeFilesAndUpdateApi]
  );

  const handleSetPrimaryMedia = useCallback(
    (imageRef) => {
      const updatedAsset = {
        ...asset,
        primaryImage: imageRef,
      };

      patchAsset({ updatedAsset, asset });
    },
    [asset, patchAsset]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      addFiles(filesUploaded);

      const updatedFiles = [
        ...asset.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedAsset = {
        ...asset,
        files: updatedFiles,
        primaryImage:
          asset?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };

      patchAsset({ updatedAsset, asset });
    },
    [addFiles, asset, patchAsset]
  );

  // ref to hold timer for onFinishEditing function
  const finishEditingRef = useRef(null);

  useEffect(() => {
    return () => {
      // if component unmounts, clear timer
      if (finishEditingRef.current) clearTimeout(finishEditingRef.current);
    };
  }, []);

  const onFinishEditing = React.useCallback(
    async (key, val) => {
      const SavingDelay = () => {
        return new Promise((resolve) => {
          finishEditingRef.current = setTimeout(() => {
            resolve();
          }, 2000);
        });
      };
      const patches = [SavingDelay];
      const posts = [SavingDelay];
      setIsSaving(true);

      let newUpdateIso = new Date();
      newUpdateIso = newUpdateIso.toISOString();
      const finishedAsset = {
        ...editedAsset,
        metadata: { ...editedAsset?.metadata, lastUpdated: newUpdateIso },
        tags:
          editedAsset?.currentTags?.map((tag) => tag?.value) ||
          editedAsset?.tags,
        [key]: val,
      };

      // remove unused properties
      delete finishedAsset.agentRef;
      delete finishedAsset.examinerRef;

      switch (key) {
        case "images": {
          patchAssetAsync({ updatedAsset: finishedAsset, asset });
          setIsSaving(false);
          break;
        }
        case "spaces": {
          patchAssetAsync({
            updatedAsset: { ...finishedAsset, spaces: val },
            asset,
          });
          setIsSaving(false);
          break;
        }
        default: {
          /**
           * Toast Initial Loading State
           */
          const savingToast = toast("Saving...", {
            isLoading: true,
            position: "top-center",
          });

          patches.push(() =>
            patchAsset({ updatedAsset: finishedAsset, asset })
          );

          const arrayOfPatches = patches?.map((patch) => patch());
          const arrayOfPosts = posts?.map((post) => post());

          Promise.all(arrayOfPatches)
            .then(() => {
              toast.update(savingToast, {
                isLoading: false,
                render: "Saved",
                closeButton: toastCloseIcon,
                className: "bg-brandGreen text-white",
                hideProgressBar: true,
                position: "top-center",
                icon: toastIcon,
                autoClose: 3000,
              });

              // update tags in s&c
              queryClient.invalidateQueries(tagKeys.tags);
              setIsSaving(false);
            })
            .catch(() => {
              toast.update(savingToast, {
                isLoading: false,
                render: "Error Saving",
                style: {
                  backgroundColor: "#BC2727",
                  color: "white",
                },
                closeButton: toastCloseIcon,
                position: "top-center",
                hideProgressBar: true,
                icon: toastErrorIcon,
                autoClose: 3000,
              });
              setIsSaving(false);
            });

          if (arrayOfPosts?.length) {
            arrayOfPosts.push(() => {
              reloadMeasurements();
              setNewAttributes([]);
            });
          }
          await Promise.all(arrayOfPosts);
        }
      }
    },
    [
      asset,
      assetsDict,
      editedAsset,
      queryClient,
      reloadMeasurements,
      patchAssetAsync,
    ]
  );

  /**
   * New Handlers
   */

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedAsset = {
            ...asset,
            files: [
              ...asset?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return patchAssetAsync({ updatedAsset, asset });
        })
        .then((resource) =>
          toastMessage(
            `Recent file successfully attached to ${resource?.name}`,
            toastIcon,
            toastCloseIcon
          )
        )
        .catch(() => {
          toastError(
            `Error attaching recent file`,
            toastErrorIcon,
            toastCloseIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [asset, cloneFile, patchAssetAsync, removeFilesAndUpdateApi]
  );

  const handlePrimaryImageChange = async (fileResource) => {
    const updatedAsset = {
      ...asset,
      files: [
        ...asset?.files,
        { ref: fileResource.reference, category: fileResource.category },
      ],
      primaryImage: fileResource.reference,
    };

    patchAsset(
      { updatedAsset, asset },
      {
        onSuccess: () => {
          addFile(fileResource);
          toastMessage(`Primary Image Updated`, toastIcon, toastCloseIcon);
        },
        onError: () => {
          toastError(
            `Error updating primary image`,
            toastErrorIcon,
            toastCloseIcon
          );
        },
      }
    );
  };

  const handleChangeName = useCallback(
    (name) => {
      dispatch({
        type: "name",
        name,
      });
      if (!editing) onFinishEditing("name", name);
    },
    [dispatch, editing, onFinishEditing]
  );

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: "details",
          title: "Details",
          content: (
            <AssetDetailView
              editedAsset={editedAsset}
              dispatch={dispatch}
              onFinishEditing={onFinishEditing}
              isSaving={isSaving}
              disableEditing={!hasWritePermission(ASSET, currentUser)}
              editing={editing}
              measurements={measurements}
              setNewAttributes={setNewAttributes}
              newAttributes={newAttributes}
              isLoading={isLoading}
            />
          ),
        },
        {
          id: "media",
          title: "Media",
          content: (
            <ImagesAndVideosWidget
              resource={asset}
              disableEditing={!hasWritePermission(ASSET, currentUser)}
              handleAddMedia={handleAddMedia}
              handleSetPrimaryMedia={handleSetPrimaryMedia}
              handleRemoveMedia={handleRemoveMedia}
            />
          ),
        },
      ],
    };

    permissionedTabs.detailsIndex = 0;
    permissionedTabs.mediaIndex = 1;

    permissionedTabs.tabs.push({
      id: "files",
      title: "Files",
      content: (
        <Widget draggable={false} title={null} overflow>
          <FilesTable
            onAddFilesCallback={onAddFilesCallback}
            removeFilesAndUpdateApi={removeFilesAndUpdateApi}
            onRemoveFilesCallback={updateAssetFiles}
            files={associatedFiles}
            resourceName="Asset"
            setIsEditModalOpen={setIsEditModalOpen}
            association={`Asset/${assetId}`}
            hasDeletePermission={hasDeletePermission(ASSET, currentUser)}
            hasWritePermission={hasWritePermission(ASSET, currentUser)}
            handleFileClone={handleFileClone}
            hasEditPermission
            handleUpdateFile={handleUpdateFile}
          />
        </Widget>
      ),
    });
    permissionedTabs.filesIndex = 2;

    if (currentUser?.hasPermission?.("expense", "can_read")) {
      permissionedTabs.tabs.push({
        id: "expenses",
        title: "Expenses",
        content: (
          <AssetExpences
            isTabView
            setButtonActions={setButtonActions}
            asset={asset}
            associationLock={associationLock}
            assetLock={assetLock}
          />
        ),
      });
      permissionedTabs.expenseIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("asset", "can_read_financials")) {
      permissionedTabs.tabs.push({
        id: "finances",
        title: "Finances",
        content: (
          <AssetFinances
            editedAsset={editedAsset}
            dispatch={dispatch}
            editing={editing}
            setEditing={setEditing}
          />
        ),
      });

      permissionedTabs.financesIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      permissionedTabs.tabs.push({
        id: "calendar",
        title: "Calendar",
        content: <CalendarView currentUser={currentUser} isTabView />,
      });

      permissionedTabs.calendarIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      permissionedTabs.tabs.push({
        id: "tasks",
        title: "Tasks",
        content: <TaskList />,
      });

      permissionedTabs.taskListIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("ticket", "can_read")) {
      permissionedTabs.tabs.push({
        id: "requests",
        title: "Service Requests",
        content: (
          <ServiceRequestsOverview
            asset={`Asset/${assetId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });

      permissionedTabs.ticketIndex = permissionedTabs.tabs.length - 1;
    }

    if (currentUser?.hasPermission?.("task", "can_read")) {
      permissionedTabs.tabs.push({
        id: "maintenance",
        title: "Maintenance",
        content: <AssetMaintenance hideSideHeader />,
        isHidden: true,
      });
    }

    return permissionedTabs;
  }, [
    editedAsset,
    dispatch,
    onFinishEditing,
    isSaving,
    currentUser,
    editing,
    measurements,
    newAttributes,
    isLoading,
    handleAddMedia,
    handleSetPrimaryMedia,
    handleRemoveMedia,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
    updateAssetFiles,
    associatedFiles,
    setIsEditModalOpen,
    assetId,
    handleFileClone,
    handleUpdateFile,
    userEvents,
    updateUserEvent,
    isTest,
    reloadEvents,
    asset,
    assetLock,
    associationLock,
  ]);

  const { activeTabIndex, setTabIndex } = useWidgetTabNavigation({
    tabs: tabs.tabs,
  });

  // Hook to calculate buttons for SR tab
  const { getRequestButtonProps } = useServiceRequestTopButtons();

  /**
   * Clean Up Actions CTA Menu
   */
  const ActionButton = useMemo(() => {
    // By default, hide the edit pencil
    setIsEditableTab(false);

    let buttonProps = {};
    switch (activeTabIndex) {
      case tabs.expenseIndex: {
        if (currentUser?.hasPermission?.("expense", "can_write")) {
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalType: EXPENSE_CREATE_MODAL,
                modalData: {
                  viewMode: "create",
                  associationLock,
                  assetLock,
                },
              });
            },
            addButton: true,
            resourceName: "Expense",
          };
        }
        break;
      }

      case tabs.filesIndex: {
        buttonProps = {
          onClick: () => document.querySelector(`.upload_area_click`)?.click(),
          addButton: true,
          resourceName: "Files",
        };
        break;
      }
      case tabs.mediaIndex: {
        buttonProps = {
          addButton: true,
          resourceName: "Media",
          onClick: () => document.getElementById("upload-form-input").click(),
        };
        break;
      }

      case tabs.calendarIndex: {
        const options = [];
        if (currentUser?.hasPermission?.("task", "can_create")) {
          // When viewing calendar from the assets details page
          // We do not want to show the Add Event option
          options.push({
            title: "Add Task",
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock, assetLock },
                modalType: CREATE_TASK_MODAL,
              }),
          });
        }
        buttonProps = {
          title: "Actions",
          className: "dropdown-btn",
          large: true,
          dropdownItems: filterButtonActions(options),
        };
        break;
      }

      case tabs.taskListIndex: {
        if (currentUser?.hasPermission?.("task", "can_create")) {
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock, assetLock, to: "task_list" },
                modalType: CREATE_TASK_MODAL,
              });
            },
            addButton: true,
            resourceName: "Task",
          };
        }
        break;
      }

      case tabs.ticketIndex: {
        if (currentUser?.hasPermission?.("ticket", "can_create")) {
          buttonProps = getRequestButtonProps(buttonActions);
        }
        break;
      }

      case tabs.detailsIndex: {
        // user has read only permission, show no CTA Button
        if (
          hasReadPermission(ASSET, currentUser) &&
          !hasWritePermission(ASSET, currentUser) &&
          !hasDeletePermission(ASSET, currentUser)
        ) {
          return null;
        }
        if (hasWritePermission(ASSET, currentUser)) {
          setIsEditableTab(true);
        }
        const options = [];
        /* ** commented for future use ** */

        // if (!editing && hasWritePermission(ASSET, currentUser)) {
        //   options.push({
        //     tabAction: true,
        //     title: "Clone Asset",
        //     onClick: () => cloneAsset(),
        //   });
        // }
        if (!editing && hasDeletePermission(ASSET, currentUser)) {
          options.push({
            tabAction: true,
            title: "Delete Asset",
            onClick: () => setShowDeleteModal(true),
          });
        }

        if (options.length === 0) return null;
        buttonProps = {
          title: "Actions",
          className: "dropdown-btn",
          large: true,
          dropdownItems: options,
        };
        break;
      }

      case tabs.financesIndex: {
        if (currentUser?.hasPermission?.("asset", "can_update_financials")) {
          setIsEditableTab(true);
        }

        return null;
      }

      default:
        buttonProps = {
          dropdownItems: [...buttonActions.filter((opt) => !opt.tabAction)],
          title: "Actions",
          className: "dropdown-btn",
          large: true,
        };
    }
    return <PrimaryButton {...buttonProps} />;
  }, [
    activeTabIndex,
    assetLock,
    associationLock,
    buttonActions,
    currentUser,
    editing,
    filterButtonActions,
    getRequestButtonProps,
    modalDispatch,
    tabs.calendarIndex,
    tabs.detailsIndex,
    tabs.expenseIndex,
    tabs.filesIndex,
    tabs.financesIndex,
    tabs.mediaIndex,
    tabs.taskListIndex,
    tabs.ticketIndex,
  ]);

  // set tab index and reset search field
  const handleTabClick = useCallback(
    (index, resourceTabs, resource) => {
      setTabIndex(index);
      clearSearchField(index, resourceTabs, resource);
    },
    [setTabIndex]
  );

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing
              editing={editing}
              image={asset?.primaryImage}
              loading={!asset?.name}
              onChange={handlePrimaryImageChange}
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={asset?.name}
              editing={editing}
              loading={!asset?.name}
              disabled={!asset?.name}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeName}
              onChangeCallback={handleChangeName}
              hidePencil
              isHeaderTitle
            />
          </div>
        }
        buttons={ActionButton}
        hideDropdownContainer
      />
      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={isEditableTab && handleEditClick}
        onFinishEditing={onFinishEditing}
        tabs={tabs?.tabs}
        loading={isLoading}
        disableEditing={!hasWritePermission(ASSET, currentUser)}
        activeIndex={activeTabIndex}
        onTabClick={(index) => {
          handleTabClick(index, tabs, "Asset");
        }}
        resetResourceState={resetAssetState}
      />
      <AssetDeleteModal
        asset={asset}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

AssetDetails.propTypes = {
  userEvents: PropTypes.shape({}),
  updateUserEvent: PropTypes.shape({}),
  isTest: PropTypes.bool,
  reloadEvents: PropTypes.func,
};

AssetDetails.defaultProps = {
  userEvents: undefined,
  updateUserEvent: undefined,
  isTest: false,
  reloadEvents: undefined,
};

export default AssetDetails;
