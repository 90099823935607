import React from "react";
import SopStepWithStatusWrapper from "./SopStepWithStatusWrapper";
import StepWithStatusWrapper from "./StepWithStatusWrapper";
import useTaskStepTextData from "./useTaskStepTextData";

const TaskStepText = ({
  sop,
  index,
  key,
  currentTask,
  setCurrentTask,
  disableMarkComplete,
  defaultExpandStep,
}) => {
  const { handleStepCheck, completeStep, handleSopStepCheck } =
    useTaskStepTextData(sop, currentTask, setCurrentTask);

  return sop?.resource ? (
    // Render Sops
    <div key={key} className="flex pb-2">
      <SopStepWithStatusWrapper
        sop={sop}
        index={index}
        completeStep={completeStep}
        handleStepCheck={handleStepCheck}
        handleSopStepCheck={handleSopStepCheck}
        disabled={disableMarkComplete || currentTask?.status === "done"}
        defaultExpandStep={defaultExpandStep}
      />
    </div>
  ) : (
    // Render Steps
    <div key={key} className="flex">
      <StepWithStatusWrapper
        sop={sop}
        index={index}
        completeStep={completeStep}
        handleStepCheck={handleStepCheck}
        disabled={disableMarkComplete || currentTask?.status === "done"}
      />
    </div>
  );
};

export default TaskStepText;
