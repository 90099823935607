import calendarKeys from "../../hooks/calendar/calendarKeys";
import getReqParams from "./getReqParms";

const editCalendarStore = ({ queryClient, variables, calendarData, item }) => {
  queryClient.setQueryData(
    calendarKeys.calendarList(getReqParams(calendarData)),
    (oldData) => {
      if (variables.operation === "$non-recurring") {
        if (oldData === undefined) {
          return [
            {
              resource: item,
            },
          ];
        }
        const updatedEntriesArray = oldData.map((event) => {
          return event.resource.id === item.id
            ? { ...event, resource: item }
            : event;
        });

        return updatedEntriesArray;
      }

      if (variables.operation === "$single") {
        const itemId = variables.args.originalItem.id;
        const itemDate = variables.args.originalItem.startDate;

        if (oldData === undefined) {
          return [
            {
              resource: item,
            },
          ];
        }

        const newEntryArray = oldData.map((event) => {
          if (
            event.resource.startDate === itemDate &&
            itemId === event.resource.id
          ) {
            return { ...event, resource: item };
          }

          return event;
        });

        return newEntryArray;
      }

      if (variables.operation === "$future" || variables.operation === "$all") {
        return oldData || [];
      }

      return oldData;
    }
  );
};
export default editCalendarStore;
