import { isEqual } from "lodash";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { PROPERTY_EMPTY } from "../../../constants";
import { propertyInfoSchema } from "../../../helpers/FormValidations";
import {
  cleanUpTitle,
  formatServerErrorMessage,
} from "../../../helpers/Formatters";
import {
  usePropertiesOverview,
  usePropertiesPost,
} from "../../../hooks/properties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useHistory } from "../../../hooks/useHistory";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import ErrorBanner from "../../../stories/Components/ErrorBanner/ErrorBanner";
import ModalWrapper from "../../../stories/Components/EsModal/ModalWrapper";
import PropertyDetailsForm from "../../../stories/Components/PropertyFormSteps/PropertyDetailsForm";

const PropertyCreateModal = ({ modalData }) => {
  const [property, dispatch] = usePropertyFormReducer();
  const { data: config } = useManagementConfiguration();
  const { data: systemConfiguration } = useSystemConfiguration();
  const { data: currentUser } = useCurrentUser();
  const { propertiesDict } = usePropertiesOverview();
  const [errorMessage, setErrorMessage] = useState();
  const [isInputValid, setIsInputValid] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutateAsync: postProperty } = usePropertiesPost();

  const history = useHistory();
  const inputRefs = useRef({ current: {} });

  const initialProperty = useMemo(
    () => ({
      ...PROPERTY_EMPTY,
      members: [
        {
          user: currentUser?.reference,
        },
      ],
    }),
    [currentUser?.reference]
  );

  useEffect(() => {
    const isSame = isEqual(initialProperty, property);
    setShowConfirm(!isSame);
  }, [initialProperty, property]);

  useEffect(() => {
    /* ** By deffault initialize members with currentUser ** */
    dispatch({
      type: "addMembers",
      members: [{ user: currentUser?.reference }],
    });
  }, [currentUser?.reference, dispatch]);

  useEffect(() => {
    if (systemConfiguration?.system) {
      dispatch({
        type: "hoursOfOperation",
        hoursOfOperation: systemConfiguration?.system?.hoursOfOperation,
      });
    }
  }, [dispatch, systemConfiguration?.system]);

  const handleCreate = useCallback(async () => {
    setErrorMessage(undefined);

    const propertyDataWithImages = {
      ...property,
      description: property?.description?.trim(),
    };

    try {
      const data = await postProperty(propertyDataWithImages);

      // eslint-disable-next-line no-param-reassign
      property.address = [];

      /* instead of hopeful update, just push user to single view after success */
      history.push(`/properties/${data?.id}`);
    } catch (err) {
      const serverMsg = formatServerErrorMessage(err);
      setErrorMessage(serverMsg);
    }
  }, [property, postProperty, history]);

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    const { title, propertyType, timezone } = property;
    checkValidation({ title, propertyType, timezone }, propertyInfoSchema);
  }, [property, checkValidation, setIsInputValid]);

  useEffect(() => {
    inputRefs.current = {};
  }, []);
  const handleForwardRef = (key, val) => {
    if (inputRefs?.current) inputRefs.current[key] = val;
  };

  // Make Sure new Projects have a unique name
  const [titleExists, setTitleExists] = useState(false);

  useEffect(() => {
    if (!property?.title?.length) {
      setTitleExists(false);
      return;
    }

    const doesTitleExists =
      propertiesDict != null &&
      Object.values(propertiesDict)?.some(
        (existingProperty) =>
          cleanUpTitle(existingProperty.title) === cleanUpTitle(property.title)
      );
    setTitleExists(doesTitleExists);
  }, [property.title, propertiesDict]);

  return (
    <ModalWrapper
      modalData={modalData}
      primaryButtonOnClick={handleCreate}
      disabled={!isInputValid || !currentUser?.reference || titleExists}
      resourceName="Property"
      title="Create Property"
      showConfirm={showConfirm}
      width="48rem"
      className="px-4"
    >
      <div className="mb-4">
        <PropertyDetailsForm
          property={property}
          dispatch={dispatch}
          config={config?.management}
          handleContinue={handleCreate}
          isInputValid={isInputValid}
          handleForwardRef={handleForwardRef}
          inputRefs={inputRefs}
        />
        {!!errorMessage && <ErrorBanner text={errorMessage} />}
      </div>
    </ModalWrapper>
  );
};

PropertyCreateModal.propTypes = {
  systemConfiguration: PropTypes.shape({
    system: PropTypes.shape({ hoursOfOperation: PropTypes.shape({}) }),
  }),
  config: PropTypes.shape({ management: PropTypes.shape({}) }),
  property: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    propertyType: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.shape({
      address1: PropTypes.shape({ label: PropTypes.string }),
    }),
  }),
  modalData: PropTypes.shape({}),
};
PropertyCreateModal.defaultProps = {
  systemConfiguration: undefined,
  config: {},
  property: {},
  modalData: {},
};

export default PropertyCreateModal;
