import { useCallback } from "react";
import { useProjectsDelete } from "../../../hooks/projects";
import { toastError, toastMessage } from "../Toast/Toast";

const useProjectTableData = () => {
  const { mutateAsync: deleteProject } = useProjectsDelete();

  /**
   * Delete single project from backend
   */
  const handleDeleteProject = useCallback(
    async (id) => {
      try {
        await deleteProject(id);
        toastMessage("Project was deleted successfully");
      } catch (err) {
        toastError("Project could not be removed. Please try again");
      }
    },
    [deleteProject]
  );

  return { handleDeleteProject };
};

export default useProjectTableData;
