import moment from "moment";

// will make sure item will always be treated as a single cell. Quotes commas and newlines
// in item will be escaped
const escapeCharsInItem = (item) => `"${item}"`;

const recognizedCsvColumnTypes = {
  Name: true,
  "Employee ID": true,
  Project: true,
  Title: true,
  Category: true,
  Type: true,
  "State Of Employment": true,
  "Payroll Period": true,
  Approver: true,
  Dates: true,
  "Rate Per Hours": true,
  "Rate Over 40 Hours": true,
  "Premium Rate": true,
  "Regular Hours": true,
  "OT Hours": true,
  "Time Off": true,
  "Total Hours": true,
  "Total Hours Billable": true,
  "Total Hours Non-Billable": true,
};

/**
 * Returns true if the item is a recognized column type
 * this is to filter out dates. If dates are in the selected columns array we need to dynamically create the
 * the column headers based on the start and end periods of the timesheet
 */

const convertPayrollToCsv = async ({
  timesheets,
  csvColumns,
  projectDict,
  userDict,
  csiCodes,
  payrollMap,
  requestsByUserMap,
  ptoLocationsLibrary,
  payPeriod,
}) => {
  /**
   * Filter out non-simulated / real timesheets. The simulated timesheets will have mostly NA values
   * and will need to show under the actual submitted timesheets
   */
  const nonSimulatedTs = timesheets.filter((ts) => !ts.simulated) || [];
  const simulatedTsNames =
    timesheets.filter((ts) => ts.simulated).map((ts) => ts.employee.fullName) ||
    [];

  const timesheetMap = {};

  nonSimulatedTs.forEach((timesheet) => {
    timesheetMap[timesheet.reference] = timesheet;
  });

  /**
   * Needs to check to see if dates is part of the selected columns
   * if so we need to dynamically create the column headers
   */
  const formattedCsvColumns = [];

  /**
   * If Ot or To is selected we want to show the total hours per timesheet
   * on the first row of the timesheet. A single timesheet will span multiple rows
   * if the user has multiple project / rate/ codes per timesheet
   */
  const userHasTo = {};
  const userHasOt = {};

  /**
   * If dates is present in the selected columns we need to dynamically create the
   * the column headers based on the start and end periods of the timesheet
   * if dates is present a Non-Billable, Note, and Date columns for every day in the
   * payPeriod
   */

  csvColumns.forEach((column) => {
    if (column !== "Dates") {
      formattedCsvColumns.push(column);
    } else {
      const { periodStart, periodEnd } = payPeriod;

      let currentDate = moment.utc(periodStart);
      let count = 1;

      while (currentDate <= moment.utc(periodEnd)) {
        formattedCsvColumns.push(currentDate.format("MM-DD-YYYY"));
        formattedCsvColumns.push(`Non-Billable ${count}`);
        formattedCsvColumns.push(`Note ${count}`);

        count += 1;
        currentDate = currentDate.add(1, "days");
      }
    }
  });

  /** extract all entries so they can be sorted into a map
   * adding some required data to each entry for ease of sorting
   */
  const entriesArray = nonSimulatedTs
    .map((timesheet) => {
      const {
        userRef,
        periodStart,
        periodEnd,
        reference: timesheetReference,
      } = timesheet;
      const { fullName } = timesheet.employee;

      return timesheet.entries.map((entry) => ({
        ...entry,
        userRef,
        fullName,
        periodStart,
        periodEnd,
        timesheetReference,
      }));
    })
    .flat();

  /**
   * sort entries by userRef, fullName, project, rate, and code
   * this will allow us to create the csv rows
   * */

  const unsortedRowsMap = {};

  entriesArray.forEach((entry) => {
    const codeString = `${entry.financialCode.division} ${entry.financialCode.code} ${entry.financialCode.subcode}`;
    const rowKey = `${entry.fullName} ${entry.project} ${entry.rate} ${codeString}`;

    if (unsortedRowsMap[rowKey]) unsortedRowsMap[rowKey].push(entry);
    else unsortedRowsMap[rowKey] = [entry];
  });

  /**
   * sort rows alphabetically by fullName, project, rate, and code
   * fullname is only necessary to make the csv in proper order
   * */
  const sortedRowsMap = {};
  const sortedKeys = Object.keys(unsortedRowsMap).sort();

  sortedKeys.forEach((key) => {
    sortedRowsMap[key] = unsortedRowsMap[key];
  });

  /**
   * From the map we need to create a matrix
   * Where x = row, y = column
   *
   * The first row will be the dynamically created header row.
   * The rest of the rows will be the actual timesheet entries
   *
   * Entries in a row will always have a common project, rate, and code
   * If a user has multiple rows it's because they had a timesheet with multiple project / rate/ category entries
   */

  const csvPrepArr = [formattedCsvColumns];
  if (nonSimulatedTs.length > 0) {
    Object.values(sortedRowsMap).forEach((entryValues) => {
      const newCsvRow = [];

      formattedCsvColumns.forEach((item, index) => {
        if (item === "Name") {
          newCsvRow.push(entryValues[0].fullName);
        }

        if (item === "Employee ID") {
          const employeeId = userDict[entryValues[0].userRef].employeeInfo.id;
          newCsvRow.push(employeeId || "NA");
        }

        if (item === "Project") {
          const projectName = projectDict[entryValues[0].project].name;
          newCsvRow.push(escapeCharsInItem(projectName));
        }

        if (item === "Title") {
          const rateID = entryValues[0].rate;
          const projectRates =
            projectDict[entryValues[0].project].rateSheet.rates;

          const projectRateDict = {};

          projectRates.forEach((rate) => {
            projectRateDict[rate.id] = rate;
          });

          const rateFound = projectRateDict[rateID];
          const formattedAndEscaped = escapeCharsInItem(
            rateFound?.category || "NA"
          );

          newCsvRow.push(formattedAndEscaped);
        }

        if (item === "Category") {
          const categoryCode = `${entryValues[0].financialCode.division} ${entryValues[0].financialCode.code} ${entryValues[0].financialCode.subcode}`;
          const categoryString = `${entryValues[0].financialCode.division}-${entryValues[0].financialCode.code}-${entryValues[0].financialCode.subcode}`;

          const fullCode = escapeCharsInItem(
            `${categoryString} ${csiCodes[categoryCode]}`
          );

          newCsvRow.push(fullCode);
        }

        if (item === "Type") {
          const { isExempt } = userDict[entryValues[0].userRef].employeeInfo;
          newCsvRow.push(isExempt ? "is-exempt" : "non-exempt");
        }

        if (item === "State Of Employment") {
          const locationId =
            userDict[entryValues[0]?.userRef]?.employeeInfo?.pto?.locationId;

          if (!locationId) {
            newCsvRow.push("NA");
          } else {
            const errorNotice = "Error: Unknown Location ID, contact support";
            const locationName = ptoLocationsLibrary?.dict[locationId]?.label;

            newCsvRow.push(escapeCharsInItem(locationName || errorNotice));
          }
        }

        if (item === "Payroll Period") {
          const periodStart = moment
            .utc(entryValues[0].periodStart)
            .format("MM/DD/YYYY");

          const periodEnd = moment
            .utc(entryValues[0].periodEnd)
            .format("MM/DD/YYYY");

          newCsvRow.push(escapeCharsInItem(`${periodStart} - ${periodEnd}`));
        }

        if (item === "Approver") {
          const approverName =
            payrollMap[entryValues[0].timesheetReference]?.approved?.user[0]
              .fullName;

          newCsvRow.push(escapeCharsInItem(approverName || "NA"));
        }

        if (item === "Rate Per Hours") {
          const ratesForProjectMap = {};

          projectDict[entryValues[0].project].rateSheet.rates.forEach(
            (rate) => {
              ratesForProjectMap[rate.id] = rate;
            }
          );

          newCsvRow.push(
            escapeCharsInItem(
              `$${ratesForProjectMap[entryValues[0].rate].ratePerHr}`
            )
          );
        }
        if (item === "Rate Over 40 Hours") {
          const projectId = entryValues[0].project;
          const project = projectDict[projectId];

          const rateFound = project.rateSheet.rates.find(
            (rate) => rate.id === entryValues[0].rate
          );

          if (!rateFound) {
            newCsvRow.push("NA");
            return;
          }

          newCsvRow.push(escapeCharsInItem(`$${rateFound.rateOver40Hrs}`));
        }

        if (item === "Premium Rate") {
          const projectId = entryValues[0].project;
          const project = projectDict[projectId];

          const rateFound = project.rateSheet.rates.find(
            (rate) => rate.id === entryValues[0].rate
          );

          newCsvRow.push(escapeCharsInItem(`$${rateFound.premiumRate}`));
        }

        if (item === "Regular Hours") {
          const totalHours =
            entryValues.reduce(
              (accumulator, currentValue) =>
                accumulator + parseFloat(currentValue.value),
              0
            ) / 60;

          newCsvRow.push(escapeCharsInItem(totalHours));
        }

        if (item === "OT Hours") {
          const timesheetEntryBelongsTo =
            timesheetMap[entryValues[0].timesheetReference];

          if (userHasOt[entryValues[0].userRef]) {
            newCsvRow.push(0);
            return;
          }

          userHasOt[entryValues[0].userRef] = true;

          const otHours =
            timesheetEntryBelongsTo.entries.reduce(
              (accumulator, currentValue) =>
                accumulator + parseFloat(currentValue.value),
              0
            ) / 60;

          const hoursToDisplay = otHours - 40 > 0 ? otHours - 40 : 0;
          newCsvRow.push(escapeCharsInItem(hoursToDisplay));
        }

        if (item === "Time Off") {
          if (
            !requestsByUserMap[entryValues[0].userRef] ||
            userHasTo[entryValues[0].userRef]
          ) {
            newCsvRow.push(0);
            return;
          }

          userHasTo[entryValues[0].userRef] = true;

          const { userRef } = entryValues[0];
          const userRequests = requestsByUserMap[userRef][0].requests;
          const timeoffForUser = userRequests.filter(
            (request) => request.status === "approved"
          );

          const totalDates = timeoffForUser
            .map((timeoff) => timeoff.dates)
            .flat();

          const datesWithinPayrollPeriod = totalDates.filter((date) => {
            const dateMoment = moment.utc(date.date);
            return (
              dateMoment.isSameOrAfter(
                moment.utc(entryValues[0].periodStart)
              ) &&
              dateMoment.isSameOrBefore(moment.utc(entryValues[0].periodEnd))
            );
          });

          const totalTimeOff = datesWithinPayrollPeriod.reduce(
            (accumulator, currentValue) =>
              accumulator + parseFloat(currentValue.numHours),
            0
          );

          newCsvRow.push(escapeCharsInItem(totalTimeOff));
        }

        if (item === "Total Hours") {
          const totalHours =
            entryValues.reduce(
              (accumulator, currentValue) =>
                accumulator + parseFloat(currentValue.value),
              0
            ) / 60;

          newCsvRow.push(escapeCharsInItem(totalHours));
        }

        if (item === "Total Hours Non-Billable") {
          const nonBillableHours =
            entryValues
              .filter((entry) => entry.isBillable === false)
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.value),
                0
              ) / 60;

          newCsvRow.push(escapeCharsInItem(nonBillableHours));
        }

        if (item === "Total Hours Billable") {
          const billableHours =
            entryValues
              .filter((entry) => entry.isBillable === true)
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + parseFloat(currentValue.value),
                0
              ) / 60;

          newCsvRow.push(escapeCharsInItem(billableHours));
        }

        if (!recognizedCsvColumnTypes[item]) {
          if (moment(item, "MM-DD-YYYY", true).isValid()) {
            let found = false;

            entryValues.forEach((entry) => {
              const date1 = moment.utc(item, "MM-DD-YYYY").format("MM-DD-YYYY");
              const date2 = moment.utc(entry.date).format("MM-DD-YYYY");

              if (date1 === date2) {
                found = true;
                newCsvRow.push(escapeCharsInItem(entry.value / 60));
              }
            });

            if (!found) {
              newCsvRow.push(0);
            }
          }

          const itemSplit = item.split(" ");

          if (itemSplit[0] === "Non-Billable") {
            let nonBillableFound = false;

            const date1 = moment
              .utc(formattedCsvColumns[index - 1])
              .format("MM-DD-YYYY");

            entryValues.forEach((entry) => {
              const date2 = moment.utc(entry.date).format("MM-DD-YYYY");

              if (date1 === date2) {
                newCsvRow.push(!entry.isBillable);
                nonBillableFound = true;
              }
            });

            if (!nonBillableFound) {
              newCsvRow.push("NA");
            }
          }

          if (itemSplit[0] === "Note") {
            let noteFound = false;

            const date1 = moment
              .utc(formattedCsvColumns[index - 2])
              .format("MM-DD-YYYY");

            entryValues.forEach((entry) => {
              const date2 = moment.utc(entry.date).format("MM-DD-YYYY");

              if (date1 === date2 && entry.note) {
                newCsvRow.push(escapeCharsInItem(entry.note));
                noteFound = true;
              }
            });

            if (!noteFound) {
              newCsvRow.push("NA");
            }
          }
        }
      });

      /**
       * At this point the row has been created and should be added to the prep array.
       * [
       * * [c, c, c, c],
       * * [r, r, r, r],
       * * [r, r, r, r],
       * [
       * Total amount of rows should === formattedCsvColumns.length + 1
       */
      csvPrepArr.push(newCsvRow);
    });
  }

  /**
   * creates a divider between the submitted and simulated timesheets
   */

  if (nonSimulatedTs.length > 0) {
    const blankRow = new Array(csvPrepArr[0].length).fill("--");
    csvPrepArr.push(blankRow);
  }

  /**
   * Simulated Timesheets represent an non submitted / unsaved timesheet
   * This row wont contain any data
   */

  if (simulatedTsNames.length > 0) {
    simulatedTsNames.forEach((name) => {
      const newCsvRow = new Array(csvPrepArr[0].length).fill("NA");
      newCsvRow[0] = name;
      csvPrepArr.push(newCsvRow);
    });
  }

  /**
   * now we can take the 2d array and convert it to a csv string by joining each array 
   * item with a coma and separating each array with a new line
   * [
   * * [c, c, c, c],
   * * [r1, r1, r1, r1],
   * * [r2, r2, r2, r2], 
   *
   ]
   * becomes "c,c,c,c\nr1,r1,r1,r1\nr2,r2,r2,r2\n"
   */
  const arraysAsCsv = csvPrepArr
    .map((row) => {
      return row.join(",");
    })
    .join("\n");

  const blob = new Blob([arraysAsCsv], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  const fileName = `${payPeriod.periodStart}-${payPeriod.periodEnd}-payroll.csv`;

  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default convertPayrollToCsv;
