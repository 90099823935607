import { useMutation, useQueryClient } from "react-query";
import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import propertyKeys from "./propertiesKeys";
import sortByProperty from "../../helpers/Utilities/sortByProperty";

/**
 * Mutation hook to post a single property
 */
const usePropertiesPost = () => {
  const queryClient = useQueryClient();

  /**
   * Function to post a property in backend
   * @param {Object} property - single property to create
   */
  const postProperty = async (property) => {
    const { data } = await PropertyAPI.post(property);

    return data;
  };

  return useMutation({
    mutationFn: postProperty,
    onSuccess: (data) => {
      const queryKey = propertyKeys.all;

      // Add new property to query cache after success
      queryClient.setQueryData(queryKey, (previous) => {
        // TODO: (Tech debt) Not sure how we gonna handle this when using infinity scroll
        // Add new property to the cache list and sort them by `ES Rule`
        const updatedList = [data, ...(previous || [])];

        // Sort properties by ES rule
        return sortByProperty(updatedList, "title");
      });
    },
    onSettled: () => {
      // TODO: (Work-around to address the issue where the backend does not return newly created properties for users without admin roles)
      // https://estatespace.atlassian.net/jira/software/projects/ES/boards/26?selectedIssue=ES-10166

      setTimeout(() => {
        // Invalidate properties
        queryClient.invalidateQueries(propertyKeys.all);
      }, 800);
    },
    onError: (error) => {
      console.error("usePropertiesPost", error);
    },
  });
};

export default usePropertiesPost;
