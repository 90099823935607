import { useMutation, useQueryClient } from "react-query";
import { AssetAPI } from "@griffingroupglobal/eslib-api";
import assetKeys from "./assetsKeys";

/**
 * Mutation hook to post a single asset
 */
const useAssetsPost = () => {
  const queryClient = useQueryClient();

  /**
   * Function to post a asset in backend
   * @param {Object} asset - single asset to create
   */
  const postAsset = async (asset) => {
    const { data } = await AssetAPI.post(asset);

    return data;
  };

  return useMutation({
    mutationFn: postAsset,
    onSuccess: (data) => {
      const queryKey = assetKeys.assets;

      // Add new asset to query cache after success
      queryClient.setQueryData(queryKey, (previuos) => {
        // TODO: (Tech debt) Not sure how we gonna handle this when using infinity scroll
        // Add new asset to the cache list and sort them by `title`
        const updatedList = [data, ...(previuos || [])].sort((a, b) =>
          a?.title > b?.title ? 1 : -1
        );

        return updatedList;
      });

      // Invalidate assets
      queryClient.invalidateQueries({
        queryKey: assetKeys.assets,
      });
    },
    onSettled: () => {
      // TODO: (Work-around to address the issue where the backend does not return newly created properties for users without admin roles)
      // https://estatespace.atlassian.net/jira/software/projects/ES/boards/26?selectedIssue=ES-10166

      setTimeout(() => {
        // Invalidate properties
        queryClient.invalidateQueries(assetKeys.assets);
      }, 800);
    },
    onError: (error) => {
      console.error("useAssetsPost", error);
    },
  });
};

export default useAssetsPost;
