import { cloneDeep } from "lodash";
import { useCallback, useMemo } from "react";
import { getLocation } from "../../helpers/Asset";
import { useAppState } from "../../state/appState";
import { usePropertiesOverview } from "../properties";
import useManagementConfiguration from "../useManagementConfiguration";
import { useGetTags } from "../useTags";
import useAssets from "./useAssets";

/**
 * Query selector to transform assets to table format
 */
const useAssetsTableFormat = (reference) => {
  // TODO: RQ-Perfm
  const [{ projectDict }] = useAppState();

  const { propertiesDict } = usePropertiesOverview();
  const { data: tagsData } = useGetTags();
  const tagsDict = tagsData?.tagsDict;

  const { data: managementConfiguration } = useManagementConfiguration();

  const subCategoryDict = useMemo(() => {
    return managementConfiguration?.management?.asset?.category?.reduce(
      (list, cat) => {
        const newList = cloneDeep(list);
        cat.subcategories.forEach((subcat) => {
          newList[subcat.id] = {
            category: cat.display,
            subCategory: subcat.display,
          };
        });
        return newList;
      },
      {}
    );
  }, [managementConfiguration?.management?.asset?.category]);

  /**
   * Selector to transform assets to table format
   */
  const formatAssetTableData = useCallback(
    (assets) => {
      return assets.map((asset) => {
        const property = propertiesDict?.[asset?.property]?.title;
        const project = projectDict?.[asset?.project]?.name;

        return {
          id: asset?.id,
          name: asset?.name,
          image: asset?.primaryImage,
          primaryImage: asset?.primaryImage,
          categoryId: asset?.category,
          category: subCategoryDict?.[asset?.subcategory]?.category,
          subcategory: subCategoryDict?.[asset?.subcategory]?.subCategory,
          subcategoryId: asset?.subcategory,
          additionalInfo: asset?.additionalInfo,
          measurement: asset?.additionalInfo,
          type: asset?.additionalInfo?.find((m) => m.detail === "Types")?.value,
          link: asset?.additionalInfo?.find((m) => m.detail === "Buy It Again")
            ?.value,
          valuation:
            asset?.value?.length > 0 ? asset?.value[0]?.amount : undefined,
          isAppraised: !!asset?.value?.length,
          appraisalDate:
            asset?.value?.length > 0
              ? asset?.value[0]?.dateOfExamination
              : undefined,
          brand: asset?.generalInfo?.brand,
          quantity: asset?.generalInfo?.quantity || 1,
          condition: asset?.generalInfo?.condition,
          yearMade: asset?.generalInfo?.yearMade,
          origin: asset?.generalInfo?.origin,
          numberOfOwners: asset?.generalInfo?.numberOfOwners || 0,
          purchaseDate: asset?.generalInfo?.purchaseDate,
          lastUpdated: asset?.metadata?.lastUpdated,
          parLevel: asset?.generalInfo?.parLevel,
          property,
          project,
          association: project ?? property,
          description: asset?.description,
          location: getLocation(asset, propertiesDict, projectDict),
          hasLinks: !!asset?.links?.length,
          hasDocuments: !!asset?.documents?.length,
          insuranceAgent: asset?.insuranceAgent,
          insuranceBroker: asset?.insuranceBroker,
          addAnother: asset?.addAnother,
          tags: asset?.tags,
          currentTags:
            asset?.tags?.map((tagRef) => ({
              label: tagsDict?.[tagRef]?.label,
              value: tagsDict?.[tagRef]?.reference,
            })) || [],
        };
      });
    },
    [tagsDict, projectDict, propertiesDict, subCategoryDict]
  );

  return useAssets({
    reference,
    select: formatAssetTableData,
  });
};

export default useAssetsTableFormat;
