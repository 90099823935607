import { TaskAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { taskKeys } from "../config/reactQuery/queryKeyFactory";
/**
 * @deprecated - Remove this hook in ES-10762
 * Fetch single Task by `id` with or without `instanceStartDate`
 * @param {string} id - Task id
 * @param {string} instanceStartDate - (Optional) Task instance start date
 * @returns {Object} - Single Task
 */
const getTask = async (id, instanceStartDate) => {
  let task;

  if (instanceStartDate) {
    const params = {
      left: instanceStartDate,
      right: instanceStartDate,
      reference: `Task/${id}`,
    };

    const { data } = await TaskAPI.get({ params });
    const foundTask = data.entries[0];
    task = foundTask?.resource;
  } else {
    const { data } = await TaskAPI.getById(id);
    task = data;
  }

  return task;
};

/**
 * @deprecated
 * Query hook to fetch single task by id
 * @param {string} id - task id
 * @param {string} instanceStartDate - task instance start date
 */
const useTaskById = (id, instanceStartDate) => {
  const enabled = !!id;

  return useAuthenticatedQuery({
    queryKey: taskKeys.byId(id, instanceStartDate),
    queryFn: () => getTask(id, instanceStartDate),
    enabled,
    staleTime: 0,
  });
};

export default useTaskById;
export { getTask };
