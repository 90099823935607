import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import { useEffect, useState } from "react";
import usePagePersistance from "../../../../hooks/usePagePersistence";
import { useAppState } from "../../../../state/appState";

const usePayrollData = () => {
  const { pageState } = usePagePersistance();
  const [timeSheets, setTimeSheets] = useState([]);
  const [timeSheetsLoading, setTimeSheetsLoading] = useState(true);
  const [{ userDict }] = useAppState();

  useEffect(() => {
    setTimeSheetsLoading(true);
    (async () => {
      const query = {
        periodStart: pageState?.timesheet?.periodStart,
        periodEnd: pageState?.timesheet?.periodEnd,
        status: "approved,approved-with-changes,locked,submitted,rejected",
      };

      try {
        const { data } = await TimesheetAPI.get({ params: query });

        const timesheetsArr = data?.entries.map((ts) => ts.resource);

        const timeSheetsWithFormattedUsers = timesheetsArr.map((ts) => {
          const employeeWithFullName = {
            ...userDict[ts.userRef],
            fullName: `${userDict[ts.userRef]?.name.firstName} ${
              userDict[ts.userRef]?.name.lastName
            }`,
          };

          return {
            ...ts,
            employee: employeeWithFullName,
          };
        });

        setTimeSheets(timeSheetsWithFormattedUsers);
      } catch (error) {
        console.error(error);
      } finally {
        setTimeSheetsLoading(false);
      }
    })();
  }, [pageState, userDict]);

  return { timeSheetsLoading, timeSheets };
};

export default usePayrollData;
