import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import getReqParams from "../../helpers/Calendar/getReqParms";
import { useAppState } from "../../state/appState";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import { fetchCalendarHandler } from "./calendarHandlers";
import eventKeys from "./calendarKeys";

const useCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: eventKeys.calendarList(getReqParams(calendarData)),
    queryFn: () => fetchCalendarHandler(getReqParams(calendarData)),
    staleTime: 0,
    onError: () => {
      toast.error("Failed to fetch events.");
    },
  });

  const {
    data: calendarList,
    isLoading: calendarLoading,
    isError: isCalendarError,
    error: calendarError,
  } = authenticatedResponse;

  const refetchCalendarList = async () => {
    await queryClient.invalidateQueries(
      eventKeys.calendarList(getReqParams(calendarData))
    );
  };

  return {
    calendarList,
    calendarLoading,
    isCalendarError,
    calendarError,
    refetchCalendarList,
  };
};

export default useCalendar;
