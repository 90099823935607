import { useHistory } from "react-router";
import { useProjectsPost } from "../../../hooks/projects";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { toastError } from "../Toast/Toast";

const useProjectFormData = () => {
  const history = useHistory();
  const { mutate, isLoading } = useProjectsPost();

  /**
   * Create project in the backend
   * @param {Object} project - Project to create
   * @param {Function} closeCreateModal - Function to close modal
   */
  const addProject = (project, closeCreateModal) => {
    mutate(project, {
      onSuccess: (data) => {
        // close modal
        closeCreateModal();
        // Redirect to project single view
        history.push(getSingleResourcePath(data.reference));
      },
      onError: () => {
        toastError("Project could not be created. Please try again");
      },
    });
  };

  return { addProject, isSaving: isLoading };
};

export default useProjectFormData;
