import { TaskAPI } from "@griffingroupglobal/eslib-api";

const fetchTaskHandler = async (id, instanceStartDate) => {
  const query = instanceStartDate
    ? `?instanceStartDate=${instanceStartDate}`
    : "";

  const { data } = await TaskAPI.getByIdWOP(id, query);

  return data;
};

export default fetchTaskHandler;
