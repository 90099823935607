import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ADD_OPEN_MODAL, PROPERTY_CREATE_MODAL } from "../../../constants";
import PropertyTable from "../../../stories/Components/PropertyTable/PropertyTable";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { useModalState } from "../../../state/modalState";
import useQueryNavigation from "../../../hooks/navigation/useQueryNavigation";
import { propertyKeys } from "../../../hooks/properties";
import { getSingleResourcePath } from "../../../helpers/Navigation";

const PropertyList = () => {
  const navigation = useQueryNavigation();
  const { data: currentUser } = useCurrentUser();
  const [, modalDispatch] = useModalState();

  const onPropertyRowClick = (id) => {
    navigation.push({
      location: getSingleResourcePath(`Property/${id}`),
      queryKey: propertyKeys.byId(id),
    });
  };

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Properties"
        buttonTitle="Property"
        customButton={
          currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          ) && (
            <PrimaryButton
              resourceName="Property"
              onClick={() =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: PROPERTY_CREATE_MODAL,
                })
              }
              addButton
            />
          )
        }
      />

      <PropertyTable onRowClick={onPropertyRowClick} />
    </>
  );
};

PropertyList.propTypes = {};

PropertyList.defaultProps = {};

export default PropertyList;
