import { EventAPI, TaskAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { MOMENT_UTC_ES_FORMAT } from "../../../constants";

/**
 * Function that handles editing events in a calendar.
 *
 * @param {Object} eventPatchArgs - Properties to be passed to the remove handler.
 * @param {Object} eventPatchArgs.args.originalItem - The original calendar item to be removed.
 * @param {Object} eventPatchArgs.args.editedItem - The edited calendar item to be processed.
 * @param {string} eventPatchArgs.operation - The operation type, which can be "$non-recurring", "$all", "$single", or "$future".
 */

const editEventHandler = async (props) => {
  const { originalItem, editedItem } = props.args;
  const { id: itemId } = originalItem;
  const { operation } = props;
  const API = originalItem.resource === "Task" ? TaskAPI : EventAPI;

  if (operation === "$non-recurring") {
    const response = await API.patch(itemId, editedItem, originalItem);
    return response;
  }

  if (operation === "$single") {
    const dateToParse = moment(originalItem.startDate);

    const dateFormattedToUTC = dateToParse.utc().format(MOMENT_UTC_ES_FORMAT);

    const reqString = `${itemId}/${operation}?instanceStartDate=${dateFormattedToUTC}`;
    const response = await API.patch(reqString, editedItem, originalItem);

    return response;
  }

  if (operation === "$all") {
    const reqString = `${itemId}/${operation}`;

    const response = await API.patch(reqString, editedItem, originalItem);

    return response;
  }

  if (operation === "$future") {
    const dateToParse = moment.parseZone(editedItem.startDate);

    const dateFormattedToUTC = dateToParse.utc().format(MOMENT_UTC_ES_FORMAT);

    const deleteString = `${itemId}/${operation}?instanceStartDate=${dateFormattedToUTC}`;
    const response = await API.patch(deleteString, editedItem, originalItem);

    return response;
  }

  return null;
};

export default editEventHandler;
