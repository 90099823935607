import { useEffect, useMemo, useState } from "react";
import { CommentAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useQueryClient } from "react-query";
import {
  filePaginatedKeys,
  taskKeys,
  ticketsKeys,
} from "../../../../config/reactQuery/queryKeyFactory";
import describeRruleFromString from "../../../../helpers/Calendar/describeRruleFromString";
import isBegOfDay from "../../../../helpers/Date/isBegOfDay";
import isEndOfDay from "../../../../helpers/Date/isEndOfDay";
import { uploadFileWithData } from "../../../../helpers/File";
import { formatTaskObj } from "../../../../helpers/Formatters";
import { getTagOptions } from "../../../../helpers/Tag";
import {
  useEditCalendar,
  useRemoveFromCalendar,
} from "../../../../hooks/calendar";
import { useEditTaskList } from "../../../../hooks/tasks";
import { useSop } from "../../../../hooks/useSop";
import { useGetTags } from "../../../../hooks/useTags";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import { toastError } from "../../Toast/Toast";
import { useSingleAssociationData } from "../../../../hooks/associations";

const useEventModalData = (modalData) => {
  // Destructure the user dictionary from the app state
  const [{ userDict }] = useAppState();

  const { editInCalendar } = useEditCalendar();
  const { editTaskList } = useEditTaskList();
  const { removeFromCalendar } = useRemoveFromCalendar();

  const { associationData, associationError } = useSingleAssociationData(
    modalData?.item?.association
  );

  const { data: tagsData } = useGetTags();
  const [allInviteesInfo, setAllInviteesInfo] = useState([]);
  const [createdBy, setCreatedBy] = useState();
  const [completedBy, setCompletedBy] = useState();
  const [currentTags, setCurrentTags] = useState([]);
  const [, modalStateDispatch] = useModalState();
  const { data: sopData, isLoading } = useSop();
  const queryClient = useQueryClient();

  const isAllDay =
    (isBegOfDay(modalData?.item?.startDate) &&
      isEndOfDay(modalData?.item?.endDate)) ||
    modalData?.item?.allDay;

  const sopOptions = useMemo(() => {
    return !isLoading
      ? sopData?.reduce(
          (list, item) => {
            return {
              options: [
                ...list.options,
                {
                  label: item.name,
                  value: item.reference,
                  version: item?.version,
                },
              ],
              dict: { ...list.dict, [item.reference]: item },
            };
          },
          { dict: {}, options: [] }
        )
      : { dict: {}, options: [] };
  }, [sopData, isLoading]);

  useEffect(() => {
    if (associationError) {
      toastError("Failed getting association");
    }
  }, [associationError]);

  useEffect(() => {
    setCurrentTags(getTagOptions(modalData?.item, tagsData?.tagsDict));
  }, [modalData, tagsData?.tagsDict]);

  useEffect(() => {
    const invitees = modalData?.item?.invitees || [];
    // Map over the invitees (if they exist) and fetch their information from the user dictionary
    const inviteeData = invitees?.reduce((list, invitee) => {
      // Get information for each invitee from the user dictionary
      const inviteeInfo = userDict?.[invitee];
      if (inviteeInfo) list.push(inviteeInfo);
      return list;
    }, []);
    // Set the state of allInviteeInfo to the information of all invitees
    setAllInviteesInfo(inviteeData);
    setCreatedBy(userDict?.[modalData?.item?.metadata?.createdBy]);
    if (modalData?.item?.closing?.closedBy) {
      setCompletedBy(userDict?.[modalData?.item?.closing?.closedBy]);
    }
  }, [userDict, modalData]);

  /**
   * File Management
   */
  const onAddAttachment = async (photo, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      photo,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };
        const resource = await onAddAttachment(
          original,
          data,
          (loaded, total) => handleProgressCallback(loaded, total, name)
        );

        return { ...data, ...resource };
      })
    );

    return result;
  };

  const handleFilesUploaded = async (form) => {
    const filteredFiles = form?.files.reduce(
      (list, file) => {
        if (!file?.isEditing && file?.type) {
          list.latest.push(file);
        } else {
          list.previous.push(file);
        }
        return list;
      },
      { latest: [], previous: [] }
    );
    const res = await onUpload(filteredFiles.latest, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);
    return [...filteredFiles.previous, ...res];
  };

  /**
   * File Management
   */

  const patchComplete = async (form, comment, toast) => {
    if (form?.recurrence) {
      try {
        // Prepare the new task object with necessary modifications
        const newTask = {
          ...form,
          metadata: { ...form.metadata, lastUpdated: moment().format() }, // Update metadata with current time
          invitees: form.invitees?.map((invitee) => invitee.value ?? invitee), // Extract invitees' values if available, else use the original invitees
          status: "done",
        };

        // Handle file uploads and retrieve references
        const uploads = await handleFilesUploaded(form);

        // Update the task with the new file references
        newTask.files = uploads.map((file) => ({
          ref: file.reference || file.ref,
          category: file.category,
        }));

        toast("Task Complete");
      } catch (error) {
        // Notify the user of an error during the task completion process

        toast("Error Marking Task Complete");
      }

      // Return from the function
      // will not execute the rest of the code
      return;
    }

    const { reference, invitees, originalKey, metadata, ...rest } = form;
    let newTask = {
      reference,
      metadata: { ...metadata, lastUpdated: moment().format() },
      invitees: invitees?.map((invitee) => invitee.value ?? invitee),
      ...rest,
      status: "done",
    };

    newTask = formatTaskObj(newTask);

    const uploads = await handleFilesUploaded(form);

    newTask.files = uploads.map((file) => ({
      ref: file.reference || file.ref,
      category: file.category,
    }));

    const taskUpdateParams = {
      originalItem: form.previousTask,
      editedItem: newTask,
    };

    await editInCalendar({
      args: taskUpdateParams,
      operation: "$non-recurring",
    });

    editTaskList(
      {
        args: taskUpdateParams,
        operation: "$non-recurring",
      },
      false
    );

    if (comment?.content) {
      await CommentAPI.post(comment);
    }

    // Invalidate tasks for tickets.
    // Make sure SR get the latest data update
    queryClient.invalidateQueries(ticketsKeys.tasks());

    // update tasks activity
    queryClient.invalidateQueries(taskKeys.taskHistory);

    toast("Task Complete");
  };

  // Return the information of all invitees

  let associationUrlPath = "";

  if (associationData?.resource === "Property") {
    associationUrlPath = `/properties/${associationData?.id}`;
  }

  if (associationData?.resource === "Project") {
    associationUrlPath = `/projects/${associationData?.id}`;
  }

  if (associationData?.resource === "Asset") {
    associationUrlPath = `/assets/${associationData?.id}`;
  }

  let recurrenceString = "";

  if (modalData?.item?.recurrence) {
    recurrenceString = describeRruleFromString(modalData?.item?.recurrence);
  }

  return {
    patchComplete,
    removeFromCalendar,
    recurrenceString,
    createdBy,
    completedBy,
    allInviteesInfo,
    modalStateDispatch,
    currentTags,
    isAllDay,
    associationData,
    associationUrlPath,
    sopOptions: sopOptions?.options,
  };
};

// Export the custom hook for use in other parts of the application
export default useEventModalData;
