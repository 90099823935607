import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { UserAPI } from "@griffingroupglobal/eslib-api";

import "./styles.css";
import { v4 as uuidv4 } from "uuid";
import { useQueryClient } from "react-query";
import ContactFormContainer from "./ContactFormContainer";
import {
  COMPANY_EMPTY,
  CONTACT_EMPTY,
  INVITE_CONTACT_POPUP,
  TOGGLE_POSITIONED_POPUP,
} from "../../constants";
import useContactReducer from "../../hooks/useContactReducer";
import { useUsers } from "../../hooks/useUsers.new";
import { formatSelectUser, yupEmailRegExp } from "../../helpers/Formatters";
import useSystemConfiguration from "../../hooks/useSystemConfiguration";
import useDirectReports from "../../hooks/useDirectReports";
import useSubscription from "../../hooks/useSubscription";
import useRoles from "../../hooks/useRoles";
import ModalWrapper from "../../stories/Components/EsModal/ModalWrapper";
import useUserPost from "../../hooks/useUserPost";
import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import { useAppState } from "../../state/appState";
import { userKeys } from "../../config/reactQuery/queryKeyFactory";

const ContactCreateModal = ({ modalData }) => {
  const [, appStateDispatch] = useAppState();
  const { data } = useUsers();
  const users = data?.users;
  const [contact, contactDispatch] = useContactReducer(
    modalData?.contactType === "company" ? COMPANY_EMPTY : CONTACT_EMPTY
  );

  /**
   * Invite Contact State
   */
  const [saving, setSaving] = useState(false);
  const { data: systemConfiguration } = useSystemConfiguration();
  const { mutateAsync: postUser } = useUserPost();
  const [allRoles] = useRoles();
  const [roles, setRoles] = useState([]);
  const [availableSeats, setAvailableSeats] = useState();
  const [states, setStates] = useState([]);
  const [ptoCategory, setPtoCategory] = useState();
  const [, directReportOptions] = useDirectReports();
  const [originalSubscription] = useSubscription();
  const [memberEmails, setMemberEmails] = useState();

  const [showConfirm, setShowConfirm] = useState(false);
  const [inviteStatus, setInviteStatus] = useState("nonmember"); // "nonmember", "pending", "success"
  const [inviteData, setInviteData] = useState();
  const modalRef = useRef();
  const queryClient = useQueryClient();

  useEffect(() => {
    const isSame = isEqual(
      contact?.kind === "company" ? COMPANY_EMPTY : CONTACT_EMPTY,
      contact
    );
    setShowConfirm(!isSame);
  }, [contact]);

  useEffect(() => {
    // also counting deleted users
    const emails = users
      ?.filter((user) => user?.kind === "member")
      .map((member) => member?.email);
    setMemberEmails(emails);
  }, [users]);

  useEffect(() => {
    setPtoCategory(systemConfiguration?.system?.pto?.category);
    if (systemConfiguration?.system?.pto?.locations?.length) {
      setStates(
        systemConfiguration?.system?.pto?.locations?.map((loc) => ({
          label: loc.location,
          value: loc.id,
        }))
      );
    }
  }, [systemConfiguration]);

  useEffect(() => {
    if (users && originalSubscription) {
      const activeMemberCount = users?.filter(
        (user) =>
          user?.kind === "member" && user?.active && !user?.metadata?.deletedAt
      )?.length;
      const seats = originalSubscription?.userCount;
      setAvailableSeats(
        seats > activeMemberCount ? seats - activeMemberCount : 0
      );
    }
  }, [originalSubscription, users]);

  useEffect(() => {
    if (allRoles?.length) {
      setRoles(
        allRoles.map((role) => ({
          label: role.name,
          value: role.reference,
        }))
      );
    }
  }, [allRoles]);

  const [invalidForm, setInvalidForm] = useState(null);

  useEffect(() => {
    // if company Check companyName length, otherwise check firstName length
    const hasFirstName =
      contact?.kind !== "company"
        ? // First Name always required, remove spaces and make sure at least 1 char long
          contact?.name?.firstName?.trim()?.length > 0 ||
          contact?.name?.lastName?.trim()?.length > 0
        : // Company Name always required, remove spaces and make sure at least 3 char long
          contact?.companyName?.trim()?.length > 0;

    // returns contactPoint Obj of email { use: "Home", system: "Email", value: "email@email.com"}
    const hasEmail = contact?.contactPoint?.find(
      (item) => item.system === "Email"
    );
    // check email against regex
    const checkEmail = yupEmailRegExp.test(hasEmail?.value);
    // set if the form is invalid or not
    if (inviteStatus === "pending" || hasEmail?.value?.length) {
      setInvalidForm(!(checkEmail && hasFirstName));
    } else {
      setInvalidForm(!hasFirstName);
    }
  }, [contact, inviteStatus]);

  const postFinishedUser = useCallback(
    (user) => {
      postUser(user)
        .then(async (response) => {
          if (response) {
            contactDispatch({
              type: "success",
              id: response?.id,
              reference: response?.reference,
            });
            if (modalData?.updateSelect?.update) {
              modalData?.updateSelect.update(response);

              modalData?.updateSelect.add(formatSelectUser({ ...response }));
            }
          }

          // if Inviting to be a member
          if (inviteStatus === "pending") {
            const hasEmail = contact?.contactPoint?.filter(
              (item) => item.system === "Email"
            );
            const payload = {
              ...response,
              ...inviteData,
              email: hasEmail?.[0]?.value,
            };
            // invite member await response
            try {
              await UserAPI.postWOP(`${payload.id}/$invitecontact`, payload);
              queryClient.invalidateQueries(userKeys?.users);
              toastMessage("Invite sent successfully");
              setInviteStatus("success");
            } catch (error) {
              toastError("There was an issue sending the invitation");
              setInviteStatus("nonmember");
            }
          }

          toastMessage("Contact has been created successfully");
        })
        .catch(() => {
          toastError("There was an issue creating this contact");
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [
      contact?.contactPoint,
      contactDispatch,
      inviteData,
      inviteStatus,
      modalData?.updateSelect,
      postUser,
      queryClient,
    ]
  );

  const createButtonHandler = useCallback(async () => {
    setSaving(true);
    // if creating new company in Create Screen
    if (contact?.isNewCompany) {
      // Post new Company
      postUser({
        ...COMPANY_EMPTY,
        kind: "company",
        company: { value: contact?.companyName, type: "string" },
        companyName: contact?.companyName,
      }).then((response) => {
        // Create Updated User Obj with the returned userRef and companyName
        const updatedUser = {
          ...contact,
          companyName: response?.companyName,
          company: { value: response?.reference, type: "reference" },
        };
        // Initiate Posting the new contact with updated reference to the new company
        postFinishedUser(updatedUser);
      });
    } else {
      // Post Contact normally
      postFinishedUser(contact);
    }
  }, [contact, postFinishedUser, postUser]);

  const onFinish = (inviteInfo) => {
    setInviteStatus("pending");
    setInviteData(inviteInfo);
  };

  const handleInviteButton = () => {
    const rect = modalRef.current.getBoundingClientRect();
    const { left, top } = rect;
    const newData = {
      userInfo: { ...contact },
      directReportOptions,
      roles,
      availableSeats,
      states,
      ptoCategory,
      memberEmails,
      onFinish,
    };
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      ref: { id: uuidv4() },
      popupData: newData,
      position: {
        x: left - 400,
        y: top + 40,
      },
      popupType: INVITE_CONTACT_POPUP,
    });
  };

  return (
    <ModalWrapper
      title="Create Contact"
      resourceName="Contact"
      primaryButtonOnClick={createButtonHandler}
      disabled={invalidForm || saving}
      modalData={modalData}
      showConfirm={showConfirm}
      width="839px"
    >
      <ContactFormContainer
        contactDispatch={contactDispatch}
        contact={contact}
        memberEmails={memberEmails}
        contactType={modalData?.contactType}
        handleInviteButton={handleInviteButton}
        modalRef={modalRef}
        inviteStatus={inviteStatus}
      />
    </ModalWrapper>
  );
};

ContactCreateModal.propTypes = {
  modalData: PropTypes.shape({
    updateSelect: PropTypes.shape({
      update: PropTypes.func,
      add: PropTypes.func,
    }),
    contactType: PropTypes.string,
  }),
};
ContactCreateModal.defaultProps = {
  modalData: undefined,
};

export default ContactCreateModal;
