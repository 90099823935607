import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { toastError, toastMessage } from "../../../Toast/Toast";
import { taskKeys } from "../../../../../config/reactQuery/queryKeyFactory";
import getUpdatedAllTaskSteps from "../../../../../helpers/Utilities/getUpdatedAllTaskSteps";
import useEditCalendar from "../../../../../hooks/calendar/useEditCalendar";

const useSopStepStatusData = (sopsForTask, currentTask, setCurrentTask) => {
  const queryClient = useQueryClient();

  const { editInCalendar } = useEditCalendar();

  const isAllChecked = sopsForTask.every((step) => step?.stepData?.isCompleted);

  const handleCompleteStepCheck = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$non-recurring",
          };

          const taskPatchResponse = await editInCalendar(props);

          setCurrentTask({
            ...taskPatchResponse.data,
          });

          toastMessage("Task updated successfully");
        } else {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$single",
          };

          const updatedTaskItem = await editInCalendar(props);

          setCurrentTask({
            ...updatedTaskItem.data,
          });
        }

        toastMessage("Task updated successfully");

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        console.error(error);
        toastError("This task could not be updated");
      }
    },
    [currentTask, queryClient, setCurrentTask, editInCalendar]
  );

  const handleCompleteCheck = useCallback(
    (newIsAllChecked) => {
      const newCompletionStatus = newIsAllChecked;
      const updatedTaskSteps = getUpdatedAllTaskSteps(
        currentTask.steps,
        sopsForTask,
        newCompletionStatus
      );

      const updatedTask = { ...currentTask, steps: updatedTaskSteps };

      handleCompleteStepCheck(updatedTask);
      return newCompletionStatus;
    },
    [currentTask, handleCompleteStepCheck, sopsForTask]
  );

  return {
    isAllChecked,
    handleCompleteCheck,
  };
};

export default useSopStepStatusData;
