import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { createContext, useContext, useReducer } from "react";
import {
  SET_PAGE_STATE,
  ADD_STREAM_CHANNEL,
  CONNECT_STREAM_USER,
  DELETE_SUBMITTAL,
  LOGOUT_USER,
  NEW_SUBMITTAL,
  PATCH_SUBMITTAL,
  PUSH_USER_EVENTS_DICT,
  REMOVE_ASSOCIATED_EVENT,
  REMOVE_USER_EVENT,
  REMOVE_USER_EVENTS,
  RESET_APPROVAL_VIEW,
  RESET_PAYROLL_VIEW,
  RESET_PTO_VIEW,
  RESET_TIMECARDS_VIEW,
  RESET_TIMESHEET_STATE,
  SET_ACTIVE_TAB,
  SET_ADMIN_SETTINGS,
  SET_ALL_EVENTS_DICT,
  SET_ALL_USERS,
  SET_APPROVAL_DATE,
  SET_APPROVAL_USER_CARD_VIEW,
  SET_APPROVAL_USER_DETAIL_VIEW,
  SET_ASSETS,
  SET_ASSET_CATEGORIES,
  SET_ASSET_MODAL_STATUS,
  SET_ASSET_SUBCATEGORIES,
  SET_ASSOCIATED_EVENTS_DICT,
  SET_ASSOCIATED_FILES,
  SET_ATTRIBUTES,
  SET_BUDGETS,
  SET_BUDGET_DETAILS,
  SET_BUDGET_GROUPS,
  SET_BUDGET_LAST_UPDATED,
  SET_BUDGET_LINE_ITEMS,
  SET_BUDGET_LOCATIONS,
  SET_BUDGET_LOCK_FIXED_FIRM,
  SET_BUDGET_LOCK_GMP,
  SET_BUDGET_TYPE,
  SET_CALENDAR_DATA,
  SET_CALENDAR_MODAL_STATUS,
  SET_CALENDAR_VIEW,
  SET_CALENDAR_WIDGET_EVENT,
  SET_CLIENTS,
  SET_COMMENTS,
  SET_COMPANIES,
  SET_CONTACT_MODAL_STATUS,
  SET_CURRENT_USER,
  SET_CURRENT_USER_AVATAR,
  SET_DIRECT_REPORTS,
  SET_DIRECT_REPORT_OPTIONS,
  SET_DOCUMENTS,
  SET_DOCUMENTS_CONFIGURATION,
  SET_DOCUMENTS_DICT,
  SET_DOCUMENT_SECTIONS,
  SET_DOC_LINE_ITEMS,
  SET_EMPLOYEES,
  SET_EVENTS,
  SET_EVENTS_RELOAD_FUNC,
  SET_FAVORITES,
  SET_FINANCIALS_ACTUAL,
  SET_FINANCIALS_CONFIGURATION,
  SET_IS_SIGNED_IN,
  SET_IS_SITE_MAINTENANCE,
  SET_JUMP_TO_DATE,
  SET_MAINTENANCE_TASKS,
  SET_MAIN_REF,
  SET_MANAGEMENT_CONFIGURATION,
  SET_MEASUREMENTS,
  SET_MESSAGE_FILTER_KEYWORD,
  SET_MESSAGE_SEARCH_KEYWORD,
  SET_PAYROLL_DETAIL_VIEW,
  SET_PAYROLL_PERIOD,
  SET_PREFERENCES,
  SET_PROJECTS,
  SET_PROJECTS_CODES,
  SET_PROJECT_DD,
  SET_PROJECT_DICT,
  SET_PROJECT_MODAL_STATUS,
  SET_PROPERTIES,
  SET_PROPERTIES_DD,
  SET_PROPERTIES_DICT,
  SET_PROPERTY_ASSET_TOTALS,
  SET_PROPERTY_MODAL_STATUS,
  SET_PTO_LOCATIONS_LIBRARY,
  SET_PTO_SETTINGS,
  SET_PTO_USER_DETAIL_VIEW,
  SET_REQUEST_MODAL_STATUS,
  SET_RFI,
  SET_RFI_DICT,
  SET_RFI_MODAL_STATUS,
  SET_ROLES,
  SET_ROLES_DICT,
  SET_ROLES_WOP,
  SET_SETTINGS,
  SET_SOPS,
  SET_SOPS_DICT,
  SET_SOP_MODAL_STATUS,
  SET_SUBMITTALS,
  SET_SUBMITTAL_DD,
  SET_SUBMITTAL_DICT,
  SET_SUBMITTAL_MODAL_STATUS,
  SET_SYSTEM_CONFIGURATION,
  SET_TABLE_FUNCTIONS,
  SET_TASKS,
  SET_TASKS_DICT,
  SET_TASK_FOR_SERVICE_REQUEST,
  SET_TEMPLATES_CONFIGURATION,
  SET_TIMECARDS_USER_DETAIL_VIEW,
  SET_TIMECARD_USER_DATE,
  SET_TIMEOFF_YEAR,
  SET_TIMEOFF_YEAR_OPTIONS,
  SET_TIMESHEET,
  SET_TIMESHEET_APPROVAL,
  SET_TIMESHEET_STATE,
  SET_TODOS,
  SET_USERS,
  SET_USERS_HOOK_STATE,
  SET_USERS_STREAM_DICT,
  SET_USERS_STREAM_INFO,
  SET_USER_DICT,
  SET_USER_EVENTS,
  SET_USER_EVENTS_DICT,
  SET_VIEW_MORE,
  SET_WATCHLIST,
  SET_WORKFLOW,
  SET_WORKFLOWS,
  SET_WORKFLOWS_CONFIGURATION,
  SET_WORKFLOW_ATTACHMENTS,
  SET_WORKFLOW_DD,
  SET_WORKFLOW_DICT,
  SET_WORKFLOW_DOCUMENT,
  SET_WORKFLOW_MODAL_STATUS,
  SET_WORKFLOW_SELECTED,
  SET_WORKFLOW_TEMPLATES,
  TOGGLE_POSITIONED_POPUP,
  UPDATE_ASSOCIATED_EVENTS_DICT,
  UPDATE_GLOBAL_STATE,
  UPDATE_USERS,
  UPDATE_USER_EVENTS_DICT,
  USERS_HOOK_STATE,
  WF_ACTIVE_STEP_TASK_INFO,
  SET_CALENDAR_TIMEZONE,
  SET_ALL_UNFORMATTED_EVENTS,
} from "../constants";
import Auth from "../helpers/Auth";
import {
  pushUserEvent,
  removeObjectById,
  wipeEvents,
} from "../helpers/Calendar";

const Context = createContext();

// TODO (RQ-Perf: Remove P/P/A from global state)
const initialState = {
  isSignedIn: Auth.isSignedIn(),
  isSiteMaintenance: false,
  currentUser: undefined,
  calendarTimezone: moment.tz.guess(),
  assetTotalPerProperty: [],
  totalAssetValue: 0,
  totalAssetCount: 0,
  properties: [],
  propertiesDD: [],
  projectDD: [],
  assetsDD: [],
  events: [],
  unformattedEvents: [],
  userEvents: {},
  associatedFiles: [],
  associatedFilesDict: {},
  recentFiles: [],
  favorites: [],
  assets: [],
  budgets: [],
  budgetLineItems: [],
  budgetGroups: [],
  measurements: {},
  attributes: [],
  selectedAttributes: [],
  assetCategories: [],
  assetSubcategories: {},
  clients: [],
  watchList: [],
  timeSheet: {},
  messages: {
    searchKeyword: "",
    filterKeyword: "",
  },
  timeSheetApproval: [],
  // projects: [],
  companies: [],
  financialsActual: [],
  users: [],
  allUsers: [],
  usersHookState: USERS_HOOK_STATE.loading,
  // workflows: [],
  workflowTemplates: [],
  usersStreamInfo: undefined,
  usersStreamDict: undefined,
  directReports: {},
  directReportOptions: [],
  rolesDict: {},
  roles: [],
  rolesWOP: [],
  todos: undefined,
  timeOff: [],
  timeOffYear: {
    label: new Date().getFullYear().toString(),
    value: new Date().getFullYear().toString(),
  },
  calendarData: moment().format(),
  associatedEvents: {},
  viewMore: "",
  calendarView: {
    label: "Month",
    value: "month",
  },
  // Modal statuses
  requestsModalStatus: {
    open: false,
    association: undefined,
    asset: undefined,
  },
  calendarModalStatus: {
    open: false,
    edit: false,
    view: false,
    create: false,
    createTask: false,
    widgetView: false,
    sideBarOpen: false,
  },
  contactModalStatus: {
    open: false,
    invite: false,
    contactType: undefined,
  },
  propertyModalStatus: {
    open: false,
  },
  calendarWidgetEvent: {},
  projectModalStatus: { open: false },
  assetModalStatus: {
    open: false,
    allowAssocSelect: false,
    propertyId: "",
    projectId: "",
    assetId: "",
    locationId: "",
    spaceId: "",
  },
  sopModalStatus: { open: false, activeTabIndex: undefined },
  submittalModalStatus: {
    open: false,
    edit: false,
    submittal: {},
    complete: false,
    allowAssocSelect: true,
  },
  // end modal statuses

  sops: [],
  sopSelectOptions: [],
  submittals: [],
  workflowAttachments: [],
  workflowSelected: [],
  workflowDocument: {
    startDate: moment().format(),
    name: "",
  },
  workflowsNew: [],
  activeTabIndex: null,
  tasks: [],
  tasksDict: {},
  rfi: [],
  rfiModalStatus: { open: false },
  modals: [],
  propertiesDict: {},
  assetsDict: {},
  projectDict: {},
  sopsDict: {},
  eventsDict: {},
  popupData: {
    position: { x: 0, y: 0 },
    popupData: null,
    popupType: null,
  },
  modalData: {
    position: { x: 0, y: 0 },
    modalData: null,
    modalType: null,
  },
  userDict: {},
  tableHelpers: {},
  ptoLocationsLibrary: undefined,
  timesheetstate: {
    tab: 0,
    timecardUserId: undefined,
    approvalUserId: undefined,
    payrollUserId: undefined,
    approvalDate: undefined,
    ptoUserId: undefined,
  },
  pageState: {
    timesheet: {
      userId: "",
      tab: "",
      periodStart: "",
      periodEnd: "",
    },
  },
};

const preserveArrayState = (
  newStateArray,
  oldStateArray,
  comparator = (newState, oldState) => newState.id === oldState.id
) =>
  newStateArray?.map((newState) => ({
    ...(oldStateArray.find((oldState) => comparator(newState, oldState)) ?? {}),
    ...newState,
  }));

const appReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_GLOBAL_STATE: {
      return { ...state, ...action.payload };
    }
    case SET_PAGE_STATE: {
      return { ...state, pageState: action.payload };
    }
    case SET_CURRENT_USER_AVATAR: {
      localStorage.setItem(`avi/${action.id}`, JSON.stringify(action.ref));
      return { ...state, currentUserAvatar: action.file };
    }
    case LOGOUT_USER:
      return { ...initialState, isSignedIn: false };
    case SET_IS_SIGNED_IN:
      return { ...state, isSignedIn: action.isSignedIn };
    case SET_IS_SITE_MAINTENANCE:
      return { ...state, isSiteMaintenance: action.isSiteMaintenance };
    case SET_CURRENT_USER: {
      return { ...state, currentUser: action.currentUser };
    }
    case CONNECT_STREAM_USER:
      return {
        ...state,
        chatUser: action.chatUser,
        chatChannels: action.channels,
      };
    case ADD_STREAM_CHANNEL:
      return {
        ...state,
        chatChannels: [...(state.chatChannels ?? []), action.channel],
      };
    case SET_MAIN_REF:
      return { ...state, mainContainerRef: action.ref };
    case SET_PROPERTY_ASSET_TOTALS:
      return {
        ...state,
        assetTotalPerProperty: action.assetTotalPerProperty,
        totalAssetValue: action.totalAssetValue,
        totalAssetCount: action.totalAssetCount,
      };
    case SET_CALENDAR_MODAL_STATUS:
      return {
        ...state,
        calendarModalStatus: action,
      };
    case SET_PROJECT_MODAL_STATUS:
      return {
        ...state,
        projectModalStatus: {
          open: action.open,
          property: action.property,
        },
      };
    case SET_CONTACT_MODAL_STATUS:
      return {
        ...state,
        contactModalStatus: {
          open: action.open,
          invite: action.invite,
          contactType: action.contactType,
        },
      };
    /**
     * @Deprecated Use <ModalWrapper />
     */
    case SET_ASSET_MODAL_STATUS:
      return {
        ...state,
        assetModalStatus: {
          open: action.open,
          propertyId: action.propertyId,
          projectId: action.projectId,
          assetId: action.assetId,
          locationId: action.locationId,
          spaceId: action.spaceId,
          allowAssocSelect: action.allowAssocSelect,
          disableSpaceSelect: action.disableSpaceSelect,
        },
      };
    case SET_PROPERTY_MODAL_STATUS:
      return {
        ...state,
        propertyModalStatus: { open: action.open },
      };
    case SET_CALENDAR_TIMEZONE:
      return {
        ...state,
        calendarTimezone: action.calendarTimezone,
      };
    case SET_SOP_MODAL_STATUS:
      return {
        ...state,
        sopModalStatus: {
          open: action.open,
          activeTabIndex: action.activeTabIndex,
        },
      };
    case SET_RFI_MODAL_STATUS:
      return {
        ...state,
        rfiModalStatus: {
          open: action.open,
          artifactFor: action.artifactFor,
        },
      };
    case SET_SOPS:
      return {
        ...state,
        sops: preserveArrayState(action.sops, state.sops),
        sopSelectOptions: preserveArrayState(
          action.sopSelectOptions,
          state.sopSelectOptions
        ),
      };
    case SET_SOPS_DICT:
      return {
        ...state,
        sopsDict: action.sopsDict,
      };

    // new workflows
    case SET_WORKFLOW:
      return {
        ...state,
        workflowsNew: action.workflows,
      };
    case SET_WORKFLOW_DICT:
      return {
        ...state,
        workflowDict: action.workflowDict,
      };
    case SET_WORKFLOW_DD:
      return {
        ...state,
        workflowDD: action.workflowDD,
      };

    case SET_SUBMITTALS:
      return {
        ...state,
        submittals: action.submittals,
      };
    case SET_SUBMITTAL_DICT:
      return {
        ...state,
        submittalDict: action.submittalDict,
      };
    case SET_SUBMITTAL_DD:
      return {
        ...state,
        submittalDD: action.submittalDD,
      };
    case SET_SUBMITTAL_MODAL_STATUS:
      return {
        ...state,
        submittalModalStatus: {
          open: action.open,
          edit: action.edit,
          submittal: action.submittal,
          complete: action.complete,
          artifactFor: action.artifactFor,
          allowAssocSelect: action.allowAssocSelect,
          activeTabIndex: action.activeTabIndex,
          association: action.association,
        },
      };
    case SET_WORKFLOW_MODAL_STATUS:
      return {
        ...state,
        workflowModalStatus: {
          open: action.open,
          edit: action.edit,
          submittal: action.workflow,
          complete: action.complete,
          artifactFor: action.artifactFor,
          allowAssocSelect: action.allowAssocSelect,
          activeTabIndex: action.activeTabIndex,
          association: action.association,
          documents: action.documents,
        },
      };
    case NEW_SUBMITTAL:
      return {
        ...state,
        submittals: [...state.submittals, action.newSubmittal],
      };
    case DELETE_SUBMITTAL: {
      const filteredSubmittals = state.submittals?.filter(
        (submittal) => submittal?.id !== action.submittal?.id
      );
      return {
        ...state,
        submittals: filteredSubmittals,
      };
    }
    case PATCH_SUBMITTAL: {
      const patchedSubmittals = state.submittals?.map((submittal) =>
        submittal?.id === action.updatedSubmittal?.id
          ? action.updatedSubmittal
          : submittal
      );
      return {
        ...state,
        submittals: patchedSubmittals,
      };
    }
    case SET_PROPERTIES:
      return {
        ...state,
        properties: preserveArrayState(action.properties, state.properties),
      };
    case SET_PROPERTIES_DICT:
      return {
        ...state,
        propertiesDict: action.propertiesDict,
      };
    case SET_PROPERTIES_DD:
      return {
        ...state,
        propertiesDD: action.dropDown,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
      };
    case SET_DOCUMENTS_DICT:
      return {
        ...state,
        documentsDict: action.documentsDict,
      };
    case SET_ASSOCIATED_FILES:
      return {
        ...state,
        associatedFiles: action.associatedFiles,
        associatedFilesDict: action.associatedFilesDict,
      };
    case SET_EVENTS: {
      return {
        ...state,
        events: action.events,
      };
    }
    case SET_ALL_UNFORMATTED_EVENTS: {
      return {
        ...state,
        unformattedEvents: action.unformattedEvents,
      };
    }
    case SET_MAINTENANCE_TASKS:
      return {
        ...state,
        maintenanceTasks: action.maintenanceTasks,
      };

    // this is a duplicate of SET_USER_EVENTS, changing the actions.events, state.userEvents broke realtime updating
    case SET_USER_EVENTS: {
      return {
        ...state,
        userEvents: action.userEvents,
      };
    }
    case SET_USER_EVENTS_DICT: {
      return {
        ...state,
        userEvents: { ...action.events },
      };
    }

    case SET_ALL_EVENTS_DICT:
      return { ...state, eventsDict: action.eventsDict };

    case UPDATE_USER_EVENTS_DICT: {
      let tempList = state?.userEvents;

      tempList = wipeEvents(tempList, action.originalKey, action.ref, -1);
      tempList = wipeEvents(tempList, action.originalKey, action.ref, 1);

      /* dont use line 205, dont push another event in */
      tempList = pushUserEvent(tempList, action.event);
      return { ...state, userEvents: tempList };
    }
    case REMOVE_USER_EVENT: {
      let tempList = state?.userEvents;

      tempList = wipeEvents(tempList, action.originalKey, action.ref, -1);
      tempList = wipeEvents(tempList, action.originalKey, action.ref, 1);

      return { ...state, userEvents: tempList };
    }

    case REMOVE_ASSOCIATED_EVENT: {
      let tempList = state?.associatedEvents[action.association];

      tempList = wipeEvents(tempList, action.originalKey, action.ref, -1);
      tempList = wipeEvents(tempList, action.originalKey, action.ref, 1);

      return {
        ...state,
        associatedEvents: {
          ...state.associatedEvents,
          [action.association]: tempList,
        },
      };
    }
    case UPDATE_ASSOCIATED_EVENTS_DICT: {
      let tempList = state?.associatedEvents[action.association];
      tempList = wipeEvents(tempList, action.originalKey, action.ref, -1);
      tempList = wipeEvents(tempList, action.originalKey, action.ref, 1);

      tempList = pushUserEvent(tempList, action.event);
      return {
        ...state,
        associatedEvents: {
          ...state.associatedEvents,
          [action.association]: tempList,
        },
      };
    }
    case SET_ASSOCIATED_EVENTS_DICT: {
      return {
        ...state,
        associatedEvents: {
          ...state.associatedEvents,
          [action.key]: action.events,
        },
      };
    }

    case PUSH_USER_EVENTS_DICT: {
      let userList = state?.userEvents;
      userList = pushUserEvent(userList, action.event);
      let newState = { ...state, userEvents: userList };
      if (action.associated) {
        let associatedList = newState?.associatedEvents[action.associated];

        associatedList = pushUserEvent(associatedList, action.event);
        newState = {
          ...newState,
          associatedEvents: {
            ...state.associatedEvents,
            [action.associated]: associatedList,
          },
        };
      }
      return newState;
    }
    case SET_EVENTS_RELOAD_FUNC:
      return { ...state, reloadEvents: action.function };
    case SET_FAVORITES:
      return {
        ...state,
        favorites: action.favorites,
      };
    case SET_WATCHLIST:
      return {
        ...state,
        watchList: action.watchList,
      };
    case SET_ASSETS:
      // map over new assets and capture any old fields that aren't on the new asset (i.e. primaryImage)
      return {
        ...state,
        assets: action?.assets
          ? preserveArrayState(action.assets, state.assets)
          : state.assets,
        assetsDict: action?.assetsDict ?? state?.assetsDict,
        assetsDD: action?.assetsDD ?? state?.assetsDD,
        assetAssociations:
          action?.assetAssociations ?? state?.assetAssociations,
      };
    case SET_BUDGETS:
      return {
        ...state,
        budgets: preserveArrayState(action.budgets, state.budgets),
      };
    case SET_USERS:
      return {
        ...state,
        users: preserveArrayState(action.users, state.users),
      };
    case SET_ALL_USERS:
      return {
        ...state,
        allUsers: preserveArrayState(action.allUsers, state.allUsers),
      };
    case SET_USERS_HOOK_STATE:
      return {
        ...state,
        usersHookState: action.value,
      };
    case SET_USER_DICT:
      return {
        ...state,
        userDict: action.userDict,
      };
    case SET_EMPLOYEES:
      return {
        ...state,
        employees: action.employees,
      };
    case UPDATE_USERS:
      // update allUsers, users, userDict
      return {
        ...state,
        userDict: { ...state.userDict, [action.user.reference]: action.user },
        users: preserveArrayState(
          state.users.map((user) => {
            if (user.id === action.user.id) {
              return action.user;
            }
            return user;
          }),
          state.allUsers
        ),
      };
    case SET_DIRECT_REPORTS:
      return {
        ...state,
        directReports: action.directReports,
      };
    case SET_DIRECT_REPORT_OPTIONS:
      return {
        ...state,
        directReportOptions: action.directReportOptions?.map((item) => ({
          ...item,
          options: item?.options?.sort((a, b) =>
            a?.label > b?.label ? 1 : -1
          ),
        })),
      };
    case SET_ROLES_DICT:
      return {
        ...state,
        rolesDict: action.rolesDict,
      };
    case SET_ROLES:
      return {
        ...state,
        roles: preserveArrayState(action.roles, state.roles),
      };
    case SET_ROLES_WOP:
      return {
        ...state,
        rolesWOP: action.roles,
      };
    case SET_MANAGEMENT_CONFIGURATION:
      return {
        ...state,
        managementConfiguration: action.managementConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
      };
    case SET_TEMPLATES_CONFIGURATION:
      return {
        ...state,
        templatesConfiguration: action.templatesConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
      };
    case SET_WORKFLOWS_CONFIGURATION:
      return {
        ...state,
        workflowsConfiguration: action.workflowsConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
      };
    case SET_DOCUMENTS_CONFIGURATION:
      return {
        ...state,
        documentsConfiguration: action.documentsConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
      };
    case SET_SYSTEM_CONFIGURATION:
      return {
        ...state,
        systemConfiguration: action.systemConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
      };
    case SET_PTO_LOCATIONS_LIBRARY:
      return {
        ...state,
        ptoLocationsLibrary: action.locations,
      };
    case SET_PTO_SETTINGS:
      return {
        ...state,
        ptoSettings: action.general,
        ptoCategory: action.category,
      };
    case SET_FINANCIALS_CONFIGURATION:
      return {
        ...state,
        financialsConfiguration: action.financialsConfiguration,
        configurationLastUpdated: action.configurationLastUpdated,
        csiCodeMappingObject: action.csiCodeMappingObject,
      };
    case SET_PAYROLL_PERIOD: {
      const pageState = JSON.parse(window.localStorage.getItem("pageData"));

      if (
        pageState?.timesheet?.periodStart &&
        pageState?.timesheet?.periodEnd
      ) {
        const newPeriodStart = action.payrollPeriod?.periodStart?.split("T")[0];
        const newPeriodEnd = action.payrollPeriod?.periodEnd?.split("T")[0];

        localStorage.setItem(
          "pageData",
          JSON.stringify({
            ...pageState,
            timesheet: {
              ...pageState.timesheet,
              periodStart: newPeriodStart,
              periodEnd: newPeriodEnd,
            },
          })
        );

        return {
          ...state,
          payrollPeriod: action.payrollPeriod,
          pageState: {
            ...state.pageState,
            timesheet: {
              ...state.pageState.timesheet,
              periodStart: newPeriodStart,
              periodEnd: newPeriodEnd,
            },
          },
        };
      }

      return {
        ...state,
        payrollPeriod: action.payrollPeriod,
      };
    }
    case SET_BUDGET_LINE_ITEMS:
      return {
        ...state,
        budgetLineItems: preserveArrayState(
          action.budgetLineItems,
          state.budgetLineItems
        ),
      };
    case SET_BUDGET_GROUPS:
      return {
        ...state,
        budgetGroups: preserveArrayState(
          action.budgetGroups,
          state.budgetGroups
        ),
        budgetLastUpdated: action?.budgetLastUpdated,
      };
    case SET_BUDGET_DETAILS:
      return {
        ...state,
        budgetLineItems: preserveArrayState(
          action.budgetLineItems,
          state.budgetLineItems
        ),
        budgetGroups: preserveArrayState(
          action.budgetGroups,
          state.budgetGroups
        ),
        budgetLastUpdated: action?.budgetLastUpdated,
        budgetType: action?.budgetType,
        budgetPendingUpdates: action?.budgetPendingUpdates,
        isLockGmp: action?.isLockGmp,
        isLockFixedFirm: action?.isLockFixedFirm,
        budgetSummary: action?.budgetSummary,
        budget: action?.budget,
      };
    case SET_BUDGET_TYPE:
      return {
        ...state,
        budgetLastUpdated: action?.budgetLastUpdated,
        budgetType: action?.budgetType,
      };
    case SET_BUDGET_LAST_UPDATED:
      return {
        ...state,
        budgetLastUpdated: action?.budgetLastUpdated,
      };
    case SET_BUDGET_LOCK_GMP:
      return {
        ...state,
        isLockGmp: action?.isLockGmp,
        budgetLastUpdated: action?.budgetLastUpdated,
        budgetLineItems: preserveArrayState(
          action.budgetLineItems,
          state.budgetLineItems
        ),
      };
    case SET_BUDGET_LOCK_FIXED_FIRM:
      return {
        ...state,
        isLockFixedFirm: action?.isLockFixedFirm,
        budgetLastUpdated: action?.budgetLastUpdated,
        budgetLineItems: preserveArrayState(
          action.budgetLineItems,
          state.budgetLineItems
        ),
      };
    case SET_BUDGET_LOCATIONS:
      return {
        ...state,
        budgetLocations: preserveArrayState(
          action.budgetLocations,
          state.budgetLocations
        ),
      };
    case SET_MEASUREMENTS:
      return {
        ...state,
        measurements: action.measurements,
      };
    case SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.attributes,
        selectedAttributes: action.selectedAttributes,
      };
    case SET_ASSET_CATEGORIES:
      return {
        ...state,
        assetCategories: action.assetCategories,
      };
    case SET_ASSET_SUBCATEGORIES:
      return {
        ...state,
        assetSubcategories: action.assetSubcategories,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };
    case SET_TIMESHEET:
      return {
        ...state,
        timeSheet: action.timeSheet,
      };
    case SET_TIMESHEET_APPROVAL:
      return {
        ...state,
        timeSheetApproval: action.timeSheetApproval,
      };
    case SET_PREFERENCES:
      return {
        ...state,
        preferenceId: action.preferenceId,
        like: action.like,
        dislike: action.dislike,
        special: action.special,
        userPreferenceLastUpdated: action.userPreferenceLastUpdated,
      };
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      };
    case SET_PROJECT_DICT:
      return {
        ...state,
        projectDict: action.projectDict,
      };
    case SET_PROJECT_DD:
      return {
        ...state,
        projectDD: action.dropDown,
      };
    case SET_PROJECTS_CODES:
      return {
        ...state,
        projectCodes: action.codes,
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
      };
    case SET_WORKFLOWS:
      return {
        ...state,
        workflows: action.workflows,
      };
    case SET_WORKFLOW_TEMPLATES:
      return {
        ...state,
        workflowTemplates: action.workflowTemplates,
      };
    case SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case SET_ADMIN_SETTINGS:
      return {
        ...state,
        adminSettings: action.adminSettings,
      };
    case SET_FINANCIALS_ACTUAL:
      return {
        ...state,
        financialsActual: preserveArrayState(
          action.financialsActual,
          state.financialsActual
        ),
      };
    case SET_MESSAGE_SEARCH_KEYWORD:
      return {
        ...state,
        messages: {
          searchKeyword: action.search_keyword,
        },
      };
    case SET_MESSAGE_FILTER_KEYWORD:
      return {
        ...state,
        messages: {
          filterKeyword: action.filter_keyword,
          searchKeyword: "",
        },
      };
    case SET_USERS_STREAM_INFO:
      return {
        ...state,
        usersStreamInfo: action.usersStreamInfo,
      };
    case SET_USERS_STREAM_DICT:
      return {
        ...state,
        usersStreamDict: action.usersStreamDict,
      };
    case SET_DOCUMENT_SECTIONS:
      return {
        ...state,
        documentSections: action.documentSections,
      };
    case SET_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      };
    case SET_WORKFLOW_DOCUMENT:
      return {
        ...state,
        workflowDocument: action?.workflowDocument ?? action?.value,
      };
    case SET_DOC_LINE_ITEMS:
      return {
        ...state,
        docLineItems: action.docLineItems,
      };
    case SET_TODOS:
      return {
        ...state,
        todos: action.todos,
      };
    case SET_TIMEOFF_YEAR:
      return {
        ...state,
        timeOffYear: action.timeOffYear,
      };
    case SET_TIMEOFF_YEAR_OPTIONS:
      return {
        ...state,
        timeOffYearOptions: action.timeOffYearOptions,
      };
    case SET_CALENDAR_DATA:
      return {
        ...state,
        calendarData: action?.data,
      };
    case SET_JUMP_TO_DATE:
      return {
        ...state,
        jumpToDate: action?.data,
      };
    case SET_VIEW_MORE:
      return {
        ...state,
        viewMore: action?.control,
      };
    case SET_CALENDAR_VIEW:
      return {
        ...state,
        calendarView: action.value,
      };
    case SET_CALENDAR_WIDGET_EVENT:
      return {
        ...state,
        calendarWidgetEvent: action.value,
      };
    case SET_WORKFLOW_ATTACHMENTS:
      return {
        ...state,
        workflowAttachments: action.value,
      };

    case SET_WORKFLOW_SELECTED:
      return {
        ...state,
        workflowSelected: action.value,
      };

    case WF_ACTIVE_STEP_TASK_INFO:
      return {
        ...state,
        wfActiveStepTaskInfo: action.payload,
      };

    case SET_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      };

    case SET_TASKS_DICT:
      return {
        ...state,
        tasksDict: action.tasksDict,
      };

    case SET_RFI:
      return {
        ...state,
        rfi: action.rfi,
      };

    case SET_RFI_DICT:
      return {
        ...state,
        rfiDict: action.rfiDict,
      };

    case REMOVE_USER_EVENTS: {
      const newUserEvents = removeObjectById(action?.id, state?.userEvents);
      return { ...state, userEvents: newUserEvents };
    }

    case TOGGLE_POSITIONED_POPUP: {
      const { position, popupData, popupType } = action;

      // setting popupType to null will close the popup
      if (action.popupType === null) {
        return {
          ...state,
          popupData: {
            position: { x: 0, y: 0 },
            popupData: null,
            popupType: null,
          },
        };
      }

      return {
        ...state,
        popupData: {
          position,
          popupData,
          popupType,
        },
      };
    }
    /**
     * Functions in the table scope that need to be accessed from parent
     * Example Case: Need to reset selected rows(only happens when table data is mutated)
     * cannot access toggle outside of component where useTable is declared
     * it is added by resource name from table component where we can access it through the appState
     */
    case SET_TABLE_FUNCTIONS:
      return {
        ...state,
        tableHelpers: {
          ...state.tableHelpers,
          [action.table]: { ...action.functions },
        },
      };

    case SET_REQUEST_MODAL_STATUS:
      return {
        ...state,
        requestsModalStatus: {
          open: action.open,
          associationLock: action.associationLock,
          assetLock: action.assetLock,
        },
      };

    case SET_TASK_FOR_SERVICE_REQUEST:
      return {
        ...state,
        taskForRequest: action.taskForRequest,
      };

    /**
     * Timesheet Overview state
     */

    case SET_TIMESHEET_STATE: {
      return {
        ...state,
        timesheetstate: action.timesheetstate,
      };
    }

    case RESET_TIMESHEET_STATE:
      return {
        ...state,
        timesheetstate: {
          tab: 0,
          timecardUserId: undefined,
          approvalUserId: undefined,
          payrollUserId: undefined,
          approvalDate: undefined,
          ptoUserId: undefined,
        },
      };

    case SET_TIMECARD_USER_DATE:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalUserId: action.approvalUserId,
          approvalDate: action.approvalDate,
          tabId: "user-timecard",
        },
      };

    case SET_TIMECARDS_USER_DETAIL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          timecardUserId: action.user,
          timecardDate: action.date,
          tabId: "timecards",
          tab: action?.tab ?? state?.timesheetstate?.tab,
        },
      };
    case RESET_TIMECARDS_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          timecardUserId: undefined,
        },
      };
    case SET_APPROVAL_USER_DETAIL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalUserId: action.user,
          approvalDate: action.date,
        },
      };

    case SET_APPROVAL_DATE:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalDate: action.date,
        },
      };
    case SET_APPROVAL_USER_CARD_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalCardView: true,
        },
      };
    case RESET_APPROVAL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalUserId: undefined,
          approvalCardView: false,
        },
      };
    case SET_PAYROLL_DETAIL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          approvalUserId: action.user,
          approvalDate: action.date,
        },
      };
    case RESET_PAYROLL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          payrollUserId: undefined,
        },
      };
    case SET_PTO_USER_DETAIL_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          ptoUserId: action.user,
        },
      };
    case RESET_PTO_VIEW:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          ptoUserId: undefined,
        },
      };
    case SET_ACTIVE_TAB:
      return {
        ...state,
        timesheetstate: {
          ...state.timesheetstate,
          tab: action.tab,
          tabId: action.id,
          timecardUserId: undefined,
          approvalUserId: undefined,
          payrollUserId: undefined,
          approvalDate: undefined,
          ptoUserId: undefined,
        },
      };
    /**
     * Timesheet Overview state
     */

    default:
      throw new Error();
  }
};

export const AppStateProvider = ({ children }) => {
  const value = useReducer(appReducer, initialState);
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

AppStateProvider.propTypes = {
  /**
   * react component to pass into the context provider
   */
  children: PropTypes.element.isRequired,
};

export const useAppState = () => {
  return useContext(Context);
};
