import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "react-query";
import { toastError, toastMessage } from "../../../../Toast/Toast";
import toggleCompletionStatus from "../../../../../../helpers/Text/toggleCompletionStatus";
import getUpdatedTaskSteps from "../../../../../../helpers/Utilities/getUpdatedTaskSteps";
import { taskKeys } from "../../../../../../config/reactQuery/queryKeyFactory";
import getUpdatedTaskSopSteps from "../../../../../../helpers/Utilities/getUpdatedTaskSopSteps";
import useEditCalendar from "../../../../../../hooks/calendar/useEditCalendar";

const useTaskStepTextData = (sop, currentTask, setCurrentTask) => {
  const queryClient = useQueryClient();

  const { editInCalendar } = useEditCalendar();

  const [completeStep, setCompleteStep] = useState("No");

  const getInitialCheckedState = useCallback(() => {
    return sop?.stepData?.isCompleted ? "Yes" : "No";
  }, [sop?.stepData?.isCompleted]);

  useEffect(() => {
    // set initial step UI complete state and save it
    const completeState = getInitialCheckedState();
    setCompleteStep(completeState);
  }, [getInitialCheckedState, sop.stepData.isCompleted]);

  const onCancelCleanExitFn = useCallback(() => {
    // on pupup cancel reset the checkbox state back to initial
    const completeState = getInitialCheckedState();
    setCompleteStep(completeState);
  }, [getInitialCheckedState]);

  const handleCompleteStepCheck = useCallback(
    async (updatedTask) => {
      try {
        if (!currentTask?.recurrence) {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$non-recurring",
          };

          const taskPatchResponse = await editInCalendar(props);

          setCurrentTask({
            ...taskPatchResponse.data,
          });

          toastMessage("Task updated successfully");
        } else {
          const props = {
            args: {
              originalItem: currentTask,
              editedItem: updatedTask,
            },
            operation: "$single",
          };

          const updatedTaskItem = await editInCalendar(props);

          setCurrentTask({
            ...updatedTaskItem.data,
          });

          toastMessage("Task updated successfully");
        }

        // update tasks activity
        queryClient.invalidateQueries(taskKeys.taskHistory);
      } catch (error) {
        // restore checkbox UI state
        onCancelCleanExitFn();
        toastError("This task could not be updated");
      }
    },
    [
      currentTask,
      queryClient,
      setCurrentTask,
      onCancelCleanExitFn,
      editInCalendar,
    ]
  );

  // handler for marking task steps
  const handleStepCheck = useCallback(
    // set the checkbox state both in the UI and API
    (editedStep) => {
      setCompleteStep((prev) => {
        const newCompletionStatus = toggleCompletionStatus(prev);
        const updatedTaskSteps = getUpdatedTaskSteps(
          currentTask.steps,
          editedStep,
          newCompletionStatus
        );

        const updatedTask = { ...currentTask, steps: updatedTaskSteps };

        handleCompleteStepCheck(updatedTask);
        return newCompletionStatus;
      });
    },
    [currentTask, handleCompleteStepCheck]
  );

  // handler for marking individual sop steps
  const handleSopStepCheck = useCallback(
    // set the checkbox state both in the UI and API
    (sopId, sopStep, totalSteps) => {
      const updatedTaskSteps = getUpdatedTaskSopSteps(
        currentTask.steps,
        sopId,
        sopStep,
        totalSteps
      );

      const updatedTask = { ...currentTask, steps: updatedTaskSteps };

      handleCompleteStepCheck(updatedTask);
    },
    [currentTask, handleCompleteStepCheck]
  );

  return {
    handleCompleteStepCheck,
    onCancelCleanExitFn,
    handleStepCheck,
    handleSopStepCheck,
    completeStep,
  };
};

export default useTaskStepTextData;
