import { AssetAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import assetKeys from "./assetsKeys";

const deleteAsset = (id) => {
  return AssetAPI.delete(id);
};

const useAssetsDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteAsset,
    onMutate: async (id) => {
      const queryKey = assetKeys.assets;

      // Get the current state of the asset to rollback in case of any error
      const currentAssets = queryClient.getQueryData(queryKey) || [];

      // Cancel any query for assets in the meantime
      await queryClient.cancelQueries(queryKey);

      // Find the asset to delete by Id
      const index = currentAssets.findIndex((asset) => asset.id === id);

      if (index !== -1) {
        queryClient.setQueryData(queryKey, (current) => {
          const updatedAssetList = current.slice();
          updatedAssetList.splice(index, 1);

          return updatedAssetList;
        });
      }

      return { currentAssets };
    },
    onError: (error, _, context) => {
      // Rollback to previous state
      const { queryKey, currentAssets } = context;
      queryClient.setQueryData(queryKey, currentAssets);

      console.error("useAssetsDelete: ", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: assetKeys.assets,
      });
    },
  });
};

export default useAssetsDelete;
