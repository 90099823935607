import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useGetTags } from "../../../hooks/useTags";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import { useSpaceSwitchView } from "../../../hooks/useSwitchView";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useGetSpacesList from "../../../hooks/useGetSpaceList";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import { getNestedResourcePath } from "../../../helpers/Navigation";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";

const getSpaceConfiguration = (managementConfiguration) => ({
  spaces: managementConfiguration?.management?.propertySpace?.types
    ?.filter((type) => type?.selected)
    ?.reduce((dict, item) => {
      // eslint-disable-next-line no-param-reassign
      dict[item?.id] = item;
      return dict;
    }, {}),
  levels: managementConfiguration?.management?.propertyLevel?.types
    ?.filter((type) => type?.selected)
    ?.reduce((dict, item) => {
      // eslint-disable-next-line no-param-reassign
      dict[item?.id] = item;
      return dict;
    }, {}),
});

const useSpaceTableData = () => {
  const history = useHistory();
  const { isLoading, data: spaces } = useGetSpacesList();
  const { data: managementConfiguration, isLoading: isLoadingManagement } =
    useManagementConfiguration();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  const { data: currentUser } = useCurrentUser();
  const { data: templatesConfiguration } = useTemplatesConfiguration();
  const { mutate: patchTemplates } = useTemplatesConfigurationPatch();
  const { mutate: deleteTemplate } = useTemplatesConfigurationDelete();
  const [spacesTemplates, setSpacesTemplates] = useState([]);
  const [isShowingSpacesTable] = useSpaceSwitchView();

  const [tableData, setTableData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modals, setModals] = useState({
    create: false,
    edit: false,
    set: (key, value) => setModals((prev) => ({ ...prev, [key]: value })),
  });

  // State to hold ids of selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  const { data: tagsData } = useGetTags();

  // Set templates for spaces (Quick View)
  useEffect(() => {
    if (templatesConfiguration?.templates?.space) {
      setSpacesTemplates(templatesConfiguration.templates.space);
    }
  }, [templatesConfiguration?.templates?.space]);
  /**
   * Set spaces ids for selected rows in state
   */
  const handleSelectRows = useCallback((rows) => {
    setSelectedRows(rows.map(({ id }) => id));
  }, []);

  // TODO: Not covered in this ticket
  // const handleDeleteSpaces = () => {
  //   removeMany(selectedRows?.map(({ id }) => id));
  //   setShowDeleteModal(false);
  // };

  const dicts = useCallback(() => {
    return {
      Property: propertiesDict,
      Project: projectDict,
    };
  }, [projectDict, propertiesDict]);

  useEffect(() => {
    if (
      spaces?.length &&
      !isLoading &&
      !isLoadingManagement &&
      propertiesDict &&
      projectDict
    ) {
      const spaceConfiguration = getSpaceConfiguration(managementConfiguration);
      setTableData(
        spaces?.map((space) => {
          const type = space?.association?.split("/")[0];
          const title = type?.includes("Project") ? "name" : "title";
          const associatedName = dicts()?.[type]?.[space?.association]?.[title];

          return {
            ...space,
            associatedResource: type,
            associatedName,
            [type]: associatedName,
            type: spaceConfiguration?.spaces?.[space?.type]?.display,
            level: spaceConfiguration?.levels?.[space?.level]?.display,
            image: space?.primaryImage,
          };
        })
      );
    }
  }, [
    dicts,
    isLoading,
    isLoadingManagement,
    managementConfiguration,
    projectDict,
    propertiesDict,
    spaces,
  ]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      patchTemplates({
        resource: "space",
        updatedTemplates: [
          ...spacesTemplates.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return {
              ...view,
              custom: true,
            };
          }),
        ],
      });
    },
    [patchTemplates, spacesTemplates]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        resource: "space",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const onRowClick = (space) => {
    if (space.association) {
      history.push(
        getNestedResourcePath(space.association, `Space/${space?.id}`)
      );
    }
  };

  const onCardClick = (id) => {
    const spacePressed = spaces.find((item) => item.id === id);
    if (spacePressed) {
      history.push(
        getNestedResourcePath(
          spacePressed.association,
          `Space/${spacePressed?.id}`
        )
      );
    }
  };

  const hasCreatePermissions = currentUser?.isAdmin;

  return {
    spaces,
    selectedRows,
    handleSelectRows,
    tagsData,
    tableData,
    spacesTemplates,
    isShowingSpacesTable,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    deleteTemplate,
    setShowDeleteModal,
    modals,
    showDeleteModal,
    onRowClick,
    onCardClick,
    hasCreatePermissions,
    isLoading,
  };
};

export default useSpaceTableData;
