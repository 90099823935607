import React from "react";
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../../Checkbox/Checkbox";
import Step from "../../../Step";
import TaskStepText from "./TaskStepText";
import useSopStepStatusData from "./useSopStepStatusData";

const labelWidth = { minWidth: "12.5rem" };

const TaskStepsSection = ({
  editingMode,
  sopsForTask,
  taskForm,
  currentTask,
  setCurrentTask,
  sopOptions,
  handleStepAdd,
  handleStepEdit,
  handleStepRemove,
  noTitle,
  isPastTask,
}) => {
  const { handleCompleteCheck, isAllChecked } = useSopStepStatusData(
    sopsForTask,
    currentTask,
    setCurrentTask
  );

  return (
    <>
      <div className="flex flex-row items-center pt-4">
        {!noTitle && (
          <p style={labelWidth} className="font-semibold text-gray-450 text-md">
            Steps
          </p>
        )}
        {editingMode && !isPastTask && (
          <PlusCircleButton
            title="Add Step"
            className="mt-0"
            titleClassName="text-darkenedGreen font-semibold"
            // if style is false overrides titleClassName
            style={{}}
            onClick={handleStepAdd}
          />
        )}
        {!editingMode && !sopsForTask?.length && (
          <p className="text-sm text-gray-450">
            There are no steps saved to this task
          </p>
        )}
      </div>
      <div className="flex flex-row mt-4">
        {!editingMode && sopsForTask?.length > 0 && (
          <div className="flex">
            <Checkbox
              className="flex items-start h-4 mr-2 mt-0.5"
              size={6}
              checked={isAllChecked}
              disabled={currentTask?.status === "done"}
              onChange={() => handleCompleteCheck(!isAllChecked)}
            />
            <p className="text-gray-450 text-sm whitespace-nowrap">
              Select All
            </p>
          </div>
        )}
        <div className="flex flex-col w-full">
          {!editingMode && (
            <div className="flex flex-col ml-14">
              {sopsForTask?.map((sop, index) => (
                <TaskStepText
                  key={sop.id}
                  sop={sop}
                  index={index}
                  currentTask={currentTask}
                  setCurrentTask={setCurrentTask}
                />
              ))}
            </div>
          )}
          <div className="flex flex-col">
            {editingMode &&
              taskForm.steps?.map((sop, index) => (
                <div key={sop.id} className="mt-3 mb-2 mr-2">
                  <Step
                    index={index}
                    key={`${sop.id}-step`}
                    form={taskForm}
                    handleAddStep={handleStepAdd}
                    handleStepEdit={handleStepEdit}
                    handleStepRemove={handleStepRemove}
                    sopOptions={sopOptions?.options || []}
                    disabled={isPastTask}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskStepsSection;
