import { useMutation, useQueryClient } from "react-query";
import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import propertyKeys from "./propertiesKeys";

/**
 * Mutation hook to patch a single property
 */
const usePropertiesPatch = () => {
  const queryClient = useQueryClient();

  /**
   * Function to patch a property in backend
   * @param {Object} data - single object containing old property and updated property data
   * @param {Object} data.updatedProperty - New property data
   * @param {Object} data.property - Old property data
   */
  const patchProperty = async ({ updatedProperty, property }) => {
    const { data } = await PropertyAPI.patch(
      property.id,
      updatedProperty,
      property
    );

    return data;
  };

  return useMutation({
    mutationFn: patchProperty,
    onMutate: async ({ updatedProperty, property }) => {
      const queryKey = propertyKeys.byId(property.id);

      // Get the current state of the property to rollback in case of any error
      const currentProperty = queryClient.getQueryData(queryKey);

      // Cancel any query for properties in the meantime
      await queryClient.cancelQueries(queryKey);

      // Optimistic update property
      queryClient.setQueryData(queryKey, (previous) => ({
        ...previous,
        ...updatedProperty,
      }));

      // Return a context that can be accessed from callbacks below
      return { currentProperty, queryKey };
    },
    onSuccess: (data, _, context) => {
      const { queryKey } = context;
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error, variables) => {
      const { queryKey, currentProperty } = variables;
      // Rollback property if any error
      queryClient.setQueryData(queryKey, currentProperty);

      console.error("usePropertiesPatch", error);
    },
    onSettled: (_, error, variables) => {
      if (error) {
        const { property } = variables;
        queryClient.invalidateQueries(propertyKeys.byId(property.id));
      }

      queryClient.invalidateQueries({
        queryKey: propertyKeys.all,
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: propertyKeys.overview(),
        exact: true,
      });
    },
  });
};

export default usePropertiesPatch;
