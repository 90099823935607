import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Checkbox from "../../../../Checkbox/Checkbox";
import Tooltip from "../../../../Tooltip";
import checkedClipboard from "../../../../../assets/images/checkedClipboard.svg";
import IconButton from "../../../../Buttons/IconButton";
import chevronRightIcon from "../../../../../assets/images/chevronRightDarkGreen.svg";

const SopStepWithStatusWrapper = ({
  sop,
  index,
  completeStep,
  handleStepCheck,
  handleSopStepCheck,
  disabled,
  defaultExpandStep,
}) => {
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(defaultExpandStep);

  const isSopStepCompleted = useCallback(
    (stepId) => {
      if (!sop?.stepData?.sopStepStatus) {
        return false;
      }

      const found = sop.stepData.sopStepStatus.find(
        (stepStatus) => stepStatus.id === stepId
      );
      return found?.isCompleted;
    },
    [sop.stepData.sopStepStatus]
  );

  return (
    <div className="flex flex-col w-full">
      <div className="flex">
        <Checkbox
          className="flex items-start h-4 mr-2 mt-0.5"
          size={6}
          checked={completeStep === "Yes"}
          disabled={disabled}
          onChange={() => handleStepCheck(sop, index)}
        />
        <p className="text-gray-450 text-sm mr-2">
          {index + 1}
          {". "}
        </p>
        <Tooltip title="click to navigate to sop">
          <button
            className="flex text-primaryGreen text-sm font-semibold"
            onClick={() => history.push(`/sops/${sop.id}`)}
            type="button"
          >
            <img
              src={checkedClipboard}
              alt={sop?.name}
              className="w-4 h-5 mr-2"
            />
            {sop?.name}
          </button>
        </Tooltip>
        <IconButton
          icon={chevronRightIcon}
          isIconOnRight
          imgClassName={`w-3 h-3 transform ${
            isExpanded ? "-rotate-90" : "rotate-90"
          }`}
          onClick={() => setIsExpanded((prev) => !prev)}
          className="px-2 pt-1"
        />
      </div>
      {isExpanded && (
        <>
          {sop?.steps?.length > 0 && (
            <div className="flex flex-col bg-backgroundGrey w-full mt-2 pt-4 px-4 rounded">
              <div className="pb-2 font-medium text-xs text-gray-450">
                SOP STEPS
              </div>
              {sop?.steps?.map((step) => (
                <div key={step?.id} className="flex items-center mt-1.5 pb-4">
                  <Checkbox
                    className="flex items-start h-4 mr-2"
                    size={6}
                    checked={isSopStepCompleted(step.id)}
                    disabled={disabled}
                    onChange={() =>
                      handleSopStepCheck(
                        sop.stepData.id,
                        step,
                        sop.steps.length
                      )
                    }
                  />
                  <p className="text-gray-450 text-sm">{step?.description}</p>
                </div>
              ))}
            </div>
          )}
          {sop?.steps?.length === 0 && (
            <div className="flex flex-col bg-backgroundGrey w-full mt-2 p-4 rounded">
              <div className="pb-2 font-medium text-xs text-gray-450">
                No steps
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

SopStepWithStatusWrapper.propTypes = {
  sop: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    stepData: PropTypes.shape({
      id: PropTypes.string,
      sopStepStatus: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
        })
      ),
    }),
  }),
  index: PropTypes.string,
  completeStep: PropTypes.string,
  handleStepCheck: PropTypes.func,
  handleSopStepCheck: PropTypes.func,
  disabled: PropTypes.bool,
  defaultExpandStep: PropTypes.bool,
};

SopStepWithStatusWrapper.defaultProps = {
  defaultExpandStep: true,
  disabled: false,
  index: undefined,
  completeStep: undefined,
  handleStepCheck: () => {},
  handleSopStepCheck: () => {},
  sop: undefined,
};

export default SopStepWithStatusWrapper;
