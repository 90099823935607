import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import projectsKey from "./projectKeys";

const deleteProject = (id) => {
  return ProjectAPI.delete(id);
};

const useProjectsDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteProject,
    onMutate: async (id) => {
      const queryKey = projectsKey.all;

      // Get the current state of the projects to rollback in case of any error
      const currentProjects = queryClient.getQueryData(queryKey) || [];

      // Cancel any query for projects in the meantime
      await queryClient.cancelQueries(queryKey);

      // Find the project to delete by Id
      const index = currentProjects.findIndex((project) => project.id === id);

      if (index !== -1) {
        queryClient.setQueryData(queryKey, (current) => {
          const updatedProjectList = current.slice();
          updatedProjectList.splice(index, 1);

          return updatedProjectList;
        });
      }

      return { currentProjects };
    },
    onError: (error, _, context) => {
      // Rollback to previuos state
      const { queryKey, currentProjects } = context;
      queryClient.setQueryData(queryKey, currentProjects);

      console.error("useProjectsDelete: ", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: projectsKey.all,
        exact: true,
      });

      queryClient.invalidateQueries({
        queryKey: projectsKey.overview(),
        exact: true,
      });
    },
  });
};

export default useProjectsDelete;
