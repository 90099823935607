/* eslint-disable no-alert */
import React, { useCallback, useMemo } from "react";
// API
import TrashIconEnabled from "../../../assets/images/trashDarkenedGreenIcon.svg";

import IconButton from "../../Buttons/IconButton";

import RoleMembersAvatarList from "../../../../Pages/Admin/RoleMembersAvatarList";
import {
  ADD_OPEN_MODAL,
  CONFIRM_MODAL,
  DELETE_RECURRENCE_POPUP,
  EDIT_EVENT_MODAL,
  EVENT,
  TOGGLE_POSITIONED_POPUP,
} from "../../../../constants";
import { toastError } from "../../../../helpers/Toast";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useEventModalData from "./useEventModalData";
// Icons
import getDateTimeInTimezone from "../../../../helpers/Calendar/getDateTimeInTimezone";
import { getFullName } from "../../../../helpers/Formatters";
import {
  hasDeletePermission,
  hasWritePermission,
} from "../../../../helpers/Permissions";
import { useRsvp } from "../../../../hooks/rsvp";
import { useAppState } from "../../../../state/appState";
import whiteCrossIcon from "../../../assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../assets/images/whiteExclamationIcon.svg";
import AddSpacesAssetsWidget from "../../AddSpacesAssetsWidget";
import AssociationLink from "../../AssociationLink";
import AttachmentsHorizontalList from "../../AttachmentsHorizontalList";
import Checkbox from "../../Checkbox/Checkbox";
import DescriptionField from "../../DescriptionField";
import EditIcon from "../../EditIcon/EditIcon";
import LinksModalWidget from "../../Links/LinksModalWidget";
import ModalMediaWidget from "../../ModalMediaWidget";
import RsvpRow from "../../RsvpRow";
import TagList from "../../Tags/TagList";
import { toastMessage } from "../../Toast/Toast";
import ModalWrapper from "../ModalWrapper";
import useEsModalData from "../useEsModalData";
import Divider from "./Divider";
// icon images to be used with toast
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;
const labelCN = "text-es-normal font-semibold text-es-medium-grey";
const labelWidth = { minWidth: "9vw" };
const rowCN = "flex flex-row items-center";
const widgetCN = "flex flex-col gap-8 border rounded-md p-8 mt-2";
const leftContainer = "flex flex-row w-1/2 items-center";
const rightContainer = "flex flex-row w-1/2 ml-4 items-center";
const textCN = "text-es-normal text-es-medium-grey text-start";

const ViewEventModal = ({ modalData }) => {
  const {
    associationData,
    createdBy,
    currentTags,
    allInviteesInfo,
    recurrenceString,
    modalStateDispatch,
    isAllDay,
    removeFromCalendar,
  } = useEventModalData(modalData);

  const { closeModal: close } = useEsModalData(null, modalData);
  const [, appStateDispatch] = useAppState();
  const { name } = modalData?.item;
  const { data: userData } = useCurrentUser();
  const { data: rsvpData } = useRsvp(
    modalData?.item?.reference,
    modalData?.item?.instanceStartDate
  );
  // Render correct amount in guest/assignees/invitees section
  const membersToShowCount = 3;

  // If `recurrenceString` text is long
  const aligmentCN =
    recurrenceString?.length > 38 ? "items-stretch" : "items-center";

  const showRSVP = useMemo(() => {
    return modalData?.item?.invitees?.find(
      (userRef) => userRef === userData?.reference
    );
  }, [modalData?.item?.invitees, userData?.reference]);
  /**
   * Opens Edit Modal
   * Closes View Modal
   */
  const handleEditClick = useCallback(() => {
    modalStateDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: `${modalData.item.id}-edit` },
      position: modalData.position,
      modalData,
      modalType: EDIT_EVENT_MODAL,
    });
    close();
  }, [modalStateDispatch, modalData, close]);

  const handleDeleteEvent = useCallback(async () => {
    const eventForm = modalData.item;
    if (eventForm?.recurrence) {
      appStateDispatch({
        type: TOGGLE_POSITIONED_POPUP,
        position: {
          centered: true,
        },
        popupData: { item: modalData.item, popupWidth: 444 },
        popupType: DELETE_RECURRENCE_POPUP,
      });

      close();
      return;
    }

    try {
      await removeFromCalendar({
        args: eventForm,
        operation: "$non-recurring",
      });

      toastMessage("Event Deleted");
      close();
    } catch (err) {
      toastError(
        `Error Deleting Event: ${err.message}`,
        toastErrorIcon,
        toastCloseIcon
      );
    }
  }, [modalData.item, appStateDispatch, close, removeFromCalendar]);

  const handleDeleteClick = () => {
    modalStateDispatch({
      type: ADD_OPEN_MODAL,
      modalType: CONFIRM_MODAL,
      ref: { id: `${modalData?.item?.id}-confirm-delete` },
      modalData: {
        item: {
          prompt:
            "Are you sure you want to delete this event, this action cannot be undone?",
          confirm: "Yes",
          cancel: "No",
          title: "Delete Event",
          onConfirm: handleDeleteEvent,
        },
      },
      position: { x: 0, y: 0 },
    });
  };

  return (
    <ModalWrapper
      width="985px"
      modalData={modalData}
      resourceName="Event"
      className="pl-6 pr-4 pb-2 pt-0 gap-5"
      buttons={
        userData?.reference === createdBy?.reference &&
        hasDeletePermission(EVENT, userData) ? (
          <IconButton
            icon={TrashIconEnabled}
            imgClassName="w-5 h-5"
            onClick={() => handleDeleteClick()}
          />
        ) : (
          <></>
        )
      }
    >
      <>
        <div className="flex justify-between w-full">
          <h1
            className="text-xl font-semibold tracking-wider text-es-medium-grey truncate ml-2"
            title={name}
          >
            {name}
          </h1>

          {/* Button for editing the event */}
          {userData?.reference === createdBy?.reference &&
            hasWritePermission(EVENT, userData) && (
              <button
                type="button"
                onClick={() => handleEditClick()}
                aria-label="edit button"
              >
                <EditIcon />
              </button>
            )}
        </div>

        {showRSVP && (
          <RsvpRow
            reference={modalData?.item?.reference}
            isEvent
            instanceStartDate={modalData?.item?.instanceStartDate}
            isRecurring={!!modalData?.item?.recurrence}
          />
        )}

        <div className={widgetCN}>
          <div className="flex flex-col gap-6 mt-2">
            {/* Association and Timezone */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <p className={labelCN} style={labelWidth}>
                  Association
                </p>
                {associationData && (
                  <AssociationLink
                    associationName={
                      associationData.title || associationData.name
                    }
                    associationRef={modalData?.item?.association}
                  />
                )}
              </div>

              <div className={rightContainer}>
                <p className={labelCN} style={labelWidth}>
                  Timezone
                </p>
                <p className={textCN}>{modalData?.item?.timezone}</p>
              </div>
            </div>

            <Divider />

            {/** Start and End */}
            <div className={rowCN}>
              <div className={leftContainer}>
                <div className="flex flex-row flex-grow">
                  <p style={labelWidth} className={labelCN}>
                    Start
                  </p>
                  <p className={textCN}>
                    {getDateTimeInTimezone({
                      date: modalData?.item?.startDate,
                      timezone: modalData?.item?.timezone,
                      format: isAllDay
                        ? "MMMM DD, YYYY"
                        : "MMMM DD, YYYY hh:mmA",
                    })}
                  </p>
                </div>
                {isAllDay && (
                  <div className="flex flex-row mr-6">
                    <Checkbox
                      checked={isAllDay}
                      inputClassName="calendar-form-tick"
                      label="All Day"
                      size="5"
                      disabled
                      labelClassName={textCN}
                    />
                  </div>
                )}
              </div>

              <div className={rightContainer}>
                <p style={labelWidth} className={labelCN}>
                  End
                </p>

                <p className={textCN}>
                  {getDateTimeInTimezone({
                    date: modalData?.item?.endDate,
                    timezone: modalData?.item?.timezone,
                    format: isAllDay ? "MMMM DD, YYYY" : "MMMM DD, YYYY hh:mmA",
                  })}
                </p>
              </div>
            </div>

            <Divider />

            {/** Repeat and Created by */}
            <div className={`flex flex-row ${aligmentCN}`}>
              <div className={`flex flex-row w-1/2 ${aligmentCN}`}>
                <p className={labelCN} style={labelWidth}>
                  Repeat
                </p>
                {recurrenceString && (
                  <p className={textCN}>{recurrenceString}</p>
                )}
              </div>

              <div className={`flex flex-row w-1/2 ml-4 ${aligmentCN}`}>
                <p className={labelCN} style={labelWidth}>
                  Created By
                </p>

                <p className={textCN}>{getFullName(createdBy?.name)}</p>
              </div>
            </div>

            <Divider />

            {/** Description and Tags */}
            <div className="flex flex-row">
              <div
                className={`flex flex-row w-1/2 ${
                  modalData?.item?.tags?.length > 0
                    ? "items-stretch"
                    : "items-center"
                }`}
              >
                <p className={labelCN} style={labelWidth}>
                  Description
                </p>

                <DescriptionField
                  className={textCN}
                  text={modalData?.item?.description}
                />
              </div>

              <div
                className={`flex flex-row w-1/2 ml-4 ${
                  modalData?.item?.tags?.length > 0
                    ? "items-stretch"
                    : "items-center"
                }`}
              >
                <p className={labelCN} style={labelWidth}>
                  Tags
                </p>
                {!!modalData?.item?.tags?.length && (
                  <TagList
                    tags={currentTags}
                    className="flex-wrap gap-2"
                    tagClassName="py-1 px-2"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4">
          <div className="w-1/2 flex flex-col gap-3">
            <div className={widgetCN}>
              <div>
                <p className={`${labelCN} pb-3`} style={labelWidth}>
                  Guests
                </p>

                {modalData?.item?.invitees?.length > 0 && (
                  <div className="flex justify-start">
                    <RoleMembersAvatarList
                      showRsvpStatus
                      members={allInviteesInfo}
                      membersToShowCount={membersToShowCount}
                      className="space-x-3"
                      viewAllClassName="pl-0 ml-0"
                      viewAllButton={
                        <div
                          style={{ minWidth: "32px", minHeight: "32px" }}
                          className="rounded-3xl mr-3 bg-backgroundGrey flex justify-center items-center shadow-light-lift"
                        >
                          <p className="text-darkenedGreen flex justify-center w-5 text-xs font-semibold">
                            +{allInviteesInfo?.length - membersToShowCount}
                          </p>
                        </div>
                      }
                      rsvpData={rsvpData}
                    />
                  </div>
                )}
              </div>

              <Divider fullLength />

              <div>
                <p className={`${labelCN} self-start`} style={labelWidth}>
                  Spaces
                </p>
                {modalData?.item?.spaces?.length > 0 && (
                  <AddSpacesAssetsWidget
                    form={modalData?.item}
                    isEditing={false}
                    noTitle
                    resource="spaces"
                  />
                )}
              </div>
            </div>

            <div className={`${widgetCN} pt-8`}>
              <LinksModalWidget
                resource={modalData?.item}
                editing={false}
                loading={false}
                textCN="text-sm"
              />
            </div>
          </div>

          {/** Media */}
          <div className="w-1/2 flex flex-col gap-3">
            <div className={widgetCN}>
              <p className={labelCN} style={labelWidth}>
                Media
              </p>

              <ModalMediaWidget
                requestForm={modalData?.item}
                filesState={{ mediaFilesToAdd: [] }}
                isEditing={false}
                handleFilesToAdd={() => {}}
                handleFilesToRemove={() => {}}
                className="min-w-full"
                noTitle
              />
            </div>

            {/** Attachments */}
            <div className={widgetCN}>
              <p className={labelCN} style={labelWidth}>
                Attachments
              </p>

              <div className="flex flex-col flex-wrap gap-2">
                <AttachmentsHorizontalList
                  files={modalData?.item?.files?.filter(
                    (item) => item.category === "Documents"
                  )}
                  isEditing={false}
                  textCN="text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
};

export default ViewEventModal;
